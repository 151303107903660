import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import firebase from "firebase/app";
import moment from "moment";
import {
  ElementType,
  ReactNode,
  SetStateAction,
  useMemo,
  useState,
} from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import ErrorDisplay from "../common/ErrorDisplay";
import formatTemperature from "../common/formatTemperature";
import LoadingIndicator from "../common/LoadingIndicator";
import LogoWrapper from "../common/LogoWrapper";
import strings from "../common/strings";
import { firestore } from "../firebaseApp";
import { IReading, ISensor } from "./common";
import CondensedTemperatureGraph, {
  CondensedChartDataPoint,
  ICondensedDataPoint,
} from "./CondensedTemperatureGraph";
import TemperatureGraph, { TemperatureChartPoint } from "./TemperatureGraph";
import TimeRangeControls from "./TimeRangeControls";
import { useCurrentReading } from "./useCurrentReading";

function useReadings(
  sensorRef: firebase.firestore.DocumentReference
): [
  [
    Data<IReading, "", "">[] | undefined,
    boolean,
    firebase.FirebaseError | undefined
  ],
  number,
  React.Dispatch<SetStateAction<number>>,
  number | null,
  React.Dispatch<SetStateAction<number | null>>,
  boolean
] {
  const [startTime, setStartTime] = useState<number>(() =>
    moment().subtract(24, "hour").valueOf()
  );
  const [endTime, setEndTime] = useState<number | null>(null);

  const shouldDisplayCondensedData = useMemo(
    () =>
      moment
        .duration(
          moment
            .unix(startTime / 1000)
            .diff(endTime != null ? moment.unix(endTime / 1000) : moment())
        )
        .abs()
        .asDays() > 7,
    [startTime, endTime]
  );

  const [sensor, loadingSensor, errorLoadingSensor] =
    useDocumentData<ISensor>(sensorRef);

  const readingsRef = useMemo(() => {
    if (loadingSensor || errorLoadingSensor) return null;

    const internalReadingsRef = (sensor?.datasetRef ?? sensorRef)
      .collection("readings")
      .orderBy("time")
      .startAt(firebase.firestore.Timestamp.fromMillis(startTime));

    return endTime != null
      ? internalReadingsRef.endAt(
          firebase.firestore.Timestamp.fromMillis(endTime)
        )
      : internalReadingsRef;
  }, [
    loadingSensor,
    errorLoadingSensor,
    sensor?.datasetRef,
    endTime,
    sensorRef,
    startTime,
  ]);

  return [
    useCollectionData<IReading>(readingsRef),
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    shouldDisplayCondensedData,
  ];
}

function useCondensedData(
  readings: IReading[] | undefined
): ICondensedDataPoint[] {
  const condensedData = useMemo(() => {
    const internalCondensedData: ICondensedDataPoint[] = [];

    if (readings != null && readings.length > 0) {
      let currentCondensedDataPoint: Omit<ICondensedDataPoint, "average"> = {
        date: moment(readings[0].time.toDate()).startOf("date").toDate(),
        minimum: readings[0].temperature,
        maximum: readings[0].temperature,
      };

      let temperaturesForDay: number[] = [readings[0].temperature];

      for (let i = 1; i < readings.length; i++) {
        const reading = readings[i];

        if (
          !moment(reading.time.toDate()).isSame(
            currentCondensedDataPoint.date,
            "day"
          )
        ) {
          internalCondensedData.push({
            ...currentCondensedDataPoint,
            average:
              temperaturesForDay.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0
              ) / temperaturesForDay.length,
          });

          currentCondensedDataPoint = {
            date: moment(reading.time.toDate()).startOf("date").toDate(),
            minimum: reading.temperature,
            maximum: reading.temperature,
          };

          temperaturesForDay = [reading.temperature];
        } else {
          temperaturesForDay.push(reading.temperature);

          if (reading.temperature < currentCondensedDataPoint.minimum)
            currentCondensedDataPoint.minimum = reading.temperature;
          else if (reading.temperature > currentCondensedDataPoint.maximum)
            currentCondensedDataPoint.maximum = reading.temperature;
        }
      }

      internalCondensedData.push({
        ...currentCondensedDataPoint,
        average:
          temperaturesForDay.reduce(
            (previousValue, currentValue) => previousValue + currentValue,
            0
          ) / temperaturesForDay.length,
      });
    }

    return internalCondensedData;
  }, [readings]);

  return condensedData;
}

const useDataDisplayClasses = makeStyles((theme) =>
  createStyles({
    container: {
      padding: 8,
      display: "flex",
      flexDirection: "row",
    },
    icon: {
      display: "block",
      minHeight: 48,
      minWidth: 48,
      marginRight: 8,
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
    },
  })
);

function DataDisplay({
  title,
  data,
  icon: Icon,
}: {
  title: ReactNode;
  data: ReactNode;
  icon: ElementType<{ className: string }>;
}) {
  const classes = useDataDisplayClasses();

  return (
    <Grid item xs={12} lg={3}>
      <Paper className={classes.container}>
        <Icon className={classes.icon} />
        <div className={classes.textContainer}>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="body1">{data}</Typography>
        </div>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: 2,
    },
    title: {
      overflowWrap: "break-word",
    },
  })
);

export default function Sensor() {
  const { id: sensorId } = useParams<{ id: string }>();

  const sensorRef = useMemo(
    () => firestore.collection("sensors").doc(sensorId),
    [sensorId]
  );

  const [sensor, loadingSensor, sensorError] =
    useDocumentData<ISensor>(sensorRef);
  const [
    [readings, loadingReadings, readingError],
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    shouldDisplayCondensedData,
  ] = useReadings(sensorRef);

  const condensedData = useCondensedData(readings);
  const condensedChartData = useMemo(
    () => condensedData.map((x) => new CondensedChartDataPoint(x)),
    [condensedData]
  );

  const [currentReading, loadingCurrentReading, errorLoadingCurrentReading] =
    useCurrentReading(sensorId, sensor?.datasetRef);

  const classes = useStyles();

  if (loadingSensor && loadingReadings) return <LoadingIndicator />;
  else if (sensorError !== undefined || readingError !== undefined)
    return (
      <LogoWrapper>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <ErrorDisplay>{strings.genericError}</ErrorDisplay>
          <Grid item>
            <Button
              component={Link}
              to="/sensors"
              variant="contained"
              color="primary"
            >
              {strings.backToSensorList}
            </Button>
          </Grid>
        </Grid>
      </LogoWrapper>
    );
  else
    return (
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12} lg={6}>
          <Typography variant="h2" className={classes.title}>
            {sensor?.name}
          </Typography>
        </Grid>
        {loadingCurrentReading ? (
          <Grid item xs={12} lg={6}>
            <LoadingIndicator />
          </Grid>
        ) : errorLoadingCurrentReading ? (
          <Grid item xs={12} lg={6}>
            <ErrorDisplay>{strings.genericError}</ErrorDisplay>
          </Grid>
        ) : currentReading === null ? (
          <Grid item xs={12} lg={6}>
            <ErrorDisplay>{strings.noData}</ErrorDisplay>
          </Grid>
        ) : (
          <>
            <DataDisplay
              icon={AccessTimeIcon}
              title={strings.timeOfLastMeasurement}
              data={moment(currentReading.time.toDate()).format(
                "HH:mm DD/MM/yyyy"
              )}
            />
            <DataDisplay
              icon={AcUnitIcon}
              title={strings.temperature}
              data={<>{formatTemperature(currentReading.temperature)}&deg;</>}
            />
          </>
        )}
        <TimeRangeControls
          {...{ startTime, setStartTime, endTime, setEndTime }}
        />
        <Grid item xs={12}>
          {loadingReadings ? (
            <LoadingIndicator>
              {shouldDisplayCondensedData ? strings.loadingLargeDataset : null}
            </LoadingIndicator>
          ) : readings == null || readings.length <= 0 ? (
            <ErrorDisplay>{strings.noDataForTimeRange}</ErrorDisplay>
          ) : shouldDisplayCondensedData ? (
            <CondensedTemperatureGraph chartData={condensedChartData} />
          ) : (
            <TemperatureGraph
              chartData={readings.map(
                (reading) => new TemperatureChartPoint(reading)
              )}
            />
          )}
        </Grid>
      </Grid>
    );
}
