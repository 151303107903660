import firebase from "firebase/app";
import { useIdTokenResult } from "./useIdTokenResult";

export default function useIsAdmin(): [
  boolean | undefined,
  boolean,
  firebase.auth.Error | undefined
] {
  const [idTokenResult, loading, error] = useIdTokenResult();

  return [idTokenResult?.claims.admin ?? undefined, loading, error];
}
