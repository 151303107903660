import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import strings from "../../../../common/strings";
import { ICustomerDialogProps } from "./common";

export default function DeleteDialog({
  customer,
  open,
  setOpen,
  onOk,
}: ICustomerDialogProps & {
  onOk(): void;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const confirm = () => {
    onOk();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`delete-${customer.id}-dialog-title`}
      aria-describedby={`delete-${customer.id}-dialog-description`}
    >
      <DialogTitle id={`delete-${customer.id}-dialog-title`}>
        {strings.deleteCustomer}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`delete-${customer.id}-dialog-description`}>
          {strings.formatString(
            strings.confirmResellerDeletion,
            <strong>{customer?.name}</strong>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {strings.cancel}
        </Button>
        <Button onClick={confirm} color="primary">
          {strings.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
