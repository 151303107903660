import constate from "constate";
import { useEffect, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseApp";

const [AdminUidOverrideProviderInternal, useAdminUidOverride] = constate(() => {
  const [authState] = useAuthState(auth);
  const uidRef = useRef<string | undefined>();
  const [uidOverride, setUidOverride] = useState<string>();

  useEffect(() => {
    if (authState?.uid !== uidRef.current) {
      uidRef.current = authState?.uid;
      setUidOverride(undefined);
    }
  }, [authState]);

  return [uidOverride, setUidOverride] as const;
});

export default useAdminUidOverride;

export const AdminUidOverrideProvider = AdminUidOverrideProviderInternal;
