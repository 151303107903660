import { MenuItem } from "@material-ui/core";
import { useCallback, useState } from "react";
import ContextMenuButton from "../../common/ContextMenuButton";
import strings from "../../common/strings";
import { functions } from "../../firebaseApp";
import { ISensor } from "../common";
import SensorDisconnectionDialog from "./SensorDisconnectionDialog";
import SensorRenamingDialog from "./SensorRenamingDialog";
import SensorReplacementDialog from "./SensorReplacementDialog";
import SensorTemperatureRangeAlterationDialog from "./SensorTemperatureRangeAlterationDialog";
import ExternalProbeConfigurationDialog from "./ExternalProbeConfigurationDialog";

async function disconnectSensor(sensorId: string) {
  await functions.httpsCallable("disconnectSensor")(sensorId);
}

type DialogName =
  | "disconnection"
  | "renaming"
  | "temperatureRangeAlteration"
  | "externalProbeConfiguration"
  | "replacement";

export default function SensorContextMenuButton({
  sensor,
  setDisconnecting,
  disabled,
}: {
  sensor: ISensor;
  setDisconnecting(disconnecting: boolean): void;
  disabled?: boolean;
}) {
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = useCallback(() => setMenuOpen(false), []);

  const [currentDialog, setCurrentDialog] = useState<DialogName | null>(null);

  const openDialog = useCallback(
    (dialogName: DialogName) => {
      closeMenu();
      setCurrentDialog(dialogName);
    },
    [closeMenu, setCurrentDialog]
  );

  const closeDialog = useCallback(() => {
    setCurrentDialog(null);
  }, []);

  return (
    <>
      <ContextMenuButton
        disabled={disabled}
        open={menuOpen}
        setOpen={setMenuOpen}
      >
        <MenuItem onClick={() => openDialog("renaming")}>
          {strings.rename}
        </MenuItem>
        <MenuItem onClick={() => openDialog("temperatureRangeAlteration")}>
          {strings.editTemperatureRange}
        </MenuItem>
        <MenuItem onClick={() => openDialog("externalProbeConfiguration")}>
          {strings.configureExternalProbe}
        </MenuItem>
        <MenuItem onClick={() => openDialog("replacement")}>
          {strings.replace}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setDisconnecting(true);
            openDialog("disconnection");
          }}
        >
          {strings.disconnect}
        </MenuItem>
      </ContextMenuButton>
      <SensorRenamingDialog
        sensor={sensor}
        isOpen={currentDialog === "renaming"}
        handleClose={closeDialog}
      />
      <SensorTemperatureRangeAlterationDialog
        sensor={sensor}
        isOpen={currentDialog === "temperatureRangeAlteration"}
        handleClose={closeDialog}
      />
      <SensorReplacementDialog
        sensor={sensor}
        isOpen={currentDialog === "replacement"}
        handleClose={closeDialog}
      />
      <ExternalProbeConfigurationDialog
        sensor={sensor}
        isOpen={currentDialog === "externalProbeConfiguration"}
        handleClose={closeDialog}
      />
      <SensorDisconnectionDialog
        sensor={sensor}
        isOpen={currentDialog === "disconnection"}
        handleCancel={() => {
          setDisconnecting(false);
          closeDialog();
        }}
        handleConfirm={async () => {
          closeDialog();

          try {
            await disconnectSensor(sensor.id);
          } catch {
            setDisconnecting(false);
          }
        }}
      />
    </>
  );
}
