import { useReducer } from "react";
import AnnouncementTarget from "../../common/AnnouncementTarget";

export default function useTargetState(
  initialState: readonly AnnouncementTarget[]
) {
  return useReducer(
    (
      prevState: readonly AnnouncementTarget[],
      action: AnnouncementTarget
    ): readonly AnnouncementTarget[] =>
      prevState.includes(action)
        ? prevState.filter((t) => t !== action)
        : [...prevState, action],
    initialState
  );
}
