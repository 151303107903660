import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { firestore } from "../../../firebaseApp";

export interface ICustomer {
  id: string;
  name: string;
  uid?: string;
}

export default function useCustomers(): [
  ICustomer[] | undefined,
  boolean,
  firebase.FirebaseError | undefined
] {
  const { resellerId } = useParams<{ resellerId: string }>();

  return useCollectionData(
    firestore.collection("resellers").doc(resellerId).collection("customers"),
    {
      idField: "id",
    }
  );
}
