import { Route, Switch } from "react-router-dom";
import ResellerPanelLandingPage from "./ResellerPanelLandingPage";
import ResellerPanelPage from "./ResellerPanelPage";

export default function ResellerPanel() {
  return (
    <Switch>
      <Route path="/reseller" exact component={ResellerPanelLandingPage} />
      <Route path="/reseller/:resellerId" component={ResellerPanelPage} />
    </Switch>
  );
}
