import { useEffect, useRef } from "react";

export default function useSavedCallback(callback: () => void) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  return savedCallback;
}
