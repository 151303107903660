import { useTheme } from "@material-ui/core";
import moment from "moment";
import "moment/locale/nl";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import formatTemperature from "../common/formatTemperature";
import strings from "../common/strings";
import { IReading } from "./common";
import useGraphStyles from "./useGraphStyles";

export class TemperatureChartPoint {
  readonly time: number;
  readonly temperature: number;

  constructor(reading: IReading) {
    this.time = reading.time.toMillis();
    this.temperature = reading.temperature;
  }
}

const TemperatureGraph = ({
  chartData,
}: {
  chartData: TemperatureChartPoint[];
}) => {
  const classes = useGraphStyles();
  const theme = useTheme();

  return (
    <ResponsiveContainer width="95%" height={500}>
      <LineChart data={chartData}>
        <XAxis
          dataKey="time"
          domain={["auto", "auto"]}
          name={strings.time}
          tickFormatter={(unixTime: number) =>
            moment(unixTime).format("HH:mm DD-MM-yyyy")
          }
          type="number"
        />
        <YAxis
          dataKey="temperature"
          name={strings.temperature}
          domain={[
            (dataMin: number) => Math.floor(dataMin - 1),
            (dataMax: number) => Math.ceil(dataMax + 1),
          ]}
        />
        <CartesianGrid />
        <Line
          type="monotone"
          dataKey="temperature"
          name={strings.temperature}
        />
        <Tooltip
          labelFormatter={(unixTime: number) =>
            moment(unixTime).format("HH:mm DD-MM-yyyy")
          }
          formatter={(temperature: number) => (
            <span>{formatTemperature(temperature)}&deg;</span>
          )}
          labelClassName={classes.label}
          itemStyle={theme.typography.body2}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default TemperatureGraph;
