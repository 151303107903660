import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import firebase from "firebase/app";
import moment from "moment";
import "moment/locale/nl";
import {
  Dispatch,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  useCollectionData,
  useDocumentDataOnce,
} from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import useTableStyles from "../../../common/useTableStyles";
import LoadingIndicator from "../../../common/LoadingIndicator";
import strings from "../../../common/strings";
import useFormField, { numberFieldParser } from "../../../common/useFormField";
import { firestore } from "../../../firebaseApp";
import { ICustomer } from "../Customers/useCustomers";

interface LicensePackage {
  id: string;
  licenseCount: number;
  creationDate: firebase.firestore.Timestamp;
  startDate: firebase.firestore.Timestamp;
  expiryDate: firebase.firestore.Timestamp;
}

export default function CustomerPage() {
  const tableClasses = useTableStyles();

  const { resellerId, customerId } =
    useParams<{ resellerId: string; customerId: string }>();

  const customerRef = useMemo(
    () =>
      firestore
        .collection("resellers")
        .doc(resellerId)
        .collection("customers")
        .doc(customerId),
    [resellerId, customerId]
  );

  const [customer, loadingCustomer, errorLoadingCustomer] =
    useDocumentDataOnce<ICustomer>(customerRef, {
      idField: "id",
    });

  const [licensePackages, loadingLicensePackages, errorLoadingLicensePackages] =
    useCollectionData<LicensePackage>(
      useMemo(
        () =>
          customerRef
            .collection("licensePackages")
            .where("expiryDate", ">", firebase.firestore.Timestamp.now())
            .orderBy("expiryDate", "asc"),
        [customerRef]
      ),
      {
        idField: "id",
      }
    );

  // TODO: Add error handling
  const [addLicensePackage, addingLicensePackage] = useAddLicensePackage();

  const totalLicenseCount = useMemo(
    () =>
      licensePackages?.reduce(
        (acc, { licenseCount }) => acc + licenseCount,
        0
      ) ?? 0,
    [licensePackages]
  );

  const nextLicenseExpiryDate = useMemo(
    () =>
      licensePackages !== undefined && licensePackages.length > 0
        ? licensePackages[0].expiryDate
        : null,
    [licensePackages]
  );

  const [
    licensePackageAdditionDialogOpen,
    setLicensePackageAdditionDialogOpen,
  ] = useState(false);
  const addButtonRef = useRef<HTMLElement>();

  return loadingCustomer || loadingLicensePackages ? (
    <Grid item xs={12}>
      <LoadingIndicator />
    </Grid>
  ) : errorLoadingCustomer || errorLoadingLicensePackages ? (
    <ErrorMessage>{strings.genericError}</ErrorMessage>
  ) : customer == null ? (
    <ErrorMessage>{strings.nonExistentCustomer}</ErrorMessage>
  ) : (
    <>
      <Grid item xs={12}>
        <Grid container component={Paper}>
          <Grid item xs={12}>
            <Typography variant="h2" component="h1" align="center">
              {customer?.name}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <SummaryDisplayItem
            label={strings.licenseCount}
            value={totalLicenseCount}
          />
          <SummaryDisplayItem
            label={strings.nextExpiryDate}
            value={
              nextLicenseExpiryDate !== null
                ? moment(nextLicenseExpiryDate?.toDate()).format("L")
                : "\u2205"
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper}>
          <Toolbar className={tableClasses.tableToolbar}>
            <Typography variant="h6">{strings.licenses}</Typography>
          </Toolbar>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{strings.licenseCount}</TableCell>
                <TableCell>{strings.expiryDate}</TableCell>
                {/* Reducing the cell's width to 1 ensures that
                the column will never be wider than it needs to be */}
                <TableCell align="center" width={1}>
                  <Button
                    variant="contained"
                    color="primary"
                    innerRef={addButtonRef}
                    disabled={addingLicensePackage}
                    onClick={() => {
                      setLicensePackageAdditionDialogOpen(true);
                      addButtonRef.current?.blur();
                    }}
                  >
                    {strings.add}
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {licensePackages?.map(
                ({ id, licenseCount, creationDate, expiryDate }) => (
                  <TableRow key={id}>
                    <TableCell>{licenseCount}</TableCell>
                    <TableCell>
                      {moment(expiryDate.toDate()).format("L")}
                    </TableCell>
                    {Math.abs(
                      moment(creationDate.toDate()).diff(moment(), "day")
                    ) <= 7 && (
                      <LicensePackageDeletionButton licensePackageId={id} />
                    )}
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <LicensePackageAdditionDialog
        open={licensePackageAdditionDialogOpen}
        onCancel={() => {
          setLicensePackageAdditionDialogOpen(false);
        }}
        onSubmit={(licenseCount) => {
          addLicensePackage(licenseCount);
          setLicensePackageAdditionDialogOpen(false);
        }}
      />
    </>
  );
}

const useErrorStyles = makeStyles(() =>
  createStyles({
    errorMessage: {
      textAlign: "center",
    },
  })
);

function ErrorMessage({ children }: PropsWithChildren<{}>) {
  const errorClasses = useErrorStyles();

  return (
    <Grid item xs={12} className={errorClasses.errorMessage}>
      <Typography align="center" variant="h3" component="strong">
        {children}
      </Typography>
    </Grid>
  );
}

function SummaryDisplayItem({
  label,
  value,
}: {
  label: ReactNode;
  value: ReactNode;
}) {
  return (
    <Grid item xs={12} md={6} container direction="column" alignItems="stretch">
      <Grid item xs={12}>
        <Typography variant="h5" component="h2" align="center">
          {label}
        </Typography>
        <Typography variant="h6" component="p" align="center">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
}

function LicensePackageAdditionDialog({
  open,
  onCancel,
  onSubmit,
}: {
  open: boolean;
  onCancel: () => void;
  onSubmit: Dispatch<number>;
}) {
  const [size, sizeIsValid, sizeFieldProps, resetSize] = useFormField(
    1,
    numberFieldParser,
    (value) =>
      value == null
        ? strings.emptyFormField
        : value < 0
        ? strings.amountShallNotBeNegative
        : null
  );

  useEffect(() => {
    if (!open) resetSize();
  }, [open, resetSize]);

  const submit = () => {
    if (sizeIsValid) onSubmit(size as number);
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{strings.addLicenses}</DialogTitle>
      <DialogContent>
        <TextField
          {...sizeFieldProps}
          fullWidth
          label={strings.quantity}
          value={size}
          onKeyDown={(e) => {
            if (e.key === "Enter") submit();
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={!sizeIsValid} onClick={onCancel}>
          {strings.cancel}
        </Button>
        <Button color="primary" disabled={!sizeIsValid} onClick={submit}>
          {strings.addLicenses}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function useAddLicensePackage(): [
  Dispatch<number>,
  boolean,
  firebase.firestore.FirestoreError | undefined
] {
  const { resellerId, customerId } =
    useParams<{ resellerId: string; customerId: string }>();
  const [error, setError] = useState<firebase.firestore.FirestoreError>();
  const [promise, setPromise] =
    useState<Promise<firebase.firestore.DocumentReference>>();

  useEffect(() => {
    if (promise !== undefined) {
      let cancelled = false;

      (async () => {
        try {
          await promise;
        } catch (error: any) {
          if (!cancelled) setError(error);
        } finally {
          if (!cancelled) setPromise(undefined);
        }
      })();

      return () => {
        cancelled = true;
      };
    }
  }, [promise]);

  return [
    (licenseCount) => {
      firestore
        .collection("resellers")
        .doc(resellerId)
        .collection("customers")
        .doc(customerId)
        .collection("licensePackages")
        .add({
          licenseCount,
          creationDate: firebase.firestore.Timestamp.now(),
          startDate: firebase.firestore.Timestamp.now(),
          expiryDate: firebase.firestore.Timestamp.fromMillis(
            moment().add(1, "year").valueOf()
          ),
        });
    },
    promise !== undefined,
    error,
  ];
}

const useDeletionButtonStyles = makeStyles((theme) =>
  createStyles({
    deletionButton: {
      color: theme.palette.error.main,
    },
  })
);

function useDeleteLicensePackage(): [
  Dispatch<string>,
  boolean,
  firebase.firestore.FirestoreError | undefined
] {
  const { resellerId, customerId } =
    useParams<{ resellerId: string; customerId: string }>();

  const [promise, setPromise] = useState<Promise<void>>();
  const [error, setError] = useState<firebase.firestore.FirestoreError>();

  useEffect(() => {
    let cancelled = false;

    (async () => {
      try {
        await promise;
      } catch (error: any) {
        if (!cancelled) setError(error);
      } finally {
        if (!cancelled) setPromise(undefined);
      }
    })();

    return () => {
      cancelled = true;
    };
  }, [promise]);

  return [
    (licensePackageId: string) => {
      setPromise(
        firestore
          .collection("resellers")
          .doc(resellerId)
          .collection("customers")
          .doc(customerId)
          .collection("licensePackages")
          .doc(licensePackageId)
          .delete()
      );
    },
    promise !== undefined,
    error,
  ];
}

function LicensePackageDeletionButton({
  licensePackageId,
}: {
  licensePackageId: LicensePackage["id"];
}) {
  const classes = useDeletionButtonStyles();

  // TODO: Add error handling
  const [deleteLicensePackage, deleting] = useDeleteLicensePackage();

  return (
    <TableCell align="right">
      <IconButton
        size="small"
        className={classes.deletionButton}
        disabled={deleting}
        onClick={() => deleteLicensePackage(licensePackageId)}
      >
        <DeleteIcon />
      </IconButton>
    </TableCell>
  );
}
