import {
  Button,
  createStyles,
  Grid,
  Icon,
  makeStyles,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Dispatch, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import strings from "../common/strings";
import useDownloadExcel from "../common/useDownloadExcel";
import useDownloadHaccp from "../common/useDownloadHaccp";
import useFormUtils from "../common/useFormUtils";
import ExportDialog, { ExportPreset } from "./ExportDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    searchContainer: {
      [theme.breakpoints.up("md")]: {
        alignItems: "center",
      },
    },
  })
);

export default function SearchControls({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string;
  setSearchTerm: Dispatch<string>;
}) {
  const isSmOrUp = useMediaQuery(useTheme().breakpoints.up("sm"));
  const classes = useStyles();

  const [, commonFieldProps] = useFormUtils();

  const [downloadExcel, downloadingExcel] = useDownloadExcel();
  const [downloadHaccp, downloadingHaccp] = useDownloadHaccp();
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const openExportDialog = useCallback(() => setExportDialogOpen(true), []);
  const closeExportDialog = useCallback(() => setExportDialogOpen(false), []);
  const handleExcelExportDialogCompletion = useCallback(
    (preset: ExportPreset) => {
      closeExportDialog();
      switch (preset.kind) {
        case "xlsx":
          downloadExcel(preset.startTime, preset.endTime);
          break;
        case "haccp":
          downloadHaccp(preset.date);
          break;
        default:
          throw new TypeError(
            `We haven't implemented export logic for "${
              (preset as { kind: unknown }).kind
            }"!`
          );
      }
    },
    [closeExportDialog, downloadExcel, downloadHaccp]
  );

  return (
    <>
      <Grid
        item
        xs={12}
        container
        spacing={1}
        className={classes.searchContainer}
        alignItems="center"
      >
        <Grid item xs sm={8}>
          <TextField
            {...commonFieldProps}
            label={strings.searchTerm}
            fullWidth
            value={searchTerm}
            onChange={useCallback(
              (e) => setSearchTerm(e.target.value),
              [setSearchTerm]
            )}
          />
        </Grid>
        <Grid item xs="auto" sm={3}>
          {isSmOrUp ? (
            <Button
              component={Link}
              to="/sensors/find-by-qr"
              variant="outlined"
              fullWidth
              startIcon={<Icon>qr_code_scanner</Icon>}
            >
              {strings.searchByQrCode}
            </Button>
          ) : (
            <Button
              component={Link}
              to="/sensors/find-by-qr"
              variant="outlined"
              fullWidth
            >
              <Icon>qr_code_scanner</Icon>
            </Button>
          )}
        </Grid>
        <Grid item xs="auto" sm={1}>
          <Button
            variant="outlined"
            fullWidth
            onClick={openExportDialog}
            disabled={downloadingExcel || downloadingHaccp}
          >
            <Icon>download</Icon>
          </Button>
        </Grid>
      </Grid>
      <ExportDialog
        open={exportDialogOpen}
        onCancel={closeExportDialog}
        onComplete={handleExcelExportDialogCompletion}
      />
    </>
  );
}
