import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import { DispatchWithoutAction, useCallback, useRef } from "react";
import useAdminUidOverride from "../../common/useAdminUidOverride";
import UserPicker from "../../common/UserPicker";
import useSwitchUserDialogOpen from "./useSwitchUserDialogOpen";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  })
);

export default function SwitchUserDialog() {
  const [open, setOpen] = useSwitchUserDialogOpen();
  const [, setUidOverride] = useAdminUidOverride();

  const refreshRef = useRef<DispatchWithoutAction>();
  const handleClose = useCallback(() => {
    setOpen(false);
    refreshRef?.current?.();
  }, [setOpen]);
  const onSelect = useCallback(
    (uid: string) => {
      setUidOverride(uid);
      handleClose();
    },
    [setUidOverride, handleClose]
  );

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Kies een gebruiker</DialogTitle>
      <DialogContent className={classes.content}>
        <UserPicker {...{ onSelect, refreshRef }} />
      </DialogContent>
    </Dialog>
  );
}
