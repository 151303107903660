import firebase from "firebase/app";
import { useIdTokenResult } from "./useIdTokenResult";

export default function useCustomerPath(): [
  string | undefined,
  boolean,
  firebase.auth.Error | undefined
] {
  const [idTokenResult, loading, error] = useIdTokenResult();

  return [idTokenResult?.claims.customer, loading, error];
}
