import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@material-ui/core";
import isEqual from "lodash/isEqual";
import { useCallback, useEffect, useRef, useState } from "react";
import UserPicker from "../../../common/UserPicker";
import strings from "../../../common/strings";
import useCloudFunction from "../../../common/useCloudFunction";
import { standardFieldProps } from "../../../common/useFormUtils";
import { useNameField } from "../../fields";

export default function EditDialog({
  open,
  setOpen,
  ...canonical
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  id: string;
  name: string;
  enabled: true | readonly string[];
}) {
  const [name, nameValid, nameFieldProps, resetName] = useNameField(
    canonical.name
  );
  const [enabled, setEnabled] = useState(canonical.enabled);

  const openRef = useRef(open);
  useEffect(() => {
    if (open && open !== openRef.current) {
      resetName();
      setEnabled(canonical.enabled);
    }

    openRef.current = open;
  }, [open, resetName, canonical.enabled, enabled]);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [updateFeatureFlag] = useCloudFunction<{
    id: string;
    name?: string;
    enabled?: true | readonly string[];
  }>("updateFeatureFlag");

  const submit = useCallback(() => {
    let values: { name?: string; enabled?: true | readonly string[] } = {};

    if (name !== canonical.name) values.name = name;
    if (
      enabled === true
        ? canonical.enabled !== true
        : canonical.enabled === true ||
          !isEqual(new Set(enabled), new Set(canonical.enabled))
    )
      values.enabled = enabled;

    if (values.name !== undefined || values.enabled !== undefined)
      updateFeatureFlag({ id: canonical.id, ...values });

    close();
  }, [
    name,
    canonical.name,
    enabled,
    canonical.enabled,
    updateFeatureFlag,
    canonical.id,
    close,
  ]);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{strings.editFeatureFlag}</DialogTitle>
      <DialogContent>
        <TextField
          {...standardFieldProps}
          {...nameFieldProps}
          value={name}
          label={strings.name}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() => {
                  setEnabled((enabled) =>
                    enabled === true
                      ? canonical.enabled === true
                        ? []
                        : canonical.enabled
                      : true
                  );
                }}
                checked={enabled === true}
              />
            }
            label={strings.enableForAllUsers}
          />
        </FormGroup>
        {enabled !== true && (
          <FormGroup>
            <UserPicker
              selected={enabled}
              onSelect={(uid) => {
                setEnabled((enabled) => {
                  if (enabled === true) return true;
                  else if (enabled.includes(uid))
                    return enabled.filter((element) => element !== uid);
                  else return [...enabled, uid];
                });
              }}
            />
          </FormGroup>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{strings.cancel}</Button>
        <Button disabled={!nameValid} onClick={submit} color="primary">
          {strings.editFeatureFlag}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
