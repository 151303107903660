import {
  Button,
  createStyles,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import strings from "../common/strings";
import useFormUtils from "../common/useFormUtils";
import { auth } from "../firebaseApp";
import errorMessages from "./errorMessages";

const useStyles = makeStyles((theme) =>
  createStyles({
    labeledDividerWrapper: {
      marginTop: theme.spacing(),
      display: "block",
      textAlign: "center",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
    labeledDivider: {
      lineHeight: "18px",
      display: "inline-block",
      position: "relative",
      "&::before": {
        content: "''",
        height: 1,
        backgroundColor: theme.palette.text.hint,
        position: "absolute",
        top: "50%",
        right: "100%",
        marginRight: 15,
        width: 9999,
      },
      "&::after": {
        content: "''",
        height: 1,
        backgroundColor: theme.palette.text.hint,
        position: "absolute",
        top: "50%",
        left: "100%",
        marginLeft: 15,
        width: 9999,
      },
    },
  })
);

export default function SignInForm({
  email,
  showPasswordResetForm,
  goBack,
}: {
  email: string;
  showPasswordResetForm?(): void;
  goBack?(): void;
}) {
  const [password, setPassword] = useState("");

  const [signIn, , signingIn, error] = useSignInWithEmailAndPassword(auth);

  const [errorTarget, errorMessage] =
    error != null
      ? errorMessages.get(error.code) ?? [null, null]
      : [null, null];

  const classes = useStyles();

  const [
    formClasses,
    standardFieldProps,
    standardButtonProps,
    standardMinorButtonProps,
  ] = useFormUtils();

  return (
    <form
      className={formClasses.form}
      onSubmit={(e) => {
        e.preventDefault();
        signIn(email, password);
      }}
    >
      <input hidden id="email" value={email} />
      <TextField
        id="password"
        label={strings.password}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={errorTarget === "password"}
        helperText={errorTarget === "password" ? errorMessage : null}
        {...standardFieldProps}
      />
      <Button
        type="submit"
        disabled={signingIn}
        className={formClasses.button}
        {...standardButtonProps}
      >
        {strings.signIn}
      </Button>
      <div className={classes.labeledDividerWrapper}>
        <Typography variant="caption" className={classes.labeledDivider}>
          {strings.or}
        </Typography>
      </div>
      <Button
        type="button"
        className={formClasses.button}
        onClick={showPasswordResetForm}
        {...standardMinorButtonProps}
      >
        {strings.resetPassword}
      </Button>
      <Button
        type="button"
        className={formClasses.button}
        onClick={goBack}
        {...standardMinorButtonProps}
      >
        {strings.back}
      </Button>
    </form>
  );
}
