import { Button, TextField } from "@material-ui/core";
import strings from "../common/strings";
import useFormField, {
  useMaximumTemperatureValidator,
  numberFieldParser,
} from "../common/useFormField";
import useFormUtils from "../common/useFormUtils";
import BackButton from "./BackButton";

export default function NameForm({
  onSubmit,
  onCancel,
  disabled = false,
}: {
  onSubmit(
    name: string,
    minimumTemperature: number,
    maximumTemperature: number
  ): void;
  onCancel(): void;
  disabled?: boolean;
}) {
  const [classes, commonTextFieldProps, commonButtonProps] = useFormUtils();

  const textFieldProps = { ...commonTextFieldProps, disabled };

  const [name, nameIsValid, nameFieldProps, resetName] = useFormField(
    "",
    (rawValue) => rawValue,
    (name) => (name.match(/^\s*$/) === null ? null : strings.emptyFormField)
  );

  const [
    minimumTemperature,
    minimumTemperatureIsValid,
    minimumTemperatureFieldProps,
    resetMinimumTemperature,
  ] = useFormField<number | null>(
    null,
    numberFieldParser,
    (minimumTemperature) =>
      minimumTemperature != null ? null : strings.emptyFormField
  );

  const [
    maximumTemperature,
    maximumTemperatureIsValid,
    maximumTemperatureFieldProps,
    resetMaximumTemperature,
  ] = useFormField<number | null>(
    null,
    numberFieldParser,
    useMaximumTemperatureValidator(minimumTemperature)
  );

  /**
   * Resets the form.
   */
  const reset = () => {
    resetName();
    resetMinimumTemperature();
    resetMaximumTemperature();
  };

  const formIsValid =
    nameIsValid && minimumTemperatureIsValid && maximumTemperatureIsValid;

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();

        if (
          nameIsValid &&
          minimumTemperatureIsValid &&
          maximumTemperatureIsValid
        ) {
          reset();
          onSubmit(
            name,
            // Use explicit type casting as we've already verified that these values aren't null
            minimumTemperature as number,
            maximumTemperature as number
          );
        }
      }}
    >
      <TextField
        label={strings.sensorName}
        {...textFieldProps}
        value={name}
        {...nameFieldProps}
      />
      <TextField
        label={strings.minimumTemperature}
        {...textFieldProps}
        value={minimumTemperature ?? ""}
        type="number"
        {...minimumTemperatureFieldProps}
      />
      <TextField
        label={strings.maximumTemperature}
        {...textFieldProps}
        value={maximumTemperature ?? ""}
        type="number"
        {...maximumTemperatureFieldProps}
      />
      <Button
        className={classes.button}
        type="submit"
        disabled={disabled || !formIsValid}
        {...commonButtonProps}
      >
        {strings.connectSensor}
      </Button>
      <BackButton
        type="button"
        onClick={() => {
          reset();
          onCancel();
        }}
        disabled={disabled}
      />
    </form>
  );
}
