import { CircularProgress, makeStyles } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import { PropsWithChildren } from "react";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginTop: theme.spacing(1),
    },
  })
);

export default function LoadingIndicator({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress />
      {children && <span>{children}</span>}
    </div>
  );
}
