import AcUnitIcon from "@material-ui/icons/AcUnit";
import strings from "../common/strings";
import MenuItemProps from "./MenuItemProps";
import NavigationItem from "./NavigationItem";

export default function SensorsButton(props: MenuItemProps) {
  return (
    <NavigationItem
      {...props}
      icon={<AcUnitIcon />}
      label={strings.refrigerators}
      url="/sensors"
    />
  );
}
