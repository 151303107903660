import { Button } from "@material-ui/core";
import { useReferentiallyStableMemo } from "haakje";
import { useState } from "react";
import UserPicker from "../../common/UserPicker";
import strings from "../../common/strings";
import useCloudFunction from "../../common/useCloudFunction";
import { standardButtonProps } from "../../common/useFormUtils";
import FormCard from "../FormCard";

export default function WeeklyReport() {
  const [sendWeeklyReport, sending] = useCloudFunction<string>(
    "manuallySendWeeklyReport"
  );

  const [uid, setUid] = useState<string>();

  return (
    <FormCard title={strings.weeklyReport}>
      <UserPicker
        onSelect={setUid}
        disabled={sending}
        selected={useReferentiallyStableMemo(
          () => (uid !== undefined ? [uid] : undefined),
          [uid]
        )}
      />
      <Button
        {...standardButtonProps}
        onClick={
          !sending && uid !== undefined
            ? () => {
                sendWeeklyReport(uid);
                setUid(undefined);
              }
            : undefined
        }
        disabled={uid === undefined || sending}
      >
        {strings.sendEmail}
      </Button>
    </FormCard>
  );
}
