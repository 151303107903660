import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import strings from "../../common/strings";
import { firestore } from "../../firebaseApp";
import { ISensor } from "../common";

async function setSensorExternalProbe(
  sensorId: string,
  externalProbe: boolean
) {
  await firestore.collection("sensors").doc(sensorId).update({ externalProbe });
}

function useSetExternalProbe() {
  const [promise, setPromise] = useState<Promise<void>>();

  useEffect(() => {
    if (promise !== undefined) {
      let cancelled = false;

      promise.finally(() => {
        if (!cancelled) setPromise(undefined);
      });

      return () => {
        cancelled = true;
      };
    }
  }, [promise]);

  return [
    useCallback(
      (sensorId: string, externalProbe: boolean) => {
        setPromise(setSensorExternalProbe(sensorId, externalProbe));
      },
      [setPromise]
    ),
    promise !== undefined,
  ] as const;
}

export default function ExternalProbeConfigurationDialog({
  sensor,
  isOpen,
  handleClose,
}: {
  sensor: ISensor;
  isOpen: boolean;
  handleClose(): void;
}) {
  const [setExternalProbe, settingExternalProbe] = useSetExternalProbe();

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={`external-probe-${sensor.id}-dialog-title`}
    >
      <DialogTitle id={`external-probe-${sensor.id}-dialog-title`}>
        {strings.configureExternalProbe}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={() =>
                  setExternalProbe(sensor.id, !sensor.externalProbe)
                }
                checked={sensor.externalProbe}
                disabled={settingExternalProbe}
              />
            }
            label={strings.useExternalProbe}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{strings.okay}</Button>
      </DialogActions>
    </Dialog>
  );
}
