import {
  IconButton,
  MenuItem,
  Snackbar,
  TableCell,
  TableRow,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import ContextMenuButton from "../../common/ContextMenuButton";
import strings from "../../common/strings";
import useCloudFunction from "../../common/useCloudFunction";
import { auth } from "../../firebaseApp";
import DeleteDialog from "./dialogs/DeleteDialog";
import { IAdmin } from "./useAdmins";

export default function Admin({ admin }: { admin: IAdmin }) {
  const [authState, loadingAuthState, loadingAuthStateError] =
    useAuthState(auth);
  const [showLoadingAuthStateError, setShowLoadingAuthStateError] =
    useState(false);

  useEffect(() => {
    setShowLoadingAuthStateError(!!loadingAuthStateError);
  }, [loadingAuthStateError]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [_sendEmail, sendingEmail, sendEmailError, resetSendEmailError] =
    useCloudFunction("resendAdminToken");

  const sendEmail = () => {
    setMenuOpen(false);
    _sendEmail(admin.id);
  };

  const [_deleteAdmin, deletingAdmin, deleteAdminError, resetDeleteAdminError] =
    useCloudFunction(
      admin.status === "roleGranted" ? "revokeAdminRole" : "deleteAdminToken"
    );
  const deleteAdmin = () => {
    _deleteAdmin(admin.id);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
    setMenuOpen(false);
  };

  return (
    <TableRow>
      <TableCell>{admin.email}</TableCell>
      <TableCell align="right">
        {admin.status === "emailSent" ? strings.emailSent : strings.roleGranted}
      </TableCell>
      <TableCell align="center">
        <ContextMenuButton open={menuOpen} setOpen={setMenuOpen}>
          {admin.status !== "roleGranted" && (
            <MenuItem
              disabled={sendingEmail || loadingAuthState}
              onClick={sendEmail}
            >
              {strings.sendEmail}
            </MenuItem>
          )}
          <MenuItem
            disabled={
              loadingAuthState ||
              !!loadingAuthStateError ||
              (admin.status === "roleGranted" && authState?.uid === admin.id)
            }
            onClick={openDeleteDialog}
          >
            {strings.delete}
          </MenuItem>
        </ContextMenuButton>
      </TableCell>
      <DeleteDialog
        admin={admin}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onOk={deleteAdmin}
      />
      {/* Email sending status */}
      <Snackbar message={strings.sendingEmail} open={sendingEmail} />
      <Snackbar
        message={strings.genericError}
        open={!!sendEmailError}
        onClose={resetSendEmailError}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetSendEmailError}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {/* Admin deletion status */}
      <Snackbar message={strings.deletingAdmin} open={deletingAdmin} />
      <Snackbar
        message={strings.genericError}
        open={!!deleteAdminError}
        onClose={resetDeleteAdminError}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetDeleteAdminError}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {/* Auth state errors */}
      <Snackbar
        message={strings.genericError}
        open={showLoadingAuthStateError}
        onClose={() => setShowLoadingAuthStateError(false)}
        autoHideDuration={2000}
      />
    </TableRow>
  );
}
