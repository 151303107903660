import { useState } from "react";
import useCloudFunction from "../../common/useCloudFunction";

export default function usePasswordResetLink() {
  const [passwordResetLink, setPasswordResetLink] = useState<string>();

  // TODO: Add a type assertion
  const [fetchPasswordResetLink, loading, error] = useCloudFunction<string>(
    "getPasswordResetLink",
    setPasswordResetLink
  );

  return [passwordResetLink, fetchPasswordResetLink, loading, error] as const;
}
