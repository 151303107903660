import { createStyles, Grid, makeStyles } from "@material-ui/core";
import { Route, Switch } from "react-router-dom";
import CustomerPage from "./CustomerPage";
import Customers from "./Customers";
import Employees from "./Employees";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(),
    },
    errorMessage: {
      textAlign: "center",
    },
  })
);

export default function ResellerPanelPage() {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.root}>
      <Switch>
        <Route
          path="/reseller/:resellerId/customer/:customerId"
          component={CustomerPage}
        />
        <Route path="/reseller/:resellerId">
          <Route>
            {() => (
              <>
                <Employees />
                <Customers />
              </>
            )}
          </Route>
        </Route>
      </Switch>
    </Grid>
  );
}
