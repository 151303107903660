import { useEffect, useState } from "react";
import { Observable, Subject } from "rxjs";

export function useSubscription<T>(
  observable: Observable<T>,
  subscriber: (value: T) => void
) {
  useEffect(() => {
    const subscription = observable.subscribe(subscriber);

    return () => subscription.unsubscribe();
  }, [observable, subscriber]);
}

export function useObservableValue<T>(
  observable: Observable<T>,
  initialValue: T
) {
  const [value, setValue] = useState<T>(initialValue);

  useSubscription(observable, setValue);

  return value;
}

export function useSubject<T>() {
  const [subject] = useState(() => new Subject<T>());

  useEffect(() => () => subject.unsubscribe(), [subject]);

  return subject;
}
