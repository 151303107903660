import { Button, Grid, Typography } from "@material-ui/core";
import firebase from "firebase/app";
import { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  UseAdminTokenFailureReasons,
  UseAdminTokenResult,
} from "../../../common/admins";
import ErrorDisplay from "../common/ErrorDisplay";
import LoadingIndicator from "../common/LoadingIndicator";
import LogoWrapper from "../common/LogoWrapper";
import strings from "../common/strings";
import { useIdTokenResult } from "../common/useIdTokenResult";
import { useSignOut } from "../common/useSignOut";
import { auth, functions } from "../firebaseApp";

const errorDescriptions: Record<UseAdminTokenFailureReasons, () => string> = {
  invalidToken: () => strings.invalidToken,
  usedToken: () => strings.tokenAlreadyUsed,
};

export default function CustomerTokenProcessor() {
  // Get the token
  const {
    params: { token },
  } = useRouteMatch<{ token: string }>("/token/customer/:token") ?? {
    params: {},
  };

  // Use the token
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<
    firebase.FirebaseError | UseAdminTokenFailureReasons
  >();
  const [tokenPromise, setTokenPromise] =
    useState<Promise<UseAdminTokenResult>>();

  const useToken = useCallback(() => {
    if (token !== undefined)
      setTokenPromise(
        functions
          .httpsCallable("useCustomerToken")(token)
          .then(({ data }): UseAdminTokenResult => data)
      );
  }, [token, setTokenPromise]);

  useEffect(() => {
    if (tokenPromise !== undefined) {
      let cancelled = false;

      (async () => {
        try {
          const result = await tokenPromise;

          if (!cancelled) {
            setSuccess(
              (alreadySuccessful) => alreadySuccessful || result.success
            );
            setError(result.reason);
          }
        } catch (error: any) {
          setError(error);
        } finally {
          if (!cancelled) setTokenPromise(undefined);
        }
      })();

      return () => {
        cancelled = true;
      };
    }
  }, [tokenPromise]);

  // Navigate to the sensor dashboard on success
  const [, , , refreshIdTokenResult] = useIdTokenResult();
  const history = useHistory();

  useEffect(() => {
    if (success) {
      refreshIdTokenResult();

      history.push("/sensors");
    }
  }, [success, refreshIdTokenResult, history]);

  // Get the user's email
  const [authState, loadingAuthState, errorLoadingAuthState] =
    useAuthState(auth);

  // Use the logic for the sign out button
  const [signOut, signingOut] = useSignOut();

  // Render the UI
  if (loadingAuthState || tokenPromise !== undefined || success)
    return (
      <LogoWrapper>
        <LoadingIndicator />
      </LogoWrapper>
    );
  else if (error)
    return (
      <LogoWrapper>
        <Grid container>
          <ErrorDisplay>
            {typeof error === "string"
              ? errorDescriptions[error]?.() ?? error
              : error?.code === "invalid-argument"
              ? errorDescriptions.invalidToken()
              : error?.toString() ?? "Er is een onbekende fout opgetreden."}
          </ErrorDisplay>
        </Grid>
      </LogoWrapper>
    );
  else if (errorLoadingAuthState)
    return (
      <LogoWrapper>
        <Grid container>
          <ErrorDisplay>{strings.genericError}</ErrorDisplay>
        </Grid>
      </LogoWrapper>
    );
  else
    return (
      <LogoWrapper>
        <Grid container alignItems="center" direction="column">
          <Grid item xs={12}>
            <Typography paragraph>
              {strings.formatString(strings.customerTokenExplaination, {
                buttonLabel: strings.becomeACustomer,
                user:
                  authState?.email != null ? (
                    <>
                      {strings.formatString(
                        strings.userWithEmail,
                        authState.email
                      )}
                    </>
                  ) : (
                    strings.currentUser
                  ),
              })}
            </Typography>
          </Grid>
          <Grid item container xs={8} justify="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={signOut}
                disabled={signingOut}
              >
                {strings.useDifferentAccount}
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth={false}
                color="primary"
                variant="contained"
                onClick={useToken}
                disabled={tokenPromise !== undefined || signingOut || success}
              >
                {strings.becomeACustomer}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </LogoWrapper>
    );
}
