import { useEffect, useState } from "react";

export default function usePromise<T>(
  onResolve?: (value: T) => void,
  onReject?: (reason: any) => void,
  onComplete?: () => void
) {
  const [promise, setPromise] = useState<Promise<T>>();

  useEffect(() => {
    if (promise != null) {
      let cancelled = false;

      if (onResolve)
        promise.then((result) => {
          if (!cancelled) onResolve(result);
        });

      if (onReject)
        promise.catch((reason) => {
          if (!cancelled) onReject(reason);
        });

      promise.finally(() => {
        if (!cancelled) {
          onComplete?.();

          setPromise(undefined);
        }
      });

      return () => {
        cancelled = true;
      };
    }
  }, [promise, onResolve, onReject, onComplete]);

  return setPromise;
}
