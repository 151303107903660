import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../../firebaseApp";

export interface IReseller {
  id: string;
  name: string;
}

export default function useResellers(): [
  IReseller[],
  boolean,
  firebase.FirebaseError?
] {
  const [resellers, loading, error] = useCollectionData<IReseller>(
    firestore.collection("resellers"),
    {
      idField: "id"
    }
  );

  return [resellers ?? [], loading, error];
}
