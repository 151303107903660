import { createMuiTheme } from "@material-ui/core";

export default createMuiTheme({
  palette: {
    primary: {
      main: "#075EA9",
    },
    secondary: {
      main: "#EF7A00",
    },
    background: {
      default: "#F6F7F9",
      paper: "#FFFFFF"
    }
  },
});
