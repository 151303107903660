import { Button, Grid } from "@material-ui/core";
import { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import ErrorDisplay from "./common/ErrorDisplay";
import LoadingIndicator from "./common/LoadingIndicator";
import LogoWrapper from "./common/LogoWrapper";
import strings from "./common/strings";
import useIsCustomer from "./common/useIsCustomer";

function CustomerWrapperErrorDisplay({ children }: PropsWithChildren<{}>) {
  return (
    <LogoWrapper>
      <Grid container direction="column" alignItems="center" spacing={1}>
        <ErrorDisplay>{children}</ErrorDisplay>
        <Grid item>
          <Button component={Link} to="/" variant="contained" color="primary">
            {strings.returnToLandingPage}
          </Button>
        </Grid>
      </Grid>
    </LogoWrapper>
  );
}

export default function CustomerWrapper({ children }: PropsWithChildren<{}>) {
  const [isCustomer, loadingIsCustomer, errorLoadingIsCustomer] =
    useIsCustomer();

  if (loadingIsCustomer)
    return (
      <LogoWrapper>
        <LoadingIndicator />
      </LogoWrapper>
    );
  else if (errorLoadingIsCustomer !== undefined)
    return (
      <CustomerWrapperErrorDisplay>
        {strings.genericError}
      </CustomerWrapperErrorDisplay>
    );
  else if (!isCustomer)
    return (
      <CustomerWrapperErrorDisplay>
        {strings.onlyCustomersCanVisitThisPage}
      </CustomerWrapperErrorDisplay>
    );
  else return <>{children}</>;
}
