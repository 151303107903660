import { TextField, TextFieldProps } from "@material-ui/core";
import { isValidPhoneNumber } from "libphonenumber-js/mobile";
import { ReactNode } from "react";
import useFormField from "../common/useFormField";
import useFormUtils from "../common/useFormUtils";
import strings from "./strings";

export default function usePhoneNumberInput(
  defaultValue: string,
  props: Partial<TextFieldProps>
): [string, boolean, () => void, ReactNode] {
  const [, commonFieldProps] = useFormUtils();
  const [
    phoneNumber,
    isPhoneNumberValid,
    phoneNumberFieldProps,
    resetPhoneNumber,
  ] = useFormField<string>(
    defaultValue,
    (rawValue) => rawValue,
    (value) =>
      isValidPhoneNumber(value, "NL") ? null : strings.invalidPhoneNumber
  );

  return [
    phoneNumber,
    isPhoneNumberValid,
    resetPhoneNumber,
    <TextField
      {...commonFieldProps}
      type="phone"
      label={strings.phoneNumber}
      fullWidth
      value={phoneNumber}
      {...phoneNumberFieldProps}
      {...props}
    />,
  ];
}
