import { CssBaseline } from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { IdTokenResultProvider } from "./common/useIdTokenResult";
import reportWebVitals from "./reportWebVitals";

// HACK: Fix a bug causing Buffer to be undefined in Chrome
import { Buffer } from "buffer";

global.Buffer = Buffer;

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <Router>
      <IdTokenResultProvider>
        <App />
      </IdTokenResultProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
