import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase/app";
import { Dispatch, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useEmailField, useNameField } from "../../../../AdminPanel/fields";
import strings from "../../../../common/strings";
import useCloudFunction from "../../../../common/useCloudFunction";
import useFormUtils from "../../../../common/useFormUtils";
import { firestore } from "../../../../firebaseApp";

export default function CreateDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<boolean>;
}) {
  const { resellerId } = useParams<{ resellerId: string }>();
  const [, commonFieldProps] = useFormUtils();

  const [email, emailValid, emailFieldProps, resetEmail] = useEmailField();
  const [name, nameValid, nameFieldProps, resetName] = useNameField();

  const handleClose = () => {
    setOpen(false);
    resetEmail();
    resetName();
  };

  const [
    sendCustomerToken,
    sendingCustomerToken,
    sendCustomerTokenError,
    resetSendCustomerTokenError,
  ] = useCloudFunction("sendCustomerToken");

  const [creatingCustomerError, setCreatingCustomerError] =
    useState<firebase.FirebaseError>();
  const resetCreatingCustomerError = () => setCreatingCustomerError(undefined);
  const [createCustomerPromise, setCreateCustomerPromise] =
    useState<Promise<{ customerId: string; email: string }>>();

  useEffect(() => {
    let cancelled = false;

    createCustomerPromise
      ?.then(({ customerId, email }) => {
        if (!cancelled) {
          setCreatingCustomerError(undefined);
          sendCustomerToken({ resellerId, customerId, email });
        }
      })
      .catch((error) => {
        if (!cancelled) setCreatingCustomerError(error);
      })
      .finally(() => {
        if (!cancelled) setCreateCustomerPromise(undefined);
      });

    return () => {
      cancelled = true;
    };
  }, [createCustomerPromise, sendCustomerToken, resellerId]);

  const submit = () => {
    if (emailValid && nameValid) {
      setCreateCustomerPromise(
        firestore
          .collection("resellers")
          .doc(resellerId)
          .collection("customers")
          .add({
            name,
          })
          .then((result) => ({ customerId: result.id, email }))
      );

      handleClose();
    }
  };

  const working = sendingCustomerToken || createCustomerPromise !== undefined;
  const error = sendCustomerTokenError || creatingCustomerError;

  const resetErrors = () => {
    resetSendCustomerTokenError();
    resetCreatingCustomerError();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={`create-customer-dialog-title`}
      >
        <DialogTitle id={`create-customer-dialog-title`}>
          {strings.createCustomer}
        </DialogTitle>
        <DialogContent>
          <TextField
            {...commonFieldProps}
            autoFocus
            margin="dense"
            label={strings.email}
            onKeyDown={({ key }) => {
              if (key === "Enter") {
                submit();
              }
            }}
            fullWidth
            value={email}
            {...emailFieldProps}
          />
          <TextField
            {...commonFieldProps}
            margin="dense"
            label={strings.name}
            onKeyDown={({ key }) => {
              if (key === "Enter") {
                submit();
              }
            }}
            fullWidth
            value={name}
            {...nameFieldProps}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.cancel}</Button>
          <Button onClick={submit} color="primary" disabled={!emailValid}>
            {strings.createCustomer}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar message={strings.sendingEmail} open={working} />
      <Snackbar
        message={strings.genericError}
        open={!!error}
        onClose={resetErrors}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetErrors}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}
