import { Typography } from "@material-ui/core";
import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  nl: {
    /**
     * General
     */
    // Error messages
    genericError: "Er is een fout opgetreden!",
    emptyFormField: "Dit veld mag niet leeg zijn",
    maximumTemperatureLesserThanMinimumTemperature:
      "De maximumtemperatuur moet groter dan de minimumtemperatuur zijn",
    minimumMaximumTemperatureDifferenceAtLeastThreeDegrees:
      "Het verschil tussen de minimumtemperatuur en de maximumtemperatuur moet minstens 3 graden Celsius zijn",
    invalidPhoneNumber: "Voer een geldig telefoonnummer in",
    onlyCustomersCanVisitThisPage:
      "U moet een klant zijn om deze pagina te bezoeken.",
    pageNotFound: "De pagina die u probeert te bezoeken is niet gevonden.",
    endTimeMustBeAfterStartTime:
      "De eindtijd moet later dan de starttijd zijn.",
    // Input labels
    phoneNumber: "Telefoonnummer",
    sensorName: "Sensornaam",
    // Buttons
    back: "Terug",
    cancel: "Annuleren",
    save: "Opslaan",
    moreInfo: "Meer informatie",
    refrigerators: "Koelingen",
    shop: "Winkel",
    signOut: "Uitloggen",
    switchUser: "Van gebruiker veranderen",
    buySubscription: "Abonnement kopen",
    returnToLandingPage: "Terug naar de startpagina",
    next: "Volgende",
    // Other
    or: "Of",
    // Introduction
    introduction: (
      <>
        <Typography paragraph>
          Welkom bij de Albird-temperatuurmonitor. Met onze app kunt u koelingen
          en vriezers draadloos monitoren van over de hele wereld. Daarnaast
          kunt u logboeken zien van de temperaturen, en kunt u meldingen
          ontvangen als koelingen buiten een door u ingesteld temperatuurbereik
          treden.
        </Typography>
        <Typography paragraph>
          Om ons systeem te gebruiken heeft u een gateway en sensoren nodig.
          Deze kunt u aanschaffen via onze webwinkel in deze app.
        </Typography>
        <Typography paragraph>
          Na aanschaf betaalt u enkel €&nbsp;5,- per maand per sensor die u in
          gebruik heeft.
        </Typography>
      </>
    ),
    alreadyBoughtSensors:
      "Heeft u al sensoren aangeschaft? Koop dan een abonnement!",
    orderHardware: "Of bestel eerst hardware;",
    /**
     * Auth
     */
    // Error messages
    wrongPassword: "Verkeerd wachtwoord",
    weakPassword: "Het wachtwoord is te zwak",
    userNotFound: "Er is geen account met dit e-mailadres",
    invalidEmail: "Ongelidg e-mailadres",
    emailAlreadyInUse: "Er is al een account met dit e-mailadres",
    passwordsDoNotMatch: "De wachtwoorden moeten overeenkomen",
    // Input labels
    email: "E-mailadres",
    password: "Wachtwoord",
    repeatPassword: "Wachtwoord herhalen",
    // Buttons
    sendResetLink: "Resetlink verzenden",
    signIn: "Inloggen",
    signUp: "Account aanmaken",
    resetPassword: "Wachtwoord opnieuw instellen",
    alreadyHaveAnAccount: "Ik heb al een account",
    // Statuses
    resetLinkSent: "Er is een resetlink naar {0} toegestuurd.",
    // Tooltips
    delete: "Verwijderen",
    loading: "Aan het laden",
    /**
     * Billing
     */
    // Titles
    upcomingInvoice: "Aankomende factuur",
    invoices: "Facturen",
    invoice: "Factuur",
    paymentMethods: "Betaalmethoden",
    // Buttons
    cancelSubscription: "Abonnement opzeggen",
    billing: "Facturering",
    addAccount: "Rekening toevoegen",
    // Statuses
    noRunningBillingPeriod: "Er loopt momenteel geen factureringsperiode",
    // Descriptions
    selectPaymentMethod: "Selecteer een betaalmethode",
    /**
     * Dashboard
     */
    editTemperatureRange: "Temperatuurbereik aanpassen",
    disconnect: "Ontkoppelen",
    disconnectSensor: "Sensor ontkopelen?",
    confirmSensorDisconnection:
      "Weet u zeker dat u de sensor {0} wilt ontkoppelen?",
    rename: "Hernoemen",
    renameSensor: "Sensor hernoemen",
    replace: "Vervangen",
    replaceSensor: "Sensor vervangen",
    searchByQrCode: "Zoeken op QR-code",
    searchTerm: "Zoekterm",
    smsDescription:
      "We zullen SMS-berichten sturen naar dit nummer in het geval dat een koeling buiten het ingestelde temperatuurbereik treedt.",
    export: "Exporteren",
    timeOfLastMeasurement: "Tijd van laatste meetpunt",
    configureExternalProbe: "Externe sonde configureren",
    // Input labels
    date: "Datum",
    minimumTemperature: "Minimumtemperatuur",
    maximumTemperature: "Maximumtemperatuur",
    startTime: "Starttijd",
    endTime: "Eindtijd",
    fromStart: "Vanaf begin",
    untilNow: "Tot nu",
    exportKind: "Uitvoertype",
    useExternalProbe: "Externe sonde gebruiken",
    // Titles
    exportExcelFile: "Excel-bestand exporteren",
    exportHaccpList: "HACCP-lijst exporteren",
    setPhoneNumber: "Telefoonnummer instellen",
    status: "Status",
    // Statuses
    durationTooLong: "Het ingestelde datumbereik is te lang.",
    noFailures: "Er zijn geen storingen opgetreden in de afgelopen 24 uur.",
    singularFailure: "Er is 1 storing opgetreden in de afgelopen 24 uur.",
    multipleFailures:
      "Er zijn {0} storingen opgetreden in de afgelopen 24 uur.",
    noData: "Er is geen data beschikbaar.",
    noDataForTimeRange: "Er is geen data voor het geselecteerde tijdsbereik.",
    noSensors:
      "U heeft nog geen sensoren gekoppeld, druk op de plusknop om te beginnen.",
    noSensorsFoundWithQuery:
      "Er zijn geen sensoren gevonden die overeenkomen met de zoektermen.",
    sensorNotFound: "Er is geen sensor gevonden voor deze QR-code.",
    // Axes
    time: "Tijd",
    temperature: "Temperatuur",
    // Tooltips
    enableNotifications: "Notificaties inschakelen",
    disableNotifications: "Notificaties uitschakelen",
    connectivityError: "Er is een fout opgetreden",
    outage: "Storing",
    okay: "Ok",
    noRecentData: "Geen recente data ontvangen",
    loadingLargeDataset:
      "We zijn een grote dataset aan het laden, dit kan even duren.",
    average: "Gemiddelde",
    range: "Bereik",
    // Export kinds
    excel: "Excel",
    haccp: "HACCP-lijst",
    /**
     * Sensor registration
     */
    scannerInstruction: "Houd de camera recht boven de QR-code op de sensor.",
    // Buttons
    connectSensor: "Sensor koppelen",
    connectAnotherSensor: "Nog een sensor koppelen",
    backToSensorList: "Terug naar sensorlijst",
    retry: "Opnieuw proberen",
    // Statuses
    sensorConnected: "Uw sensor is gekoppeld!",
    notEnoughLicenses: "U heeft niet genoeg licenties.",
    /**
     * Shop
     */
    // Titles
    sensor: "Sensor",
    gateway: "Gateway",
    // Descriptions
    sensorDescription: (
      <>
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
          sollicitudin sit amet urna nec efficitur. Aliquam dui nisl, egestas
          sit amet nunc in, fermentum cursus metus. Etiam blandit, ex eu dapibus
          consequat, turpis augue hendrerit lorem, vitae interdum purus magna id
          ex. Pellentesque habitant morbi tristique senectus et netus et
          malesuada fames ac turpis egestas. Quisque a libero augue. In ante
          odio, sollicitudin vitae turpis sit amet, volutpat fringilla elit.
          Integer purus leo, gravida quis dictum id, facilisis nec nisi. Aenean
          eget nulla et velit fermentum fringilla. Nunc quis sem tempus,
          consectetur massa vel, imperdiet nulla. Integer rhoncus laoreet nisl
          quis faucibus. Ut eu semper augue.
        </Typography>
        <Typography paragraph>
          Pellentesque at ex sed ipsum congue laoreet sed semper odio.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Integer nec malesuada tortor, consectetur
          varius eros. Nam lacinia erat tincidunt tortor pretium, sed tincidunt
          libero porttitor. Morbi eros dui, dignissim non vulputate ut,
          condimentum nec mi. Sed gravida nulla aliquam lectus blandit dictum.
          Fusce faucibus lacus non erat sodales fringilla. Nulla facilisi.
          Aenean at lobortis quam, eu tincidunt dolor. In non lacus ac mi
          convallis pharetra ac ut sem.
        </Typography>
        <Typography paragraph>
          Sed augue est, pharetra at pharetra quis, volutpat eu risus. Sed
          fringilla nisl vulputate neque venenatis porta. Morbi ut ipsum vitae
          est euismod suscipit in eu mi. Suspendisse sapien augue, lobortis ac
          odio in, sollicitudin faucibus mi. Aliquam placerat sapien sit amet
          ante mollis, malesuada dapibus est posuere. Morbi accumsan massa in
          justo faucibus, eu hendrerit nibh viverra. Aenean malesuada ex ut
          suscipit cursus. Maecenas porttitor pulvinar ornare. Mauris et turpis
          nec odio ultricies scelerisque id ac lectus. Nulla elementum magna ac
          dolor malesuada condimentum. Vivamus ultrices, justo ac pellentesque
          lobortis, mi velit vestibulum mi, at efficitur erat purus nec libero.
          Morbi et libero vitae mi varius feugiat. Mauris non ipsum lacus.
        </Typography>
        <Typography paragraph>
          Etiam sit amet nibh eget nisl viverra tincidunt a ut massa. Etiam ante
          eros, aliquet sed lectus eu, commodo tincidunt est. Donec tincidunt
          sagittis risus vitae pellentesque. Proin vitae ullamcorper elit.
          Aliquam facilisis tortor at sollicitudin blandit. Suspendisse potenti.
          Nam a congue augue. Cras auctor mattis dolor vel pretium. Integer
          ornare dapibus est, at gravida nibh. Praesent a consequat est. Aliquam
          sed scelerisque magna.
        </Typography>
        <Typography>
          Sed vitae orci iaculis, ultricies eros id, posuere lectus. Cras
          aliquet diam id tempor mollis. Aliquam vel arcu posuere, vestibulum
          est sed, accumsan metus. Nunc sit amet neque tortor. Integer purus
          dolor, venenatis id porta in, consequat non justo. Aliquam eleifend
          sapien fringilla felis iaculis rhoncus. Nullam lectus nibh, euismod
          eget justo id, ullamcorper venenatis quam. Suspendisse tempor ante
          ipsum, at lacinia arcu scelerisque ut. Donec suscipit urna nec laoreet
          efficitur. Duis nec leo a tortor lacinia consectetur in at urna. In
          blandit odio eget hendrerit mollis. Donec ornare, ipsum vel porta
          ultrices, lectus nisl iaculis arcu, ac laoreet elit ante at nibh.
        </Typography>
      </>
    ),
    gatewayDescription: (
      <>
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
          sollicitudin sit amet urna nec efficitur. Aliquam dui nisl, egestas
          sit amet nunc in, fermentum cursus metus. Etiam blandit, ex eu dapibus
          consequat, turpis augue hendrerit lorem, vitae interdum purus magna id
          ex. Pellentesque habitant morbi tristique senectus et netus et
          malesuada fames ac turpis egestas. Quisque a libero augue. In ante
          odio, sollicitudin vitae turpis sit amet, volutpat fringilla elit.
          Integer purus leo, gravida quis dictum id, facilisis nec nisi. Aenean
          eget nulla et velit fermentum fringilla. Nunc quis sem tempus,
          consectetur massa vel, imperdiet nulla. Integer rhoncus laoreet nisl
          quis faucibus. Ut eu semper augue.
        </Typography>
        <Typography paragraph>
          Pellentesque at ex sed ipsum congue laoreet sed semper odio.
          Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas. Integer nec malesuada tortor, consectetur
          varius eros. Nam lacinia erat tincidunt tortor pretium, sed tincidunt
          libero porttitor. Morbi eros dui, dignissim non vulputate ut,
          condimentum nec mi. Sed gravida nulla aliquam lectus blandit dictum.
          Fusce faucibus lacus non erat sodales fringilla. Nulla facilisi.
          Aenean at lobortis quam, eu tincidunt dolor. In non lacus ac mi
          convallis pharetra ac ut sem.
        </Typography>
        <Typography paragraph>
          Sed augue est, pharetra at pharetra quis, volutpat eu risus. Sed
          fringilla nisl vulputate neque venenatis porta. Morbi ut ipsum vitae
          est euismod suscipit in eu mi. Suspendisse sapien augue, lobortis ac
          odio in, sollicitudin faucibus mi. Aliquam placerat sapien sit amet
          ante mollis, malesuada dapibus est posuere. Morbi accumsan massa in
          justo faucibus, eu hendrerit nibh viverra. Aenean malesuada ex ut
          suscipit cursus. Maecenas porttitor pulvinar ornare. Mauris et turpis
          nec odio ultricies scelerisque id ac lectus. Nulla elementum magna ac
          dolor malesuada condimentum. Vivamus ultrices, justo ac pellentesque
          lobortis, mi velit vestibulum mi, at efficitur erat purus nec libero.
          Morbi et libero vitae mi varius feugiat. Mauris non ipsum lacus.
        </Typography>
        <Typography paragraph>
          Etiam sit amet nibh eget nisl viverra tincidunt a ut massa. Etiam ante
          eros, aliquet sed lectus eu, commodo tincidunt est. Donec tincidunt
          sagittis risus vitae pellentesque. Proin vitae ullamcorper elit.
          Aliquam facilisis tortor at sollicitudin blandit. Suspendisse potenti.
          Nam a congue augue. Cras auctor mattis dolor vel pretium. Integer
          ornare dapibus est, at gravida nibh. Praesent a consequat est. Aliquam
          sed scelerisque magna.
        </Typography>
        <Typography>
          Sed vitae orci iaculis, ultricies eros id, posuere lectus. Cras
          aliquet diam id tempor mollis. Aliquam vel arcu posuere, vestibulum
          est sed, accumsan metus. Nunc sit amet neque tortor. Integer purus
          dolor, venenatis id porta in, consequat non justo. Aliquam eleifend
          sapien fringilla felis iaculis rhoncus. Nullam lectus nibh, euismod
          eget justo id, ullamcorper venenatis quam. Suspendisse tempor ante
          ipsum, at lacinia arcu scelerisque ut. Donec suscipit urna nec laoreet
          efficitur. Duis nec leo a tortor lacinia consectetur in at urna. In
          blandit odio eget hendrerit mollis. Donec ornare, ipsum vel porta
          ultrices, lectus nisl iaculis arcu, ac laoreet elit ante at nibh.
        </Typography>
      </>
    ),
    // Labels
    total: "Totaal",
    quantity: "Aantal",
    accountHolderName: "Naam",
    // Buttons
    checkOut: "Afrekenen",
    startOver: "Opnieuw beginnen",
    toIdeal: "Naar iDEAL",
    toStore: "Naar winkel gaan",
    // Error messages
    amountShallNotBeNegative: "Het aantal mag niet negatief zijn",
    // Statuses
    thanksForYourOrder: "Bedankt voor uw bestelling!",
    thanksForYourOrder2:
      "We gaan zo snel mogelijk aan de slag voor u. U zult een e-mail ontvangen zodra we uw bestelling in behandeling nemen.",
    orderCancelled: "Bestelling geannuleerd",
    // Mandate
    mandate: `By providing your payment information and confirming this payment, you
    authorise (A) and Stripe, our payment service provider, to send
    instructions to your bank to debit your account and (B) your bank to
    debit your account in accordance with those instructions. As part of
    your rights, you are entitled to a refund from your bank under the terms
    and conditions of your agreement with your bank. A refund must be
    claimed within 8 weeks starting from the date on which your account was
    debited. Your rights are explained in a statement that you can obtain
    from your bank. You agree to receive notifications for future debits up
    to 2 days before they occur.`,
    /**
     * Admin panel
     */
    confirmAdminDeletion:
      "Weet u zeker dat u de beheerder {0} wilt verwijderen?",
    confirmFeatureFlagDeletion:
      "Weet u zeker dat u de feature {0} wilt verwijderen?",
    confirmResellerDeletion:
      "Weet u zeker dat u de reseller {0} wilt verwijderen?",
    confirmReadingDeletion:
      "Weet u zeker dat u deze data wilt verwijderen?",
    // Labels
    appEui: "App EUI",
    appKey: "App key",
    content: "Inhoud",
    deviceEui: "Device EUI",
    edit: "Aanpassen",
    enableForAllUsers: "Voor alle gebruikers inschakelen",
    id: "ID",
    name: "Naam",
    title: "Titel",
    // Titles
    admins: "Beheerders",
    announcements: "Meldingen",
    createAdmin: "Beheerder toevoegen",
    createReseller: "Reseller aanmaken",
    deleteAdmin: "Beheerder verwijderen",
    duplicateSensor: "Bestaande sensor",
    createAnnouncement: "Melding aanmaken",
    deleteReseller: "Reseller verwijderen",
    editAnnouncement: "Melding aanpassen",
    editFeatureFlag: "Feature aanpassen",
    overwrite: "Overschrijven",
    renameReseller: "Reseller hernoemen",
    resellers: "Resellers",
    sendEmail: "E-mail verzenden",
    sensors: "Sensoren",
    weeklyReport: "Wekelijks rapport",
    featureFlags: "Features",
    createFeatureFlag: "Feature aanmaken",
    deleteReadings: "Metingen verwijderen",
    // Statuses
    deletingAdmin: "Beheerder aan het verwijderen",
    emailSent: "E-mail verzonden",
    renamingReseller: "Reseller aan het hernoemen",
    roleGranted: "Rol toegewezen",
    sendingEmail: "E-mail aan het verzenden",
    // Errors
    invalidToken: "Deze link is ongeldig.",
    sensorAlreadyExists: "De sensor met de EUI {0} bestaat al.",
    tokenAlreadyUsed: "Deze link is al gebruikt.",
    notAnAdmin: "U moet een beheerder zijn om deze pagina te betreden.",
    /**
     * Reseller panel
     */
    confirmCustomerDeletion:
      "Weet u zeker dat u de klant {0} wilt verwijderen?",
    confirmEmployeeDeletion:
      "Weet u zeker dat u de medewerker {0} wilt verwijderen?",
    // Labels
    expiryDate: "Vervaldatum",
    licenseCount: "Aantal licenties",
    nextExpiryDate: "Eerstvolgende vervaldatum",
    // Titles
    add: "Toevoegen",
    addLicenses: "Licenties toevoegen",
    createCustomer: "Klant aanmaken",
    createEmployee: "Medewerker aanmaken",
    customers: "Klanten",
    deleteCustomer: "Klant verwijderen",
    deleteEmployee: "Medewerker verwijderen",
    employees: "Medewerkers",
    licenses: "Licenties",
    renameCustomer: "Klant hernoemen",
    // Statuses
    deletingCustomer: "Klant aan het verwijderen",
    deletingEmployee: "Medewerker aan het verwijderen",
    renamingCustomer: "Klant aan het hernoemen",
    // Errors
    nonExistentCustomer: "Deze klant bestaat niet",
    notAReseller: "U moet een reseller zijn om deze pagina te betreden.",
    /**
     * Token processors
     */
    userWithEmail: 'de gebruiker "{0}"',
    currentUser: "de huidige gebruiker",
    adminTokenExplaination:
      'Door op "{buttonLabel}" te klikken, wordt {user} een beheerder.',
    becomeAnAdmin: "Beheerder worden",
    resellerTokenExplaination:
      'Door op "{buttonLabel}" te klikken, wordt {user} een medewerker van een reseller.',
    becomeAnEmployee: "Medewerker worden",
    customerTokenExplaination:
      'Door op "{buttonLabel}" te klikken, krijgt {user} toegang tot de Albird-temperatuurmonitor.',
    becomeACustomer: "Dit account gebruiken",
    useDifferentAccount: "Ander account gebruiken",
    /**
     * Lacking licenses dialog
     */
    deactivateSensors: "Sensoren deactiveren",
    sensorNoun: "sensor",
    sensorsNoun: "sensoren",
    lackingLicensesExplaination: `Een deel van uw licenties zijn verlopen, 
    kies alstublieft de sensoren die u behouden wilt. U kunt {count} {sensorNoun} behouden.`,
    selectionCountDisplay:
      "{selectedSensorCount} / {licensePackageCount} sensoren geselecteerd.",
    licensesExpired: "Licenties verlopen",
    noLicensesExplaination:
      "Uw licenties zijn verlopen. Neem contact met ons op om nieuwe licenties aan te schaffen.",
    /**
     * Settings
     */
    activatePushNotifications: "Activeer pushberichten op dit apparaat",
    updatePhoneNumber: "Telefoonnummer bijwerken",
    weeklyEmail: "Wekelijkse e-mail",
    receiveWeeklyEmail: "Wekelijks een e-mail met een HACCP-lijst ontvangen",
  },
});
