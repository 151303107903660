import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import LoadingIndicator from "../../common/LoadingIndicator";
import strings from "../../common/strings";
import AddButton from "../AddButton";
import useTableStyles from "../../common/useTableStyles";
import Admin from "./Admin";
import CreateDialog from "./dialogs/CreateDialog";
import useAdmins from "./useAdmins";

export default function Admins() {
  const [admins, loading, error] = useAdmins();
  const classes = useTableStyles();

  return (
    <Grid item xs={12} xl={6}>
      <TableContainer component={Paper}>
        <Toolbar className={classes.tableToolbar}>
          <Typography variant="h6">{strings.admins}</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{strings.email}</TableCell>
              <TableCell align="right">{strings.status}</TableCell>
              {/* Reducing the cell's width to 1 ensures that
              the column will never be wider than it needs to be */}
              <TableCell align="center" width={1}>
                <AddButton
                  renderDialog={(open, setOpen) => (
                    <CreateDialog {...{ open, setOpen }} />
                  )}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <LoadingIndicator />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {strings.genericError}
                </TableCell>
              </TableRow>
            ) : (
              admins?.map((admin) => <Admin key={admin.id} admin={admin} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
