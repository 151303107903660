import { PropsWithChildren, useMemo, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useCollectionData } from "react-firebase-hooks/firestore";
import AnnouncementTarget from "../common/AnnouncementTarget";
import useIsAdmin from "../common/useIsAdmin";
import useIsCustomer from "../common/useIsCustomer";
import useIsReseller from "../common/useIsReseller";
import { auth, firestore } from "../firebaseApp";
import Announcement from "./Announcement";

function useUserAnnouncementCategories() {
  const [isAdmin, loadingIsAdmin, adminError] = useIsAdmin();
  const [isReseller, loadingIsReseller, resellerError] = useIsReseller();
  const [isCustomer, loadingIsCustomer, customerError] = useIsCustomer();

  const resultRef = useRef<readonly AnnouncementTarget[]>([]);

  if (
    (isAdmin && !resultRef.current.includes("admin")) ||
    (isReseller && !resultRef.current.includes("reseller")) ||
    (isCustomer && !resultRef.current.includes("customer"))
  ) {
    const newResult: AnnouncementTarget[] = [];

    if (isAdmin) newResult.push("admin");

    if (isReseller) newResult.push("reseller");

    if (isCustomer) newResult.push("customer");

    resultRef.current = newResult;
  }

  return [
    resultRef.current,
    loadingIsAdmin || loadingIsReseller || loadingIsCustomer,
    adminError ?? resellerError ?? customerError,
  ] as const;
}

export default function AnnouncementWrapper({
  children,
}: PropsWithChildren<{}>) {
  const [announcementCategories] = useUserAnnouncementCategories();

  const [announcements] = useCollectionData<{
    title: string;
    content: string;
    id: string;
  }>(
    announcementCategories.length > 0
      ? firestore
          .collection("announcements")
          .where("targets", "array-contains-any", announcementCategories)
      : undefined,
    {
      idField: "id",
    }
  );

  const [authState, loadingAuthState, authError] = useAuthState(auth);
  const [reads, loadingReads, errorLoadingReads] = useCollectionData<{
    announcementId: string;
  }>(
    firestore
      .collection("users")
      .doc(authState?.uid)
      .collection("announcementReads")
  );

  const readsReady =
    loadingReads || loadingAuthState || authError || errorLoadingReads;

  const unreadAnnouncement = useMemo(() => {
    if (readsReady) return undefined;

    const readAnnouncementIds = reads?.map((read) => read.announcementId);

    return announcements?.find(
      (announcement) => !readAnnouncementIds?.includes(announcement.id)
    );
  }, [readsReady, reads, announcements]);

  return (
    <>
      {unreadAnnouncement && <Announcement announcement={unreadAnnouncement} />}
      {children}
    </>
  );
}
