import { Button, TextField } from "@material-ui/core";
import { useState } from "react";
import { useCreateUserWithEmailAndPassword } from "react-firebase-hooks/auth";
import strings from "../common/strings";
import useFormUtils from "../common/useFormUtils";
import { auth } from "../firebaseApp";
import errorMessages from "./errorMessages";

export default function SignUpForm({
  email,
  goBack,
}: {
  email: string;
  goBack(): void;
}) {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const passwordsMatch = password === repeatPassword;

  const [signUp, , signingUp, error] = useCreateUserWithEmailAndPassword(auth);

  const [errorTarget, errorMessage] =
    error != null
      ? errorMessages.get(error.code) ?? [null, null]
      : [null, null];

  const [
    classes,
    standardFieldProps,
    standardButtonProps,
    standardMinorButtonProps,
  ] = useFormUtils();

  return (
    <form
      className={classes.form}
      onSubmit={(e) => {
        e.preventDefault();
        signUp(email, password);
      }}
    >
      <input hidden id="email" value={email} />
      <TextField
        id="password"
        label={strings.password}
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        error={errorTarget === "password"}
        helperText={errorTarget === "password" ? errorMessage : null}
        {...standardFieldProps}
      />
      <TextField
        id="repeat-password"
        label={strings.repeatPassword}
        type="password"
        value={repeatPassword}
        onChange={(e) => setRepeatPassword(e.target.value)}
        {...standardFieldProps}
        error={!passwordsMatch}
        helperText={passwordsMatch ? null : strings.passwordsDoNotMatch}
      />
      <Button
        type="submit"
        disabled={signingUp || !passwordsMatch}
        className={classes.button}
        {...standardButtonProps}
      >
        {strings.signUp}
      </Button>
      <Button
        type="button"
        className={classes.button}
        onClick={goBack}
        {...standardMinorButtonProps}
      >
        {strings.alreadyHaveAnAccount}
      </Button>
    </form>
  );
}
