import firebase from "firebase/app";
import { useIdTokenResult } from "./useIdTokenResult";

const EMPTY_ARRAY = [] as const;

export function useUserResellerIds(): [
  readonly string[] | undefined,
  boolean,
  firebase.auth.Error | undefined
] {
  const [idTokenResult, loading, error] = useIdTokenResult();

  return [idTokenResult?.claims.resellerIds ?? EMPTY_ARRAY, loading, error];
}
