import { Button, Grid, LinearProgress } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ErrorDisplay from "../common/ErrorDisplay";
import strings from "../common/strings";
import useUid from "../common/useUid";
import BackButton from "../DeviceRegistration/BackButton";
import Scanner from "../DeviceRegistration/Scanner";
import { firestore } from "../firebaseApp";

export default function FindByQrCode() {
  const [uid, loadingUid, errorLoadingUid] = useUid(false);

  const history = useHistory();

  const [sensorExistsPromise, setSensorExistsPromise] =
    useState<Promise<[string, string]>>();
  const [sensorExistsError, setSensorExistsError] = useState<
    "notFound" | "error"
  >();
  const resetSensorExistsError = useCallback(
    () => setSensorExistsError(undefined),
    []
  );

  useEffect(() => {
    if (sensorExistsPromise !== undefined && uid !== undefined) {
      let cancelled = false;

      sensorExistsPromise
        .then(([id, ownerId]) => {
          if (!cancelled)
            if (ownerId === uid) history.push(`/sensors/sensor/${id}`);
            else setSensorExistsError("notFound");
        })
        .catch(() => setSensorExistsError("error"))
        .finally(() => {
          if (!cancelled) setSensorExistsPromise(undefined);
        });

      return () => {
        cancelled = true;
      };
    }
  }, [
    sensorExistsPromise,
    uid,
    history,
    setSensorExistsError,
    setSensorExistsPromise,
  ]);

  const handleScan = useCallback(
    (id) => {
      setSensorExistsPromise(
        firestore
          .collection("sensors")
          .doc(id)
          .get()
          .then((result) => [id, result.get("ownerId")])
      );
    },
    [setSensorExistsPromise]
  );

  const goBack = useCallback(() => history.push("/sensors"), [history]);

  if (loadingUid || sensorExistsPromise !== undefined)
    return <LinearProgress />;
  else if (errorLoadingUid !== undefined)
    return (
      <Grid container direction="column" alignItems="center" spacing={1}>
        <ErrorDisplay>{strings.genericError}</ErrorDisplay>
        <Grid item>
          <Button onClick={resetSensorExistsError} variant="contained">
            {strings.retry}
          </Button>
        </Grid>
        <Grid item>
          <BackButton onClick={goBack} />
        </Grid>
      </Grid>
    );
  else if (sensorExistsError !== undefined)
    return (
      <Grid container direction="column" alignItems="center" spacing={1}>
        <ErrorDisplay>
          {sensorExistsError === "error"
            ? strings.genericError
            : strings.sensorNotFound}
        </ErrorDisplay>
        <Grid item>
          <Button onClick={resetSensorExistsError} variant="contained">
            {strings.retry}
          </Button>
        </Grid>
        <Grid item>
          <BackButton onClick={goBack} />
        </Grid>
      </Grid>
    );
  else return <Scanner onScan={handleScan} />;
}
