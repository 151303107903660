import { IconButton, Menu } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
} from "react";

export default function ContextMenuButton({
  disabled,
  open,
  setOpen,
  children,
}: PropsWithChildren<{
  disabled?: boolean;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}>) {
  const buttonRef: MutableRefObject<HTMLElement | undefined> = useRef();

  useEffect(() => {
    if (!open) buttonRef.current?.blur();
  }, [open, buttonRef]);

  return (
    <>
      <IconButton
        innerRef={buttonRef}
        onClick={() => setOpen(true)}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        keepMounted
        open={open}
        anchorEl={buttonRef.current}
        onClose={() => setOpen(false)}
      >
        {children}
      </Menu>
    </>
  );
}
