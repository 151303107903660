import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import strings from "../../common/strings";
import useAdminUidOverride from "../../common/useAdminUidOverride";
import Scanner from "../../DeviceRegistration/Scanner";
import { functions } from "../../firebaseApp";
import { ISensor } from "../common";

export default function SensorReplacementDialog({
  sensor,
  isOpen,
  handleClose,
}: {
  sensor: ISensor;
  isOpen: boolean;
  handleClose(): void;
}) {
  const [replaceSensorPromise, setReplaceSensorPromise] =
    useState<Promise<unknown>>();

  useEffect(() => {
    if (replaceSensorPromise) {
      let cancelled = false;

      replaceSensorPromise
        .then(() => {
          if (!cancelled) handleClose();
        })
        .finally(() => {
          if (!cancelled) setReplaceSensorPromise(undefined);
        });

      return () => {
        cancelled = true;
      };
    }
  }, [replaceSensorPromise, handleClose, setReplaceSensorPromise]);

  const [uidOverride] = useAdminUidOverride();

  const replaceSensor = useCallback(
    (newEui: string) => {
      setReplaceSensorPromise(
        functions.httpsCallable("replaceSensor")({
          oldEui: sensor.id,
          newEui,
          ...(uidOverride !== undefined ? { uid: uidOverride } : {}),
        })
      );
    },
    [sensor.id, uidOverride]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby={`replace-sensor-${sensor.id}-dialog-title`}
    >
      <DialogTitle id={`replace-sensor-${sensor.id}-dialog-title`}>
        {strings.replaceSensor}
      </DialogTitle>
      <DialogContent>
        <Scanner onScan={replaceSensor} onCancel={handleClose} />
      </DialogContent>
    </Dialog>
  );
}
