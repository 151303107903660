import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import strings from "../../common/strings";
import useFormField from "../../common/useFormField";
import useFormUtils from "../../common/useFormUtils";
import { firestore } from "../../firebaseApp";
import { ISensor } from "../common";
import { useCallback, useEffect, useState } from "react";

async function renameSensor(sensorId: string, newName: string) {
  await firestore.collection("sensors").doc(sensorId).update({ name: newName });
}

function useRenameSensor(onSuccess?: () => void) {
  const [promise, setPromise] = useState<Promise<void>>();

  useEffect(() => {
    if (promise !== undefined) {
      let cancelled = false;

      promise
        .then(() => {
          if (!cancelled) onSuccess?.();
        })
        .finally(() => {
          if (!cancelled) setPromise(undefined);
        });

      return () => {
        cancelled = true;
      };
    }
  }, [promise, onSuccess]);

  return [
    useCallback(
      (sensorId: string, newName: string) =>
        setPromise(renameSensor(sensorId, newName)),
      [setPromise]
    ),
    promise !== undefined,
  ] as const;
}

export default function SensorRenamingDialog({
  sensor,
  isOpen,
  handleClose,
}: {
  sensor: ISensor;
  isOpen: boolean;
  handleClose(): void;
}) {
  const [, commonFieldProps] = useFormUtils();

  const [newName, isNewNameValid, newNameFieldProps, resetNewName] =
    useFormField(
      sensor.name,
      (rawName) => rawName,
      (newName) =>
        newName != null && newName.length > 0 && newName.match(/^\s*$/) === null
          ? null
          : strings.emptyFormField
    );

  const cancel = useCallback(() => {
    resetNewName();
    handleClose();
  }, [resetNewName, handleClose]);

  const [renameSensor, renamingSensor] = useRenameSensor(handleClose);

  const submit = useCallback(() => {
    if (isNewNameValid) renameSensor(sensor.id, newName);
  }, [isNewNameValid, renameSensor, sensor.id, newName]);

  return (
    <Dialog
      open={isOpen}
      onClose={cancel}
      aria-labelledby={`rename-sensor-${sensor.id}-dialog-title`}
    >
      <DialogTitle id={`rename-sensor-${sensor.id}-dialog-title`}>
        {strings.renameSensor}
      </DialogTitle>
      <DialogContent>
        <TextField
          {...commonFieldProps}
          autoFocus
          margin="dense"
          id={`name-${sensor.id}`}
          label={strings.sensorName}
          onKeyDown={async ({ key }) => {
            if (key === "Enter") {
              await submit();
            }
          }}
          fullWidth
          value={newName}
          {...newNameFieldProps}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel} disabled={renamingSensor}>
          {strings.cancel}
        </Button>
        <Button
          onClick={submit}
          color="primary"
          disabled={!isNewNameValid || renamingSensor}
        >
          {strings.save}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
