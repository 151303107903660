import { Button, TextField } from "@material-ui/core";
import { useEmailField } from "../AdminPanel/fields";
import strings from "../common/strings";
import useFormUtils from "../common/useFormUtils";

export default function EmailForm({
  onSubmit,
}: {
  onSubmit?(email: string): void;
}) {
  const [email, emailValid, emailFieldProps] = useEmailField();

  const [
    formClasses,
    standardFieldProps,
    standardButtonProps,
  ] = useFormUtils();
  return (
    <form
      className={formClasses.form}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit?.(email);
      }}
    >
      <TextField
        id="email"
        label={strings.email}
        type="email"
        value={email}
        {...standardFieldProps}
        {...emailFieldProps}
      />
      <Button
        type="submit"
        disabled={!emailValid || onSubmit === undefined}
        className={formClasses.button}
        {...standardButtonProps}
      >
        {strings.next}
      </Button>
    </form>
  );
}
