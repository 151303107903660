import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from "@material-ui/core";
import strings from "../../../common/strings";
import useFormUtils from "../../../common/useFormUtils";
import { useEmailField } from "../../fields";
import { IResellerDialogProps } from "./common";

export default function SendEmailDialog({
  reseller,
  open,
  setOpen,
}: IResellerDialogProps) {
  const [, commonFieldProps] = useFormUtils();
  const [email, emailValid, emailFieldProps, resetEmail] = useEmailField();

  const handleClose = () => {
    setOpen(false);
    resetEmail();
  };

  const submit = () => {
    if (emailValid) handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`send-email-${reseller.id}-dialog-title`}
    >
      <DialogTitle id={`send-email-${reseller.id}-dialog-title`}>
        {strings.sendEmail}
      </DialogTitle>
      <DialogContent>
        <TextField
          {...commonFieldProps}
          autoFocus
          margin="dense"
          id={`email-${reseller.id}`}
          label={strings.email}
          onKeyDown={({ key }) => {
            if (key === "Enter") {
              submit();
            }
          }}
          fullWidth
          value={email}
          {...emailFieldProps}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{strings.cancel}</Button>
        <Button onClick={submit} color="primary" disabled={!emailValid}>
          {strings.sendEmail}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
