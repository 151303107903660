import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Dispatch } from "react";
import { useParams } from "react-router-dom";
import { useEmailField } from "../../../../AdminPanel/fields";
import strings from "../../../../common/strings";
import useCloudFunction from "../../../../common/useCloudFunction";
import useFormUtils from "../../../../common/useFormUtils";

export default function CreateDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<boolean>;
}) {
  const [, commonFieldProps] = useFormUtils();
  const { resellerId } = useParams<{ resellerId: string }>();

  const [email, emailValid, emailFieldProps, resetEmail] = useEmailField();
  const [
    sendResellerToken,
    sendingResellerToken,
    sendResellerTokenError,
    resetSendResellerTokenError,
  ] = useCloudFunction("sendResellerToken");

  const handleClose = () => {
    setOpen(false);
    resetEmail();
  };

  const submit = () => {
    if (emailValid) {
      sendResellerToken({ email, resellerId });
      handleClose();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={`create-employee-dialog-title`}
      >
        <DialogTitle id={`create-employee-dialog-title`}>
          {strings.createEmployee}
        </DialogTitle>
        <DialogContent>
          <TextField
            {...commonFieldProps}
            autoFocus
            margin="dense"
            label={strings.email}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                submit();
                e.preventDefault();
              }
            }}
            fullWidth
            value={email}
            {...emailFieldProps}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.cancel}</Button>
          <Button onClick={submit} color="primary" disabled={!emailValid}>
            {strings.createEmployee}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar message={strings.sendingEmail} open={sendingResellerToken} />
      <Snackbar
        message={strings.genericError}
        open={!!sendResellerTokenError}
        onClose={resetSendResellerTokenError}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetSendResellerTokenError}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}
