import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { useEffect, useState } from "react";
import strings from "../../common/strings";
import useFormField, {
  useMaximumTemperatureValidator,
  numberFieldParser,
} from "../../common/useFormField";
import useFormUtils from "../../common/useFormUtils";
import { firestore } from "../../firebaseApp";
import { ISensor } from "../common";

async function updateTemperatureRange(
  sensorId: string,
  minimumTemperature: number,
  maximumTemperature: number
) {
  await firestore
    .collection("sensors")
    .doc(sensorId)
    .update({ minimumTemperature, maximumTemperature });
}

function useUpdateTemperatureRange(
  onSuccess?: () => void,
  onError?: () => void
): [
  boolean,
  (
    sensorId: string,
    minimumTemperature: number,
    maximumTemperature: number
  ) => void
] {
  const [updateTemperatureRangePromise, setUpdateTemperatureRangePromise] =
    useState<Promise<any> | null>(null);

  useEffect(() => {
    if (updateTemperatureRangePromise != null) {
      let cancelled = false;

      updateTemperatureRangePromise
        .then(() => {
          if (!cancelled) {
            setUpdateTemperatureRangePromise(null);
            onSuccess?.();
          }
        })
        .catch(() => onError?.());

      return () => {
        cancelled = true;
      };
    }
  }, [
    updateTemperatureRangePromise,
    setUpdateTemperatureRangePromise,
    onSuccess,
    onError,
  ]);

  return [
    updateTemperatureRangePromise != null,
    (
      sensorId: string,
      minimumTemperature: number,
      maximumTemperature: number
    ) =>
      setUpdateTemperatureRangePromise(
        updateTemperatureRange(sensorId, minimumTemperature, maximumTemperature)
      ),
  ];
}

export default function SensorTemperatureRangeAlterationDialog({
  sensor,
  isOpen,
  handleClose,
}: {
  sensor: ISensor;
  isOpen: boolean;
  handleClose(): void;
}) {
  const titleId = `alter-sensor-temperature-range-${sensor.id}-dialog-title`;

  const [updatingTemperatureRange, updateTemperatureRange] =
    useUpdateTemperatureRange(handleClose, () => {});

  const [, commonTextFieldProps] = useFormUtils();

  const textFieldProps: Partial<TextFieldProps> = {
    ...commonTextFieldProps,
    disabled: updatingTemperatureRange,
    onKeyDown({ key }) {
      if (key === "Enter") {
        submit();
      }
    },
  };

  const [
    newMinimumTemperature,
    isNewMinimumTemperatureValid,
    newMinimumTemperatureFieldProps,
    resetNewMinimumTemperature,
  ] = useFormField(
    sensor.minimumTemperature,
    numberFieldParser,
    (newMinimumTemperature) =>
      newMinimumTemperature != null ? null : strings.emptyFormField
  );

  const [
    newMaximumTemperature,
    isNewMaximumTemperatureValid,
    newMaximumTemperatureFieldProps,
    resetNewMaximumTemperature,
  ] = useFormField(
    sensor.maximumTemperature,
    numberFieldParser,
    useMaximumTemperatureValidator(newMinimumTemperature)
  );

  const valid = isNewMinimumTemperatureValid && isNewMaximumTemperatureValid;

  const reset = () => {
    resetNewMinimumTemperature();
    resetNewMaximumTemperature();
  };

  const cancel = () => {
    handleClose();
    reset();
  };

  const submit = () => {
    if (valid && !updatingTemperatureRange) {
      updateTemperatureRange(
        sensor.id,
        newMinimumTemperature as number,
        newMaximumTemperature as number
      );
    }
  };

  return (
    <Dialog open={isOpen} onClose={cancel} aria-labelledby={titleId}>
      <DialogTitle id={titleId}>{strings.editTemperatureRange}</DialogTitle>
      <DialogContent>
        <TextField
          label={strings.minimumTemperature}
          {...textFieldProps}
          autoFocus
          value={newMinimumTemperature ?? ""}
          type="number"
          {...newMinimumTemperatureFieldProps}
        />
        <TextField
          label={strings.maximumTemperature}
          {...textFieldProps}
          value={newMaximumTemperature ?? ""}
          type="number"
          {...newMaximumTemperatureFieldProps}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={cancel}>{strings.cancel}</Button>
        <Button
          onClick={submit}
          color="primary"
          disabled={!valid || updatingTemperatureRange}
        >
          {strings.save}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
