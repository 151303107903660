import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import strings from "../common/strings";
import usePhoneNumberInput from "../common/usePhoneNumberInput";
import useUpdatePhoneNumber from "../common/useUpdatePhoneNumber";

export default function PhoneNumberDialog({
  open = false,
  allowCancel = false,
  onCancel,
  onComplete,
}: {
  open?: boolean;
  allowCancel?: boolean;
  onCancel?(): void;
  onComplete?(): void;
}) {
  const [updatePhoneNumber, loading] = useUpdatePhoneNumber(onComplete);
  const [phoneNumber, isPhoneNumberValid, resetPhoneNumber, phoneNumberInput] =
    usePhoneNumberInput("", {
      onKeyDown({ key }) {
        if (key === "Enter") {
          submit();
        }
      },
    });

  const cancel = () => {
    resetPhoneNumber();
    onCancel?.();
  };

  const submit = () => {
    if (isPhoneNumberValid && !loading) updatePhoneNumber(phoneNumber);
  };

  return (
    <Dialog open={open} aria-labelledby="phone-number-dialog-title">
      <DialogTitle id="phone-number-dialog-title">
        {strings.setPhoneNumber}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{strings.smsDescription}</DialogContentText>
        {phoneNumberInput}
      </DialogContent>
      <DialogActions>
        {allowCancel && <Button onClick={cancel}>{strings.cancel}</Button>}
        <Button
          color="primary"
          disabled={!isPhoneNumberValid || loading}
          onClick={submit}
        >
          {strings.save}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
