import { useState } from "react";
import { firestore } from "../firebaseApp";
import usePromise from "./usePromise";

export default function useSetNotificationsEnabled(): [
  (sensorId: string, notificationsEnabled: boolean) => void,
  boolean
] {
  const [loading, setLoading] = useState(false);
  const setPromise = usePromise(undefined, undefined, () => {
    setLoading(false);
  });

  return [
    (sensorId: string, notificationsEnabled: boolean) => {
      setLoading(true);
      setPromise(
        firestore
          .collection("sensors")
          .doc(sensorId)
          .update({ notificationsEnabled })
      );
    },
    loading,
  ];
}
