import {
  Dispatch,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { functions } from "../../firebaseApp";

export interface UserPage {
  readonly users: readonly { readonly uid: string; readonly email: string }[];
  readonly pageToken: string | undefined;
}

export interface UsersState {
  readonly pages: readonly UserPage[];
  readonly currentPageIndex: number;
}

export enum UsersActionKind {
  FINISH_LOADING_PAGE = "FINISH_LOADING_PAGE",
  PREVIOUS_PAGE = "PREVIOUS_PAGE",
  NEXT_PAGE = "NEXT_PAGE",
  REFRESH = "REFRESH",
}

export type UsersAction =
  | {
      readonly kind: Exclude<
        UsersActionKind,
        UsersActionKind.FINISH_LOADING_PAGE
      >;
    }
  | {
      readonly kind: UsersActionKind.FINISH_LOADING_PAGE;
      readonly page: UserPage;
      readonly sourceToken: string | undefined;
    };

export default function useUsers(): [
  UserPage["users"] | undefined,
  number,
  Dispatch<Exclude<UsersActionKind, UsersActionKind.FINISH_LOADING_PAGE>>
] {
  const [{ pages, currentPageIndex }, dispatch] = useReducer(
    (prevState: UsersState, action: UsersAction): UsersState => {
      switch (action.kind) {
        case UsersActionKind.FINISH_LOADING_PAGE:
          return {
            ...prevState,
            pages:
              // Are there no pages and is the source token undefined?
              (prevState.pages.length === 0 &&
                action.sourceToken === undefined) ||
              // Does the last page's token match the source token and is the source token not undefined?
              (prevState.pages.length > 0 &&
                prevState.pages[prevState.pages.length - 1].pageToken ===
                  action.sourceToken &&
                action.sourceToken !== undefined)
                ? [...prevState.pages, action.page]
                : prevState.pages,
          };
        case UsersActionKind.PREVIOUS_PAGE:
          return {
            ...prevState,
            currentPageIndex:
              prevState.currentPageIndex > 0
                ? prevState.currentPageIndex - 1
                : prevState.currentPageIndex,
          };
        case UsersActionKind.NEXT_PAGE:
          return {
            ...prevState,
            currentPageIndex:
              prevState.currentPageIndex < prevState.pages.length &&
              prevState.pages[prevState.currentPageIndex].users.length > 0
                ? prevState.currentPageIndex + 1
                : prevState.currentPageIndex,
          };
        case UsersActionKind.REFRESH:
          return {
            pages: [],
            currentPageIndex: 0,
          };
      }
    },
    { pages: [], currentPageIndex: 0 }
  );

  const [loadOperation, setLoadOperation] =
    useState<[Promise<UserPage>, string | undefined]>();

  useEffect(() => {
    if (currentPageIndex === pages.length) {
      const pageToken =
        pages.length > 0 ? pages[pages.length - 1].pageToken : undefined;

      setLoadOperation([
        functions
          .httpsCallable("listUsers")(pageToken)
          .then((result) => result.data),
        pageToken,
      ]);
    }
  }, [pages, currentPageIndex]);

  useEffect(() => {
    if (loadOperation !== undefined) {
      let cancelled = false;

      const [promise, sourceToken] = loadOperation;

      promise
        .then((page) => {
          if (!cancelled)
            dispatch({
              kind: UsersActionKind.FINISH_LOADING_PAGE,
              page,
              sourceToken,
            });
        })
        .finally(() => {
          if (!cancelled) setLoadOperation(undefined);
        });

      return () => {
        cancelled = true;
      };
    }
  }, [loadOperation]);

  return [
    useMemo(
      () =>
        currentPageIndex <= pages.length - 1
          ? pages[currentPageIndex].users
          : undefined,
      [pages, currentPageIndex]
    ),
    currentPageIndex,
    useRef(
      (kind: Exclude<UsersActionKind, UsersActionKind.FINISH_LOADING_PAGE>) =>
        dispatch({ kind })
    ).current,
  ];
}
