import { Dispatch, useCallback, useEffect, useState } from "react";
import strings from "../../common/strings";
import { firestore } from "../../firebaseApp";
import AnnouncementEditor from "./AnnouncementEditor";
import useTargetState from "./useTargetState";

export default function CreateDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<boolean>;
}) {
  const [targets, toggleTarget] = useTargetState([]);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");

  const close = useCallback(() => {
    setOpen(false);
    setTitle("");
    setContent("");
  }, [setOpen]);

  const [promise, setPromise] = useState<Promise<unknown>>();

  useEffect(() => {
    if (promise) {
      let cancelled = false;

      promise
        .then(() => {
          if (!cancelled) close();
        })
        .finally(() => {
          if (!cancelled) setPromise(undefined);
        });

      return () => {
        cancelled = true;
      };
    }
  }, [promise, close]);

  const createAnnouncement = useCallback(() => {
    setPromise(
      firestore.collection("announcements").add({
        targets,
        title,
        content,
      })
    );
  }, [targets, title, content]);

  return (
    <AnnouncementEditor
      dialogTitle={strings.createAnnouncement}
      open={open}
      targets={targets}
      toggleTarget={toggleTarget}
      title={title}
      setTitle={setTitle}
      content={content}
      setContent={setContent}
      onCancel={promise === undefined ? close : undefined}
      onOk={promise === undefined ? createAnnouncement : undefined}
    />
  );
}
