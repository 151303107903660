import { createStyles, Grid, List, makeStyles } from "@material-ui/core";
import { useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import ErrorDisplay from "../common/ErrorDisplay";
import LoadingIndicator from "../common/LoadingIndicator";
import strings from "../common/strings";
import useUid from "../common/useUid";
import { firestore } from "../firebaseApp";
import { ISensor } from "./common";
import SearchControls from "./SearchControls";
import SensorListItem from "./SensorListItem";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        marginBottom: 48,
      },
    },
  })
);

export default function SensorList() {
  const [searchTerm, setSearchTerm] = useState("");
  const [uid, loadingUid, loadingUidError] = useUid(false);

  const [sensors, loadingSensors, error] = useCollectionData<ISensor>(
    useMemo(
      () =>
        uid !== undefined
          ? firestore.collection("sensors").where("ownerId", "==", uid)
          : null,
      [uid]
    ),
    {
      idField: "id",
    }
  );

  const filteredSensors = useMemo(() => {
    const segments = searchTerm
      .split(" ")
      .filter((segment) => segment.length > 0)
      .map((segment) => segment.toLowerCase());

    if (segments.length <= 0) return sensors ?? [];

    return (
      sensors?.filter((sensor) => {
        const normalizedName = sensor.name.toLowerCase();

        for (let i = 0; i < segments.length; i++)
          if (!normalizedName.includes(segments[i])) return false;

        return true;
      }) ?? []
    );
  }, [searchTerm, sensors]);

  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.root}>
      <SearchControls searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {loadingSensors || loadingUid ? (
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      ) : error !== undefined ||
        loadingUidError !== undefined ||
        uid === undefined ? (
        <ErrorDisplay>{strings.genericError}</ErrorDisplay>
      ) : sensors == null || sensors.length <= 0 ? (
        <ErrorDisplay>{strings.noSensors}</ErrorDisplay>
      ) : (
        <Grid item xs={12}>
          {filteredSensors.length > 0 ? (
            <List>
              {filteredSensors.map((sensor) => (
                <SensorListItem sensor={sensor} key={sensor.id} />
              ))}
            </List>
          ) : (
            <ErrorDisplay>{strings.noSensorsFoundWithQuery}</ErrorDisplay>
          )}
        </Grid>
      )}
    </Grid>
  );
}
