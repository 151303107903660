import {
  Fab,
  FabTypeMap,
  Grow,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { createStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    floatingActionButton: {
      zIndex: 1,
      [theme.breakpoints.up("sm")]: {
        position: "absolute",
        bottom: -24,
        zIndex: 1,
        margin: "0 auto",
      },
      [theme.breakpoints.down("xs")]: {
        position: "fixed",
        bottom: 16,
        right: 16,
      },
    },
  })
);

function useFabSize(): FabTypeMap<{}, "button">["props"]["size"] {
  const theme = useTheme();
  const isXsOrDown = useMediaQuery(theme.breakpoints.down("xs"));

  return isXsOrDown ? undefined : "medium";
}

export default function FloatingActionButton({
  onClick,
}: {
  onClick?(): void;
}) {
  const classes = useStyles();
  const fabSize = useFabSize();

  return (
    <Grow in={!!onClick}>
      <Fab
        onClick={onClick}
        color="secondary"
        aria-label="add"
        className={classes.floatingActionButton}
        size={fabSize}
      >
        <AddIcon />
      </Fab>
    </Grow>
  );
}
