import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AddButton from "../../../AdminPanel/AddButton";
import useTableStyles from "../../../common/useTableStyles";
import LoadingIndicator from "../../../common/LoadingIndicator";
import strings from "../../../common/strings";
import CreateDialog from "./dialogs/CreateDialog";
import Customer from "./Customer";
import useCustomers from "./useCustomers";

export default function Customers() {
  const [customers, loading, error] = useCustomers();
  const classes = useTableStyles();

  return (
    <Grid item xs={12} xl={6}>
      <TableContainer component={Paper}>
        <Toolbar className={classes.tableToolbar}>
          <Typography variant="h6">{strings.customers}</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{strings.name}</TableCell>
              {/* Reducing the cell's width to 1 ensures that
              the column will never be wider than it needs to be */}
              <TableCell align="center" width={1}>
                <AddButton
                  renderDialog={(open, setOpen) => (
                    <CreateDialog {...{ open, setOpen }} />
                  )}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <LoadingIndicator />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  {strings.genericError}
                </TableCell>
              </TableRow>
            ) : (
              customers?.map((customer) => (
                <Customer key={customer.id} customer={customer} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
