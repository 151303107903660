import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import ReactMarkdown from "react-markdown";
import strings from "../common/strings";
import { auth, firestore } from "../firebaseApp";

export default function Announcement({
  announcement: { title, content, id },
}: {
  announcement: { title: string; content: string; id: string };
}) {
  const [markAsReadPromise, setMarkAsReadPromise] =
    useState<Promise<unknown>>();

  useEffect(() => {
    if (markAsReadPromise !== undefined) {
      let cancelled = false;

      markAsReadPromise.finally(() => {
        if (!cancelled) setMarkAsReadPromise(undefined);
      });

      return () => {
        cancelled = true;
      };
    }
  }, [markAsReadPromise]);

  const [authState] = useAuthState(auth);
  const markAsRead = useCallback(() => {
    if (authState?.uid !== undefined)
      setMarkAsReadPromise(
        firestore
          .collection("users")
          .doc(authState.uid)
          .collection("announcementReads")
          .add({
            announcementId: id,
          })
      );
  }, [id, authState?.uid]);

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <ReactMarkdown>{content}</ReactMarkdown>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={markAsReadPromise !== undefined} onClick={markAsRead}>
          {strings.okay}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
