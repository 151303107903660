import firebase from "firebase/app";
import isArray from "lodash/isArray";
import { DispatchWithoutAction, useEffect, useReducer, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Route, Switch } from "react-router";
import { auth, firestore } from "../firebaseApp";
import FindByQrCode from "./FindByQrCode";
import LackingLicensesDialog from "./LackingLicensesDialog";
import PhoneNumberDialog from "./PhoneNumberDialog";
import Sensor from "./Sensor";
import SensorList from "./SensorList";

function useHasPhoneNumber() {
  const [user, loadingAuthState] = useAuthState(auth);
  const [{ phoneNumber = null } = {}, loadingPhoneNumber] = useDocumentData(
    firestore.collection("users").doc(user?.uid)
  );

  return [
    phoneNumber != null &&
      (typeof phoneNumber === "string" ||
        (isArray(phoneNumber) &&
          phoneNumber.every((e) => typeof e === "string"))),
    loadingAuthState || loadingPhoneNumber,
  ];
}

async function getLacksLicenses(user: firebase.User): Promise<boolean> {
  await user.getIdToken(true);
  const result = await user.getIdTokenResult(true);

  return result.claims.lacksLicenses ?? false;
}

function useLacksLicenses(): [boolean, boolean, DispatchWithoutAction] {
  const [user, loadingAuthState] = useAuthState(auth);
  const [refreshToken, refresh] = useReducer(() => Symbol(), Symbol());
  const [lacksLicenses, setLacksLicenses] = useState(false);

  useEffect(() => {
    if (user != null) {
      let cancelled = false;

      getLacksLicenses(user).then((result) => {
        if (!cancelled) setLacksLicenses(result);
      });

      return () => {
        cancelled = true;
      };
    }
  }, [user, refreshToken]);

  return [lacksLicenses, loadingAuthState, refresh];
}

export default function Dashboard() {
  const [hasPhoneNumber, loadingHasPhoneNumber] = useHasPhoneNumber();
  const [lacksLicenses, , refreshLacksLicenses] = useLacksLicenses();

  return (
    <>
      <PhoneNumberDialog open={!hasPhoneNumber && !loadingHasPhoneNumber} />
      <LackingLicensesDialog
        open={lacksLicenses}
        onComplete={refreshLacksLicenses}
      />
      <Switch>
        <Route path="/sensors/sensor/:id" component={Sensor} />
        <Route path="/sensors/find-by-qr" component={FindByQrCode} />
        <Route component={SensorList} />
      </Switch>
    </>
  );
}
