import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FormEventHandler, useEffect, useRef } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import strings from "../common/strings";
import useFormUtils from "../common/useFormUtils";
import usePhoneNumberInput from "../common/usePhoneNumberInput";
import useUid from "../common/useUid";
import useUpdatePhoneNumber from "../common/useUpdatePhoneNumber";
import { firestore } from "../firebaseApp";

function usePhoneNumber(): [string | undefined, boolean] {
  const [uid, loadingUid] = useUid(false);
  const [{ phoneNumber = null } = {}, loadingPhoneNumber] = useDocumentData<{
    phoneNumber?: string | string[] | null;
  }>(firestore.collection("users").doc(uid));

  return [
    (Array.isArray(phoneNumber) ? phoneNumber[0] : phoneNumber) ?? undefined,
    loadingUid || loadingPhoneNumber,
  ];
}

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  })
);

export default function PhoneNumberSection() {
  const previousPhoneNumberRef = useRef<string | undefined>();
  const [phoneNumber, loadingPhoneNumber] = usePhoneNumber();
  const [updatePhoneNumber, updatingPhoneNumber] = useUpdatePhoneNumber();

  const [
    newPhoneNumber,
    isNewPhoneNumberValid,
    resetPhoneNumber,
    phoneNumberInput,
  ] = usePhoneNumberInput(phoneNumber ?? "", {
    disabled: loadingPhoneNumber || updatingPhoneNumber,
  });

  useEffect(() => {
    if (previousPhoneNumberRef.current !== phoneNumber) {
      resetPhoneNumber();

      previousPhoneNumberRef.current = phoneNumber;
    }
  }, [phoneNumber, resetPhoneNumber]);

  const classes = useStyles();
  const [, , commonButtonProps] = useFormUtils();

  const submit: FormEventHandler = (e) => {
    e.preventDefault();

    if (isNewPhoneNumberValid && !loadingPhoneNumber && !updatingPhoneNumber)
      updatePhoneNumber(newPhoneNumber);
  };

  return (
    <Grid item container xs={12} component="form" spacing={1} onSubmit={submit}>
      <Grid item xs={12}>
        <Typography variant="h5" component="h2">
          {strings.updatePhoneNumber}
        </Typography>
      </Grid>
      <Grid item xs={12} md={9}>
        {phoneNumberInput}
      </Grid>
      <Grid item xs={12} md={3} className={classes.buttonContainer}>
        <Button
          {...commonButtonProps}
          type="submit"
          disabled={
            loadingPhoneNumber || updatingPhoneNumber || !isNewPhoneNumberValid
          }
        >
          {strings.save}
        </Button>
      </Grid>
    </Grid>
  );
}
