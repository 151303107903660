import {
  CircularProgress,
  createStyles,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import NotificationsOffIcon from "@material-ui/icons/NotificationsOff";
import firebase from "firebase/app";
import moment from "moment";
import "moment/locale/nl";
import { useState } from "react";
import { Link } from "react-router-dom";
import formatTemperature from "../common/formatTemperature";
import strings from "../common/strings";
import useSetNotificationsEnabled from "../common/useSetNotificationsEnabled";
import { IReading, ISensor } from "./common";
import SensorContextMenuButton from "./SensorContextMenuButton";
import StatusIcon from "./StatusIcon";
import { useCurrentReading } from "./useCurrentReading";

const useStyles = makeStyles((theme) =>
  createStyles({
    withDoubleSecondaryAction: {
      paddingRight: 104,
    },
    okayIcon: {
      color: green[500],
    },
  })
);

function renderTime(time: firebase.firestore.Timestamp) {
  const readingTime = moment(time.toDate());
  const currentTime = moment();

  return Math.abs(readingTime.diff(currentTime, "h")) >= 24
    ? readingTime.format("L")
    : readingTime.format("LT");
}

function readingIsRecent(reading: IReading) {
  const twoHoursAgo = moment().subtract(2, "hours");
  const readingTime = moment(reading.time.toDate());

  return readingTime.isAfter(twoHoursAgo);
}

export default function SensorListItem({ sensor }: { sensor: ISensor }) {
  const [reading, loading, error] = useCurrentReading(
    sensor.id,
    sensor.datasetRef
  );
  const [setNotificationsEnabled, updatingNotificationsEnabled] =
    useSetNotificationsEnabled();
  const [disconnecting, setDisconnecting] = useState(false);
  const classes = useStyles();

  const toggleNotifications = () => {
    if (!disconnecting || updatingNotificationsEnabled)
      setNotificationsEnabled(sensor.id, !sensor.notificationsEnabled);
  };

  return (
    <ListItem
      to={`/sensors/sensor/${sensor.id}`}
      component={Link}
      button
      disabled={disconnecting}
      className={classes.withDoubleSecondaryAction}
    >
      <ListItemIcon>
        <StatusIcon
          status={
            loading
              ? "loading"
              : error
              ? "connectivityError"
              : reading === null || !readingIsRecent(reading)
              ? "noRecentData"
              : (sensor.minimumTemperature == null ||
                  reading.temperature > sensor.minimumTemperature) &&
                (sensor.maximumTemperature == null ||
                  reading.temperature < sensor.maximumTemperature)
              ? "okay"
              : "error"
          }
        />
      </ListItemIcon>
      <ListItemText
        primary={sensor.name}
        secondary={
          <>
            {loading ? (
              <CircularProgress size={24} />
            ) : error != null ? (
              <ErrorIcon />
            ) : reading != null ? (
              <>
                <Typography>
                  {formatTemperature(reading.temperature)}&deg;
                </Typography>
                <Typography variant="body2">
                  {renderTime(reading.time)}
                </Typography>
              </>
            ) : (
              <Typography>&#8709;</Typography>
            )}
          </>
        }
      />
      <ListItemSecondaryAction>
        <Tooltip
          title={
            sensor.notificationsEnabled
              ? strings.disableNotifications
              : strings.enableNotifications
          }
        >
          <IconButton
            about="Test"
            onClick={toggleNotifications}
            disabled={updatingNotificationsEnabled || disconnecting}
          >
            {sensor.notificationsEnabled ? (
              <NotificationsActiveIcon />
            ) : (
              <NotificationsOffIcon />
            )}
          </IconButton>
        </Tooltip>
        <SensorContextMenuButton
          sensor={sensor}
          setDisconnecting={setDisconnecting}
          disabled={disconnecting}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}
