import strings from "../../common/strings";
import FormCard from "../FormCard";
import EntryForm from "./EntryForm";

export default function Sensors() {
  return (
    <FormCard title={strings.sensors}>
      <EntryForm />
    </FormCard>
  );
}
