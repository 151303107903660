import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import { PropsWithChildren } from "react";

const useStyles = makeStyles(() =>
  createStyles({
    error: {
      textAlign: "center",
    },
  })
);

export default function ErrorDisplay({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.error}>
      <Typography variant="h4" component="strong">
        {children}
      </Typography>
    </Grid>
  );
}
