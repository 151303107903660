import strings from "../common/strings";
import useFormField, { emailAdressValidator } from "../common/useFormField";

const noopParser = (rawValue: string) => rawValue;

export const useEmailField = (initialValue: string = "") =>
  useFormField(initialValue, noopParser, emailAdressValidator);

const nameValidator = (value: string) =>
  value.length > 0 ? null : strings.emptyFormField;

export const useNameField = (initialValue: string = "") =>
  useFormField(initialValue, noopParser, nameValidator);
