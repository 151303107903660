import {
  IconButton,
  MenuItem,
  Snackbar,
  TableCell,
  TableRow,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Dispatch, useCallback, useEffect, useState } from "react";
import ContextMenuButton from "../../common/ContextMenuButton";
import strings from "../../common/strings";
import { firestore } from "../../firebaseApp";
import { IResellerDialogProps } from "./dialogs/common";
import DeleteDialog from "./dialogs/DeleteDialog";
import RenameDialog from "./dialogs/RenameDialog";
import SendEmailDialog from "./dialogs/SendEmailDialog";
import { IReseller } from "./useResellers";

type DialogType = "sendEmail" | "rename" | "delete";

function useOpenedDialog(): [
  DialogType | undefined,
  Dispatch<DialogType>,
  () => void
] {
  const [openedDialog, setOpenedDialog] = useState<DialogType>();

  return [
    openedDialog,
    (dialog) => setOpenedDialog(dialog),
    () => setOpenedDialog(undefined),
  ];
}

export default function Reseller({ reseller }: { reseller: IReseller }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openedDialog, openDialog, closeDialog] = useOpenedDialog();

  const useDialogProps = (
    dialog: DialogType,
    openDialogInternal: typeof openDialog = openDialog,
    closeDialogInternal: typeof closeDialog = closeDialog
  ): IResellerDialogProps => ({
    open: openedDialog === dialog,
    setOpen: useCallback(
      (open: boolean) =>
        open ? openDialogInternal(dialog) : closeDialogInternal(),
      [openDialogInternal, dialog, closeDialogInternal]
    ),
    reseller,
  });

  const sendEmailDialogProps = useDialogProps("sendEmail");
  const renameDialogProps = useDialogProps("rename");
  const deleteDialogProps = useDialogProps("delete");

  const [renamePromise, setRenamePromise] = useState<Promise<unknown>>();
  const [renameResellerError, setRenameError] = useState<any>();
  const resetRenameResellerError = useCallback(
    () => setRenameError(undefined),
    [setRenameError]
  );

  useEffect(() => {
    if (renamePromise !== undefined) {
      let cancelled = false;

      renamePromise.catch(setRenameError).finally(() => {
        if (!cancelled) setRenamePromise(undefined);
      });

      return () => {
        cancelled = true;
      };
    }
  }, [renamePromise, setRenamePromise]);

  const rename = useCallback(
    (name: string) => {
      setRenamePromise(
        firestore.collection("resellers").doc(reseller.id).update({
          name,
        })
      );
    },
    [setRenamePromise, reseller.id]
  );

  const renamingReseller = renamePromise !== undefined;

  const getMenuItemProps = (dialog: DialogType) => ({
    onClick: () => {
      openDialog(dialog);
      setMenuOpen(false);
    },
  });

  return (
    <TableRow>
      <TableCell>{reseller.name}</TableCell>
      <TableCell align="center">
        <ContextMenuButton open={menuOpen} setOpen={setMenuOpen}>
          <MenuItem {...getMenuItemProps("sendEmail")}>
            {strings.sendEmail}
          </MenuItem>
          <MenuItem {...getMenuItemProps("rename")}>{strings.rename}</MenuItem>
          <MenuItem {...getMenuItemProps("delete")}>{strings.delete}</MenuItem>
        </ContextMenuButton>
      </TableCell>
      <SendEmailDialog {...sendEmailDialogProps} />
      <RenameDialog {...renameDialogProps} onOk={rename} />
      <DeleteDialog {...deleteDialogProps} />
      {/* Reseller renaming status */}
      <Snackbar message={strings.renamingReseller} open={renamingReseller} />
      <Snackbar
        message={strings.genericError}
        open={!!renameResellerError}
        onClose={resetRenameResellerError}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetRenameResellerError}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </TableRow>
  );
}
