import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Dispatch, useCallback, useReducer } from "react";
import strings from "../../common/strings";
import useCloudFunction from "../../common/useCloudFunction";
import useFormUtils from "../../common/useFormUtils";

function useDeviceProperty(length: number) {
  const [value, setValue] = useReducer(
    (currentValue: string, newValue: string) =>
      newValue.length <= length && /^[a-f0-9]*$/gi.test(newValue)
        ? newValue.toUpperCase()
        : currentValue,
    ""
  );

  return [value, setValue, value.length === length] as const;
}

function useCreateSensor(callback?: () => void) {
  const [invoke, running, error, resetError] = useCloudFunction(
    "createSensor",
    callback
  );

  return [
    useCallback(
      (devEui: string, appEui: string, appKey: string, overwrite?: boolean) =>
        invoke({
          devEui,
          appEui,
          appKey,
          ...(overwrite !== undefined ? { overwrite } : {}),
        }),
      [invoke]
    ),
    running,
    error,
    resetError,
  ] as const;
}

function HexadecimalInput({
  label,
  value,
  onChange,
  disabled,
}: {
  label: string;
  value: string;
  onChange?: Dispatch<string>;
  disabled?: boolean;
}) {
  const [, standardFieldProps] = useFormUtils();

  return (
    <TextField
      label={label}
      {...standardFieldProps}
      value={value.replace(/\W/g, "").replace(/(.{4}(?!$))/g, "$1 ")}
      onChange={(e) => onChange?.(e.target.value.replace(/\W/g, ""))}
      disabled={disabled}
    />
  );
}

export default function EntryForm() {
  const [deviceEui, setDeviceEui, deviceEuiValid] = useDeviceProperty(16);
  const [appEui, setAppEui, appEuiValid] = useDeviceProperty(16);
  const [appKey, setAppKey, appKeyValid] = useDeviceProperty(32);

  const reset = useCallback(() => {
    setDeviceEui("");
    setAppEui("");
    setAppKey("");
  }, [setDeviceEui, setAppEui, setAppKey]);

  const [createSensor, creatingSensor, errorCreatingSensor, resetError] =
    useCreateSensor(reset);

  const [formClasses, , standardButtonProps] = useFormUtils();

  return (
    <form
      className={formClasses.form}
      style={{ minWidth: "unset" }}
      onSubmit={(e) => {
        e.preventDefault();

        createSensor(deviceEui, appEui, appKey);
      }}
    >
      <Dialog open={errorCreatingSensor?.code === "already-exists"}>
        <DialogTitle>{strings.duplicateSensor}</DialogTitle>
        <DialogContent>
          {strings.formatString(strings.sensorAlreadyExists, deviceEui)}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              resetError();
            }}
            disabled={creatingSensor}
          >
            {strings.cancel}
          </Button>
          <Button
            onClick={() => {
              createSensor(deviceEui, appEui, appKey, true);
            }}
            disabled={creatingSensor}
          >
            {strings.overwrite}
          </Button>
        </DialogActions>
      </Dialog>
      <HexadecimalInput
        label={strings.deviceEui}
        value={deviceEui}
        onChange={setDeviceEui}
        disabled={creatingSensor}
      />
      <HexadecimalInput
        label={strings.appEui}
        value={appEui}
        onChange={setAppEui}
        disabled={creatingSensor}
      />
      <HexadecimalInput
        label={strings.appKey}
        value={appKey}
        onChange={setAppKey}
        disabled={creatingSensor}
      />
      <Button
        type="submit"
        className={formClasses.button}
        disabled={
          !deviceEuiValid || !appEuiValid || !appKeyValid || creatingSensor
        }
        {...standardButtonProps}
      >
        {strings.add}
      </Button>
    </form>
  );
}
