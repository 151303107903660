import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import {
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import strings from "../common/strings";
import { standardFieldProps } from "../common/useFormUtils";

export type ExportPreset =
  | {
      kind: "xlsx";
      startTime: Date;
      endTime: Date;
    }
  | {
      kind: "haccp";
      date: Date;
    };

interface SetUpdate<K extends string, V> {
  kind: `set${Capitalize<K>}`;
  value: SetStateAction<V>;
}

type ExportPresetUpdate =
  | { kind: "reset" }
  | SetUpdate<"kind", ExportPreset["kind"]>
  | SetUpdate<"startTime" | "endTime" | "date", Date>;

function isPresetKind(value: unknown): value is ExportPreset["kind"] {
  return value === "xlsx" || value === "haccp";
}

function assertIsPresetKind(value: unknown): ExportPreset["kind"] {
  if (isPresetKind(value)) return value;
  else throw new TypeError("The provided value isn't a preset kind!");
}

function getDefaultExportPreset(kind: ExportPreset["kind"]): ExportPreset {
  switch (kind) {
    case "xlsx":
      return {
        kind: "xlsx",
        startTime: new Date(),
        endTime: moment().add(1, "month").toDate(),
      };
    case "haccp":
      return {
        kind: "haccp",
        date: new Date(),
      };
  }
}

function evaluateSetStateAction<V>(
  previousValue: V,
  action: SetStateAction<V>
) {
  if (action instanceof Function) return action(previousValue);
  else return action;
}

const DEFAULT_EXPORT_KIND: ExportPreset["kind"] = "xlsx";

function useExportPreset() {
  return useReducer(
    (prevState: ExportPreset, action: ExportPresetUpdate): ExportPreset => {
      switch (action.kind) {
        case "setKind":
          return getDefaultExportPreset(
            evaluateSetStateAction(prevState.kind, action.value)
          );
        case "reset":
          return getDefaultExportPreset(DEFAULT_EXPORT_KIND);
        default:
          switch (prevState.kind) {
            case "xlsx":
              switch (action.kind) {
                case "setStartTime":
                  return {
                    ...prevState,
                    startTime: evaluateSetStateAction(
                      prevState.startTime,
                      action.value
                    ),
                  };
                case "setEndTime":
                  return {
                    ...prevState,
                    endTime: evaluateSetStateAction(
                      prevState.endTime,
                      action.value
                    ),
                  };
                default:
                  return prevState;
              }
            case "haccp":
              switch (action.kind) {
                case "setDate":
                  return {
                    ...prevState,
                    date: evaluateSetStateAction(prevState.date, action.value),
                  };
                default:
                  return prevState;
              }
          }
      }
    },
    getDefaultExportPreset(DEFAULT_EXPORT_KIND)
  );
}

export default function ExportDialog({
  open = false,
  onCancel,
  onComplete,
}: {
  open?: boolean;
  onCancel?(): void;
  onComplete?(exportPreset: ExportPreset): void;
}) {
  const [preset, update] = useExportPreset();

  useEffect(() => {
    if (open) update({ kind: "reset" });
  }, [open, update]);

  const submit = useCallback(() => {
    onComplete?.(preset);
  }, [onComplete, preset]);

  const {
    title,
    content,
    valid,
  }: { title: ReactNode; content: ReactNode; valid: boolean } = (() => {
    switch (preset.kind) {
      case "xlsx": {
        const endTimeValid = moment(preset.endTime).isAfter(preset.startTime);

        return {
          title: strings.exportExcelFile,
          content: (
            <>
              <DateTimePicker
                value={preset.startTime}
                onChange={(newTime) =>
                  update({
                    kind: "setStartTime",
                    value: (currentTime) => newTime?.toDate() ?? currentTime,
                  })
                }
                ampm={false}
                label={strings.startTime}
                margin={standardFieldProps.margin}
                fullWidth
              />
              <DateTimePicker
                value={preset.endTime}
                onChange={(newTime) =>
                  update({
                    kind: "setEndTime",
                    value: (currentTime) => newTime?.toDate() ?? currentTime,
                  })
                }
                ampm={false}
                label={strings.endTime}
                margin={standardFieldProps.margin}
                fullWidth
                error={!endTimeValid}
                helperText={
                  !endTimeValid
                    ? strings.endTimeMustBeAfterStartTime
                    : undefined
                }
              />
            </>
          ),
          valid: endTimeValid,
        };
      }
      case "haccp": {
        const startDate = moment(preset.date).startOf("isoWeek");
        const endDate = moment(preset.date).endOf("isoWeek");
        return {
          title: strings.exportHaccpList,
          content: (
            <>
              <DateTimePicker
                value={preset.date}
                onChange={(newTime) =>
                  update({
                    kind: "setDate",
                    value: (currentTime) => newTime?.toDate() ?? currentTime,
                  })
                }
                ampm={false}
                label={strings.date}
                margin={standardFieldProps.margin}
                fullWidth
              />
              <Typography variant="body2">
                {startDate.format("DD-MM-yyyy")}&nbsp;/&nbsp;
                {endDate.format("DD-MM-yyyy")}
              </Typography>
            </>
          ),
          valid: true,
        };
      }
    }
  })();

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <InputLabel id="export-kind-dropdown-label" shrink>
          {strings.exportKind}
        </InputLabel>
        <Select
          fullWidth
          labelId="export-kind-dropdown-label"
          label={strings.exportKind}
          value={preset.kind}
          onChange={(change) =>
            // TODO: Use an assertion function here
            update({
              kind: "setKind",
              value: assertIsPresetKind(change.target.value),
            })
          }
        >
          <MenuItem value="xlsx">{strings.excel}</MenuItem>
          <MenuItem value="haccp">{strings.haccp}</MenuItem>
        </Select>
        {content}
        {/* <DateTimePicker
          value={startTime}
          onChange={(newTime) =>
            setStartTime((currentTime) => newTime?.toDate() ?? currentTime)
          }
          ampm={false}
          label={strings.startTime}
          margin={standardFieldProps.margin}
          fullWidth
        />
        <DateTimePicker
          value={endTime}
          onChange={(newTime) =>
            setEndTime((currentTime) => newTime?.toDate() ?? currentTime)
          }
          ampm={false}
          label={strings.endTime}
          margin={standardFieldProps.margin}
          fullWidth
          error={!endTimeValid}
          helperText={
            !endTimeValid ? strings.endTimeMustBeAfterStartTime : undefined
          }
        /> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Annuleren</Button>
        <Button color="primary" disabled={!valid} onClick={submit}>
          {strings.export}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
