import {
  ButtonBase,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import SettingsIcon from "@material-ui/icons/Settings";
import StoreIcon from "@material-ui/icons/Store";
import { ElementType, PropsWithChildren } from "react";
import { Link, LinkProps, useHistory } from "react-router-dom";
import ErrorDisplay from "../common/ErrorDisplay";
import LoadingIndicator from "../common/LoadingIndicator";
import LogoWrapper from "../common/LogoWrapper";
import strings from "../common/strings";
import useIsAdmin from "../common/useIsAdmin";
import useIsCustomer from "../common/useIsCustomer";
import useIsReseller from "../common/useIsReseller";

const useStyles = makeStyles((theme) =>
  createStyles({
    linkButton: {
      width: "100%",
    },
    icon: {
      height: 96,
    },
  })
);

function LinkButton({
  to,
  children,
}: PropsWithChildren<Pick<LinkProps, "to">>) {
  const classes = useStyles();
  return (
    <Link to={to} component={ButtonBase} className={classes.linkButton}>
      {children}
    </Link>
  );
}

function LandingPageItem({
  to,
  title,
  icon,
}: {
  to: LinkProps["to"];
  title: string;
  icon: ElementType<any>;
}) {
  const classes = useStyles();
  return (
    <Grid item xs={12} lg={4}>
      <LinkButton to={to}>
        <Grid container>
          <Grid item xs={12} component={icon} className={classes.icon} />
          <Grid item xs={12}>
            <Typography align="center">{title}</Typography>
          </Grid>
        </Grid>
      </LinkButton>
    </Grid>
  );
}

export default function LandingPage() {
  const [isAdmin, loadingIsAdmin, loadingIsAdminError] = useIsAdmin();
  const [isCustomer, loadingIsCustomer, loadingIsCustomerError] =
    useIsCustomer();
  const [isReseller, loadingIsReseller, loadingIsResellerError] =
    useIsReseller();

  const loading = loadingIsAdmin || loadingIsReseller || loadingIsCustomer;
  const error =
    loadingIsAdminError ?? loadingIsResellerError ?? loadingIsCustomerError;

  const history = useHistory();

  if (!loading && !error) {
    const roleCount =
      (isAdmin ? 1 : 0) + (isReseller ? 1 : 0) + (isCustomer ? 1 : 0);

    if (roleCount === 1)
      if (isAdmin) history.replace("/admin");
      else if (isReseller) history.replace("/reseller");
      else history.replace("/sensors");
  }

  return (
    <LogoWrapper>
      <Grid container justify="center">
        {loading ? (
          <Grid item xs={12}>
            <LoadingIndicator />
          </Grid>
        ) : error ? (
          <ErrorDisplay>{strings.genericError}</ErrorDisplay>
        ) : (
          <>
            {isAdmin && (
              <LandingPageItem
                icon={SettingsIcon}
                title="Adminpanel"
                to="/admin"
              />
            )}
            {isReseller && (
              <LandingPageItem
                icon={StoreIcon}
                title="Resellerpanel"
                to="/reseller"
              />
            )}
            {isCustomer && (
              <LandingPageItem
                icon={AcUnitIcon}
                title="Koelingen"
                to="/sensors"
              />
            )}
          </>
        )}
      </Grid>
    </LogoWrapper>
  );
}
