import { saveAs } from "file-saver";
import { useCallback, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseApp";
import useAdminUidOverride from "./useAdminUidOverride";

function getHeaders(idToken: string, uidOverride?: string) {
  const headers = new Headers();

  headers.set("Authorization", "Bearer " + idToken);
  if (uidOverride !== undefined) headers.set("X-Uid-Override", uidOverride);

  return headers;
}

export default function useDownloadExcel(): [
  (start: Date, end: Date) => void,
  boolean
] {
  const [authState] = useAuthState(auth);
  const [uidOverride] = useAdminUidOverride();
  const [downloadPromise, setDownloadPromise] = useState<Promise<Blob>>();

  const downloadExcel = useCallback(
    (start: Date, end: Date) =>
      setDownloadPromise(
        authState?.getIdToken().then((idToken) =>
          fetch(
            "https://europe-west1-duursma-temperature-monitor.cloudfunctions.net/exportExcel?" +
              new URLSearchParams({
                start: start.toISOString(),
                end: end.toISOString(),
              }),
            {
              headers: getHeaders(idToken, uidOverride),
            }
          ).then((response) => {
            if (response.ok) return response.blob();
            throw new Error("Received status " + response.status);
          })
        )
      ),
    [authState, uidOverride]
  );

  useEffect(() => {
    let cancelled = false;

    if (downloadPromise !== undefined) {
      downloadPromise
        .then((blob) => !cancelled && saveAs(blob))
        .finally(() => !cancelled && setDownloadPromise(undefined));
    }

    return () => {
      cancelled = true;
    };
  }, [downloadPromise]);

  return [downloadExcel, downloadPromise !== undefined];
}
