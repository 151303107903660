import { PropsWithChildren } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import LoadingIndicator from "../common/LoadingIndicator";
import strings from "../common/strings";
import { auth } from "../firebaseApp";
import SignInPortal from "./SignInPortal";

export default function AuthWrapper({ children }: PropsWithChildren<{}>) {
  const [user, loading, error] = useAuthState(auth);

  if (error) return <h1>{strings.genericError}</h1>;

  if (user != null) return <>{children}</>;

  if (loading) return <LoadingIndicator />;

  return <SignInPortal />;
}
