import {
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/nl";
import { SetStateAction, useState } from "react";
import strings from "../common/strings";

export default function TimeRangeControls({
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  errorMessage,
}: {
  startTime: number;
  setStartTime: React.Dispatch<SetStateAction<number>>;
  endTime: number | null;
  setEndTime: React.Dispatch<SetStateAction<number | null>>;
  errorMessage?: string | undefined;
}) {
  return (
    <Grid item container spacing={1}>
      {errorMessage !== undefined && (
        <Grid item xs={12}>
          <Typography color="error">{errorMessage}</Typography>
        </Grid>
      )}
      <DateTimeInput
        time={startTime}
        defaultTimeFunc={() => moment().subtract(1, "day").valueOf()}
        setTime={setStartTime}
        dateTimeLabel={strings.startTime}
        checkboxLabel={strings.fromStart}
      />
      <NullableDateTimeInput
        time={endTime}
        defaultTimeFunc={() => moment().valueOf()}
        setTime={setEndTime}
        dateTimeLabel={strings.endTime}
        checkboxLabel={strings.untilNow}
      />
    </Grid>
  );
}

function NullableDateTimeInput({
  time,
  defaultTimeFunc,
  setTime,
  dateTimeLabel,
  checkboxLabel,
}: {
  time: number | null;
  defaultTimeFunc(): number;
  setTime: React.Dispatch<SetStateAction<number | null>>;
  dateTimeLabel: string;
  checkboxLabel: string;
}) {
  const [hiddenValue, setHiddenValue] = useState(() => time);

  return (
    <Grid item xs={12} sm={6} md="auto">
      <FormGroup>
        {
          <Collapse in={time != null}>
            <DateTimePicker
              value={moment(time)}
              disabled={time == null}
              onChange={(e) => setTime(e?.valueOf() ?? null)}
              ampm={false}
              label={dateTimeLabel}
              fullWidth
              {...(time == null ? { error: false, helperText: null } : {})}
            />
          </Collapse>
        }
        <FormControlLabel
          control={
            <Checkbox
              checked={time == null}
              onChange={(e) => {
                if (e.target.checked) {
                  setHiddenValue(time);
                  setTime(null);
                } else {
                  setTime(hiddenValue || defaultTimeFunc());
                }
              }}
              color="primary"
            />
          }
          label={checkboxLabel}
        />
      </FormGroup>
    </Grid>
  );
}

function DateTimeInput({
  time,
  setTime,
  dateTimeLabel,
}: {
  time: number;
  defaultTimeFunc(): number;
  setTime: React.Dispatch<SetStateAction<number>>;
  dateTimeLabel: string;
  checkboxLabel: string;
}) {
  return (
    <Grid item xs={12} sm={6} md="auto">
      <FormGroup>
        <DateTimePicker
          value={moment(time)}
          onChange={(e) => {
            const newValue = e?.valueOf();

            if (newValue != null) setTime(newValue);
          }}
          ampm={false}
          label={dateTimeLabel}
          fullWidth
        />
      </FormGroup>
    </Grid>
  );
}
