import { useTheme } from "@material-ui/core";
import moment from "moment";
import { useMemo } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import formatTemperature from "../common/formatTemperature";
import strings from "../common/strings";
import useGraphStyles from "./useGraphStyles";

export interface ICondensedDataPoint {
  date: Date;
  average: number;
  minimum: number;
  maximum: number;
}

export class CondensedChartDataPoint {
  readonly date: number;
  readonly average: number;
  readonly minimum: number;
  readonly maximum: number;
  readonly range: [number, number];

  constructor({ date, average, minimum, maximum }: ICondensedDataPoint) {
    this.date = date.getTime();
    this.average = average;
    this.minimum = minimum;
    this.maximum = maximum;
    this.range = [minimum, maximum];
  }
}

export default function CondensedTemperatureGraph({
  chartData,
}: {
  chartData: CondensedChartDataPoint[];
}) {
  const classes = useGraphStyles();
  const theme = useTheme();

  const domain: [number, number] = useMemo(() => {
    if (chartData.length <= 0) return [0, 0];

    let minimum = chartData[0].minimum,
      maximum = chartData[0].maximum;

    for (let i = 1; i < chartData.length; i++)
      if (chartData[i].minimum < minimum) minimum = chartData[i].minimum;
      else if (chartData[i].maximum > maximum) maximum = chartData[i].maximum;

    return [Math.floor(minimum - 1), Math.ceil(maximum + 1)];
  }, [chartData]);

  return (
    <ResponsiveContainer width="95%" height={500}>
      <ComposedChart data={chartData}>
        <XAxis
          dataKey="date"
          domain={
            chartData.length > 0
              ? [chartData[0].date, chartData[chartData.length - 1].date]
              : ["auto", "auto"]
          }
          name={strings.time}
          tickFormatter={(unixTime: number) =>
            moment(unixTime).format("DD-MM-yyyy")
          }
          type="number"
        />
        <YAxis yAxisId={"test"} name={strings.temperature} domain={domain} />
        <CartesianGrid />
        <Area yAxisId="test" dataKey="range" stroke="#8884d8" fill="#8884d8" name={strings.range} />
        <Line
          yAxisId="test"
          type="monotone"
          dataKey="average"
          name={strings.average}
          scale="auto"
          dot={false}
        />
        <Tooltip
          labelFormatter={(unixTime: number) =>
            moment(unixTime).format("DD-MM-yyyy")
          }
          formatter={(value: number | [number, number]) =>
            typeof value === "number" ? (
              <span>{formatTemperature(value)}&deg;</span>
            ) : (
              <span>
                {formatTemperature(value[0])}&deg;&nbsp;:&nbsp;
                {formatTemperature(value[1])}&deg;
              </span>
            )
          }
          labelClassName={classes.label}
          itemStyle={theme.typography.body2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
