import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { Dispatch, ReactNode, SetStateAction, useState } from "react";
import { useRef } from "react";

export interface ICreateDialogProps {
  open: boolean;
  setOpen: Dispatch<boolean>;
}

export default function AddButton({
  renderDialog,
}: {
  renderDialog: (
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
  ) => ReactNode;
}) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const buttonRef = useRef<HTMLElement>();

  return (
    <>
      <IconButton
        innerRef={buttonRef}
        size="small"
        onClick={() => {
          setDialogOpen(true);
          buttonRef.current?.blur();
        }}
      >
        <AddIcon />
      </IconButton>
      {renderDialog(dialogOpen, setDialogOpen)}
    </>
  );
}
