import { Button } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { functions } from "../firebaseApp";
import FormCard from "./FormCard";

export default function BulkSensors() {
  const [value, setValue] = useState("");
  const [state, setState] = useState<undefined | Promise<unknown> | boolean>();

  useEffect(() => {
    if (state !== undefined && typeof state !== "boolean") {
      let cancelled = false;

      state
        .then(() => {
          if (!cancelled) setState(true);
        })
        .catch(() => {
          if (!cancelled) setState(false);
        })
        .finally(() => {
          if (!cancelled)
            setState((currentState) =>
              typeof currentState === "boolean" ? currentState : undefined
            );
          setValue("");
        });

      return () => {
        cancelled = true;
      };
    }
  }, [state]);

  const submit = useCallback(
    (data: {
      ownerId: string;
      sensors: {
        id: string;
        name: string;
        minimumTemperature?: number;
        maximumTemperature?: number;
        notificationsEnabled?: boolean;
      }[];
    }) => {
      setState(functions.httpsCallable("addSensorsInBulk")(data));
    },
    []
  );

  return (
    <FormCard title="Sensoren toevoegen">
      {typeof state !== "boolean" ? (
        <>
          <input
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            disabled={state !== undefined}
            onClick={() => submit(JSON.parse(value))}
          >
            Verzenden
          </Button>
        </>
      ) : state ? (
        "Success"
      ) : (
        "Failed"
      )}
    </FormCard>
  );
}
