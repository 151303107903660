import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useCallback } from "react";
import strings from "../../../common/strings";
import useCloudFunction from "../../../common/useCloudFunction";

export default function DeleteDialog({
  open,
  setOpen,
  id,
  name,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  id: string;
  name: string;
}) {
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [deleteFeatureFlag] = useCloudFunction<typeof id>("deleteFeatureFlag");
  const confirm = useCallback(() => {
    close();
    deleteFeatureFlag(id);
  }, [close, deleteFeatureFlag, id]);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{strings.deleteAdmin}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {strings.formatString(
            strings.confirmFeatureFlagDeletion,
            <strong>{name}</strong>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} autoFocus>
          {strings.cancel}
        </Button>
        <Button onClick={confirm} color="primary">
          {strings.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
