import { useBehaviorSubjectValue } from "haakje";
import { BehaviorSubject } from "rxjs";

const $switchUserDialogOpen = new BehaviorSubject(false);

function setSwitchUserDialogOpen(switchUserDialogOpen: boolean) {
  $switchUserDialogOpen.next(switchUserDialogOpen);
}

export default function useSwitchUserDialogOpen() {
  return [
    useBehaviorSubjectValue($switchUserDialogOpen),
    setSwitchUserDialogOpen,
  ] as const;
}
