import { useCallback, useEffect, useReducer, useState } from "react";
import LoadingIndicator from "../common/LoadingIndicator";
import { auth } from "../firebaseApp";
import EmailForm from "./EmailForm";
import PasswordResetView from "./PasswordResetView";
import SignInForm from "./SignInForm";
import SignUpForm from "./SignUpForm";

function useSignInMethods(email: string | undefined) {
  const [signInMethods, setSignInMethods] =
    useState<{ email: string; signInMethods: readonly string[] }>();

  useEffect(() => {
    if (email !== undefined) {
      let cancelled = false;

      auth.fetchSignInMethodsForEmail(email).then((signInMethods) => {
        if (!cancelled) setSignInMethods({ email, signInMethods });
      });

      return () => {
        cancelled = true;
      };
    }
  }, [email]);

  return signInMethods !== undefined && signInMethods?.email === email
    ? signInMethods.signInMethods
    : undefined;
}

export default function EmailPasswordSection() {
  const [{ email, passwordResetShown }, dispatch] = useReducer(
    (
      prevState:
        | { email: string; passwordResetShown: boolean }
        | { email: undefined; passwordResetShown: false },
      action:
        | {
            kind: "setEmail";
            email: string;
          }
        | {
            kind: "showPasswordReset";
          }
        | {
            kind: "goBack";
          }
    ):
      | { email: string; passwordResetShown: boolean }
      | { email: undefined; passwordResetShown: false } => {
      switch (action.kind) {
        case "setEmail":
          return { ...prevState, email: action.email };
        case "showPasswordReset":
          if (prevState.email !== undefined)
            return {
              email: prevState.email,
              passwordResetShown: true,
            };
          else return prevState;
        case "goBack":
          return prevState.passwordResetShown
            ? {
                ...prevState,
                passwordResetShown: false,
              }
            : {
                email: undefined,
                passwordResetShown: false,
              };
      }
    },
    { email: undefined, passwordResetShown: false }
  );
  const signInMethods = useSignInMethods(email);

  const setEmail = useCallback(
    (email: string) =>
      dispatch({
        kind: "setEmail",
        email,
      }),
    []
  );
  const showPasswordReset = useCallback(
    () => dispatch({ kind: "showPasswordReset" }),
    []
  );
  const goBack = useCallback(() => dispatch({ kind: "goBack" }), []);

  if (
    email === undefined ||
    (signInMethods !== undefined &&
      signInMethods.length !== 0 &&
      !signInMethods.includes("password"))
  )
    return <EmailForm onSubmit={setEmail} />;
  else if (signInMethods === undefined) return <LoadingIndicator />;
  else if (signInMethods.length === 0) return <SignUpForm email={email} goBack={goBack} />;
  else if (passwordResetShown)
    return <PasswordResetView showSignInForm={goBack} />;
  else
    return (
      <SignInForm
        email={email}
        showPasswordResetForm={showPasswordReset}
        goBack={goBack}
      />
    );
}
