import {
  IconButton,
  Link as MuiLink,
  MenuItem,
  Snackbar,
  TableCell,
  TableRow,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ContextMenuButton from "../../../common/ContextMenuButton";
import strings from "../../../common/strings";
import useCloudFunction from "../../../common/useCloudFunction";
import { firestore } from "../../../firebaseApp";
import DeleteDialog from "./dialogs/DeleteDialog";
import RenameDialog from "./dialogs/RenameDialog";
import { ICustomer } from "./useCustomers";

export default function Customer({ customer }: { customer: ICustomer }) {
  const { resellerId } = useParams<{ resellerId: string }>();

  const [menuOpen, setMenuOpen] = useState(false);
  const [_sendEmail, sendingEmail, sendEmailError, resetSendEmailError] =
    useCloudFunction("resendResellerToken");

  const sendEmail = () => {
    setMenuOpen(false);
    _sendEmail(customer.id);
  };

  const [
    _deleteCustomer,
    deletingCustomer,
    deleteCustomerError,
    resetDeleteCustomerError,
  ] = useCloudFunction("deleteCustomer");

  const deleteCustomer = () => {
    _deleteCustomer({ resellerId, customerId: customer.id });
  };

  const [renameCustomerPromise, setRenameCustomerPromise] =
    useState<Promise<unknown>>();
  const [renameCustomerError, setRenameCustomerError] = useState<any>();
  const resetRenameCustomerError = useCallback(
    () => setRenameCustomerError(undefined),
    [setRenameCustomerError]
  );

  useEffect(() => {
    if (renameCustomerPromise !== undefined) {
      let cancelled = false;

      renameCustomerPromise.catch(setRenameCustomerError).finally(() => {
        if (!cancelled) setRenameCustomerPromise(undefined);
      });

      return () => {
        cancelled = true;
      };
    }
  }, [renameCustomerPromise, setRenameCustomerError, setRenameCustomerPromise]);

  const renameCustomer = useCallback(
    (name: string) => {
      setRenameCustomerPromise(
        firestore
          .collection("resellers")
          .doc(resellerId)
          .collection("customers")
          .doc(customer.id)
          .update({
            name,
          })
      );
    },
    [resellerId, customer.id, setRenameCustomerPromise]
  );

  const renamingCustomer = renameCustomerPromise !== undefined;

  const [openedDialog, setOpenedDialog] = useState<"delete" | "rename">();

  const openDeleteDialog = useCallback(() => {
    setOpenedDialog("delete");
    setMenuOpen(false);
  }, [setOpenedDialog, setMenuOpen]);

  const setDeleteDialogOpen = useCallback(
    (open) => {
      if (open) openDeleteDialog();
      else
        setOpenedDialog((currentlyOpenedDialog) =>
          currentlyOpenedDialog === "delete" ? undefined : currentlyOpenedDialog
        );
    },
    [openDeleteDialog, setOpenedDialog]
  );

  const openRenameDialog = useCallback(() => {
    setOpenedDialog("rename");
    setMenuOpen(false);
  }, [setOpenedDialog, setMenuOpen]);

  const setRenameDialogOpen = useCallback(
    (open) => {
      if (open) openRenameDialog();
      else
        setOpenedDialog((currentlyOpenedDialog) =>
          currentlyOpenedDialog === "rename" ? undefined : currentlyOpenedDialog
        );
    },
    [openRenameDialog, setOpenedDialog]
  );

  return (
    <TableRow>
      <TableCell>
        <MuiLink
          component={Link}
          to={`/reseller/${resellerId}/customer/${customer.id}`}
        >
          {customer.name}
        </MuiLink>
      </TableCell>
      <TableCell align="center">
        <ContextMenuButton open={menuOpen} setOpen={setMenuOpen}>
          {customer.uid == null && (
            <MenuItem disabled={sendingEmail} onClick={sendEmail}>
              {strings.sendEmail}
            </MenuItem>
          )}
          <MenuItem
            disabled={deletingCustomer || renamingCustomer}
            onClick={openDeleteDialog}
          >
            {strings.delete}
          </MenuItem>
          <MenuItem
            disabled={deletingCustomer || renamingCustomer}
            onClick={openRenameDialog}
          >
            {strings.rename}
          </MenuItem>
        </ContextMenuButton>
      </TableCell>
      <DeleteDialog
        customer={customer}
        open={openedDialog === "delete"}
        setOpen={setDeleteDialogOpen}
        onOk={deleteCustomer}
      />
      <RenameDialog
        customer={customer}
        open={openedDialog === "rename"}
        setOpen={setRenameDialogOpen}
        onOk={renameCustomer}
      />
      {/* Email sending status */}
      <Snackbar message={strings.sendingEmail} open={sendingEmail} />
      <Snackbar
        message={strings.genericError}
        open={!!sendEmailError}
        onClose={resetSendEmailError}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetSendEmailError}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {/* Customer deletion status */}
      <Snackbar message={strings.deletingCustomer} open={deletingCustomer} />
      <Snackbar
        message={strings.genericError}
        open={!!deleteCustomerError}
        onClose={resetDeleteCustomerError}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetDeleteCustomerError}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {/* Customer renaming status */}
      <Snackbar message={strings.renamingCustomer} open={renamingCustomer} />
      <Snackbar
        message={strings.genericError}
        open={!!renameCustomerError}
        onClose={resetRenameCustomerError}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetRenameCustomerError}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </TableRow>
  );
}
