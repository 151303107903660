import { Collapse, Fade, Grid, Typography } from "@material-ui/core";
import jpeg from "jpeg-js";
import jsQR from "jsqr";
import React, {
  Component,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import Webcam from "react-webcam";
import LoadingIndicator from "../common/LoadingIndicator";
import strings from "../common/strings";
import useSavedCallback from "../common/useSavedCallback";
import BackButton from "./BackButton";

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useSavedCallback(callback);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay, savedCallback]);
}

const BASE64_MARKER = ";base64,";

function extractBase64FromDataURI(dataURI: string) {
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  return dataURI.substring(base64Index);
}

export default function Scanner({
  onScan,
  onCancel,
}: {
  onScan(result: string): void;
  onCancel?(): void;
}) {
  const history = useHistory();

  const webcamRef = useRef<Webcam>(null);

  useInterval(() => {
    const imageURI = webcamRef.current?.getScreenshot();

    if (imageURI != null) {
      const jpegBase64 = extractBase64FromDataURI(imageURI);
      const jpegData = Buffer.from(jpegBase64, "base64");
      const { data, width, height } = jpeg.decode(jpegData);
      const qrCode = jsQR(new Uint8ClampedArray(data), width, height);

      if (qrCode != null) onScan(qrCode.data);
    }
  }, 1000);

  const [loading, setLoading] = useState(true);
  const [loaderVisible, setLoaderVisible] = useState(true);

  return (
    <Grid container direction="column">
      <Grid item style={{ alignSelf: "center" }}>
        <Collapse in={loading} onExited={() => setLoaderVisible(false)}>
          <div>
            <LoadingIndicator />
          </div>
        </Collapse>
        <Fade in={!loading && !loaderVisible}>
          <Grid container alignItems="center" direction="column">
            <Grid item>
              <Typography variant="h5" align="center">
                {strings.scannerInstruction}
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <WebcamWrapper
                  onWillUnmount={() => {
                    const video = webcamRef.current?.video;

                    if (video != null) video.srcObject = null;
                  }}
                >
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width="100%"
                    videoConstraints={{
                      width: 360,
                      height: 360,
                      aspectRatio: 1,
                      facingMode: "environment",
                    }}
                    onUserMedia={() => setLoading(false)}
                    onUserMediaError={console.log}
                  />
                </WebcamWrapper>
              </div>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
      <BackButton onClick={onCancel ?? (() => history.push("/sensors"))} />
    </Grid>
  );
}

class WebcamWrapper extends Component<
  PropsWithChildren<{ onWillUnmount(): void }>
> {
  componentWillUnmount() {
    this.props.onWillUnmount();
  }

  render() {
    return this.props.children;
  }
}
