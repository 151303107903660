import { useRef } from "react";

export default function useReferentiallyStableValue<T, D>(
  factory: () => T,
  dependency: D,
  comparator?: (previousDependency: D, currentDependency: D) => boolean
): T {
  const valueRef = useRef<{ value: T; dependency: D }>();

  if (
    valueRef.current === undefined ||
    !(
      comparator?.(valueRef.current.dependency, dependency) ??
      valueRef.current.dependency === dependency
    )
  )
    return (valueRef.current = { value: factory(), dependency }).value;

  return valueRef.current.value;
}
