import firebase from "firebase/app";
import "moment/locale/nl";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../firebaseApp";
import { IReading } from "./common";

export function useCurrentReading(
  sensorId: string,
  datasetRef?: firebase.firestore.DocumentReference | null | undefined
): [IReading | null, boolean, firebase.FirebaseError | undefined] {
  const [readings, loading, error] = useCollectionData<IReading>(
    (datasetRef ?? firestore.collection("sensors").doc(sensorId))
      .collection("readings")
      .orderBy("time", "desc")
      .limit(1)
  );

  return [readings != null ? readings[0] || null : null, loading, error];
}
