import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import strings from "../../common/strings";
import { ISensor } from "../common";

export default function SensorDisconnectionDialog({
  sensor,
  isOpen,
  handleCancel,
  handleConfirm,
}: {
  sensor?: ISensor | null;
  isOpen: boolean;
  handleCancel(): void;
  handleConfirm(): void;
}) {
  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby={`sensor-${sensor?.id}-disconnection-dialog-title`}
      aria-describedby={`sensor-${sensor?.id}-disconnection-dialog-description`}
    >
      <DialogTitle id={`sensor-${sensor?.id}-disconnection-dialog-title`}>
        {strings.disconnectSensor}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id={`sensor-${sensor?.id}-disconnection-dialog-description`}
        >
          {strings.formatString(
            strings.confirmSensorDisconnection,
            <strong>{sensor?.name}</strong>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} autoFocus>
          {strings.cancel}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {strings.disconnect}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
