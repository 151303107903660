import firebase from "firebase/app";
import { useUserResellerIds } from "./useUserResellerIds";

export default function useIsReseller(): [
  boolean | undefined,
  boolean,
  firebase.auth.Error | undefined
] {
  const [resellerIds, loadingResellerIds, loadingResellerIdsError] =
    useUserResellerIds();

  return [
    (resellerIds?.length ?? 0) > 0,
    loadingResellerIds,
    loadingResellerIdsError,
  ];
}
