import {
  IconButton,
  MenuItem,
  Snackbar,
  TableCell,
  TableRow,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import ContextMenuButton from "../../../common/ContextMenuButton";
import strings from "../../../common/strings";
import useCloudFunction from "../../../common/useCloudFunction";
import { auth } from "../../../firebaseApp";
import DeleteDialog from "./dialogs/DeleteDialog";
import { IEmployee } from "./useEmployees";

export default function Employee({ employee }: { employee: IEmployee }) {
  const [authState, loadingAuthState, loadingAuthStateError] =
    useAuthState(auth);
  const [showLoadingAuthStateError, setShowLoadingAuthStateError] =
    useState(false);

  useEffect(() => {
    setShowLoadingAuthStateError(!!loadingAuthStateError);
  }, [loadingAuthStateError]);

  const [menuOpen, setMenuOpen] = useState(false);
  const [_sendEmail, sendingEmail, sendEmailError, resetSendEmailError] =
    useCloudFunction("resendResellerToken");

  const sendEmail = () => {
    setMenuOpen(false);
    _sendEmail(employee.id);
  };

  const [
    _deleteEmployee,
    deletingEmployee,
    deleteEmployeeError,
    resetDeleteEmployeeError,
  ] = useCloudFunction(
    employee.status === "roleGranted"
      ? "revokeResellerRole"
      : "deleteResellerToken"
  );
  const deleteEmployee = () => {
    _deleteEmployee(employee.id);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
    setMenuOpen(false);
  };

  return (
    <TableRow>
      <TableCell>{employee.email}</TableCell>
      <TableCell align="right">
        {employee.status === "emailSent"
          ? strings.emailSent
          : strings.roleGranted}
      </TableCell>
      <TableCell align="center">
        <ContextMenuButton open={menuOpen} setOpen={setMenuOpen}>
          {employee.status !== "roleGranted" && (
            <MenuItem
              disabled={sendingEmail || loadingAuthState}
              onClick={sendEmail}
            >
              {strings.sendEmail}
            </MenuItem>
          )}
          <MenuItem
            disabled={
              loadingAuthState ||
              !!loadingAuthStateError ||
              (employee.status === "roleGranted" &&
                authState?.uid === employee.id)
            }
            onClick={openDeleteDialog}
          >
            {strings.delete}
          </MenuItem>
        </ContextMenuButton>
      </TableCell>
      <DeleteDialog
        employee={employee}
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        onOk={deleteEmployee}
      />
      {/* Email sending status */}
      <Snackbar message={strings.sendingEmail} open={sendingEmail} />
      <Snackbar
        message={strings.genericError}
        open={!!sendEmailError}
        onClose={resetSendEmailError}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetSendEmailError}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {/* Employee deletion status */}
      <Snackbar message={strings.deletingEmployee} open={deletingEmployee} />
      <Snackbar
        message={strings.genericError}
        open={!!deleteEmployeeError}
        onClose={resetDeleteEmployeeError}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetDeleteEmployeeError}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {/* Auth state errors */}
      <Snackbar
        message={strings.genericError}
        open={showLoadingAuthStateError}
        onClose={() => setShowLoadingAuthStateError(false)}
        autoHideDuration={2000}
      />
    </TableRow>
  );
}
