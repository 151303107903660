import {
  createStyles,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import StoreIcon from "@material-ui/icons/Store";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { Link, useHistory } from "react-router-dom";
import { IReseller } from "../AdminPanel/Resellers/useResellers";
import LoadingIndicator from "../common/LoadingIndicator";
import strings from "../common/strings";
import { useUserResellerIds } from "../common/useUserResellerIds";
import { firestore } from "../firebaseApp";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(),
    },
    errorMessage: {
      textAlign: "center",
    },
  })
);

export default function ResellerPanelLandingPage() {
  const [userResellerIds, loadingUserResellerIds, loadingUserResellerIdsError] =
    useUserResellerIds();

  const classes = useStyles();

  const history = useHistory();
  if (userResellerIds && userResellerIds.length === 1)
    history.replace(`/reseller/${userResellerIds[0]}`);

  return (
    <Grid container spacing={1} className={classes.root}>
      {loadingUserResellerIds ? (
        <Grid item xs={12} component={LoadingIndicator} />
      ) : loadingUserResellerIdsError ? (
        <Grid item xs={12} className={classes.errorMessage}>
          <Typography align="center" variant="h3" component="strong">
            {strings.genericError}
          </Typography>
        </Grid>
      ) : (userResellerIds?.length ?? 0) === 0 ? (
        <Grid item xs={12} className={classes.errorMessage}>
          <Typography align="center" variant="h3" component="strong">
            {strings.notAReseller}
          </Typography>
        </Grid>
      ) : (
        <Grid item xs={12} component={List}>
          {userResellerIds?.map((resellerId) => (
            <ResellerListItem id={resellerId} key={resellerId} />
          ))}
        </Grid>
      )}
    </Grid>
  );
}

function ResellerListItem({ id }: { id: string }) {
  const [reseller, loadingReseller, loadingResellerError] =
    useDocumentDataOnce<IReseller>(firestore.collection("resellers").doc(id));

  // TODO: Display errors
  return reseller && !loadingReseller && !loadingResellerError ? (
    <ListItem component={Link} to={`/reseller/${id}`} button={true}>
      <ListItemIcon>
        <StoreIcon />
      </ListItemIcon>
      <ListItemText primary={reseller.name} />
    </ListItem>
  ) : null;
}
