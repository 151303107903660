import firebase from "firebase/app";
import useCustomerPath from "./useCustomerPath";

export default function useIsCustomer(): [
  boolean | undefined,
  boolean,
  firebase.auth.Error | undefined
] {
  const [customerPath, loading, error] = useCustomerPath();

  return [!!customerPath, loading, error];
}
