import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import strings from "../../../common/strings";
import { IResellerDialogProps } from "./common";

export default function DeleteDialog({
  reseller,
  open,
  setOpen,
}: IResellerDialogProps) {
  const handleClose = () => {
    setOpen(false);
  };

  const confirm = () => {
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`delete-${reseller.id}-dialog-title`}
      aria-describedby={`delete-${reseller.id}-dialog-description`}
    >
      <DialogTitle id={`delete-${reseller.id}-dialog-title`}>
        {strings.deleteReseller}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id={`delete-${reseller.id}-dialog-description`}>
          {strings.formatString(
            strings.confirmResellerDeletion,
            <strong>{reseller?.name}</strong>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          {strings.cancel}
        </Button>
        <Button onClick={confirm} color="primary">
          {strings.delete}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
