import { MenuItem, TableCell, TableRow } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import AnnouncementTarget from "../../common/AnnouncementTarget";
import ContextMenuButton from "../../common/ContextMenuButton";
import strings from "../../common/strings";
import { firestore } from "../../firebaseApp";
import AnnouncementEditor from "./AnnouncementEditor";
import useTargetState from "./useTargetState";

export default function Announcement({
  announcement: { id, targets, title, content },
}: {
  announcement: {
    id: string;
    targets: readonly AnnouncementTarget[];
    title: string;
    content: string;
  };
}) {
  const [newTargets, toggleNewTargets] = useTargetState(targets);
  const [newTitle, setNewTitle] = useState(title);
  const [newContent, setNewContent] = useState(content);

  const [promise, setPromise] = useState<Promise<unknown>>();

  useEffect(() => {
    if (promise !== undefined) {
      let cancelled = false;

      promise.finally(() => {
        if (!cancelled) setPromise(undefined);
      });

      return () => {
        cancelled = true;
      };
    }
  }, [promise]);

  const deleteAnnouncement = useCallback(
    () => setPromise(firestore.collection("announcements").doc(id).delete()),
    [id]
  );

  const [dialogOpen, setDialogOpen] = useState(false);
  const openDialog = useCallback(() => setDialogOpen(true), []);
  const onCancel = useCallback(() => {
    setDialogOpen(false);
    setNewTitle(title);
    setNewContent(content);
  }, [title, content]);

  const updateAnnouncement = useCallback(() => {
    setPromise(
      firestore
        .collection("announcements")
        .doc(id)
        .update({ targets: newTargets, title: newTitle, content: newContent })
    );
    setDialogOpen(false);
  }, [id, newTargets, newTitle, newContent]);

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <TableRow>
      <TableCell>{title}</TableCell>
      <TableCell align="center">
        <ContextMenuButton open={menuOpen} setOpen={setMenuOpen}>
          <MenuItem disabled={promise !== undefined} onClick={openDialog}>
            {strings.editAnnouncement}
          </MenuItem>
          <MenuItem
            disabled={promise !== undefined}
            onClick={deleteAnnouncement}
          >
            {strings.delete}
          </MenuItem>
        </ContextMenuButton>
      </TableCell>
      <AnnouncementEditor
        dialogTitle={strings.editAnnouncement}
        open={dialogOpen}
        targets={newTargets}
        toggleTarget={toggleNewTargets}
        title={newTitle}
        setTitle={setNewTitle}
        content={newContent}
        setContent={setNewContent}
        onCancel={onCancel}
        onOk={updateAnnouncement}
      />
    </TableRow>
  );
}
