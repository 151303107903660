import { createStyles, Grid, makeStyles, Paper } from "@material-ui/core";
import { PropsWithChildren } from "react";
import Logo from "./Logo";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
      },
      marginTop: 4,
    },
    column: {
      padding: 4,
    },
    content: {
      alignSelf: "stretch",
    },
  })
);

export default function LogoWrapper({ children }: PropsWithChildren<{}>) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        alignItems="center"
        component={Paper}
        className={classes.column}
      >
        <Grid item component={Logo} />
        <Grid item className={classes.content}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}
