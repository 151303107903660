import { useCallback, useEffect, useState } from "react";
import useUid from "../common/useUid";
import { registerForNotifications } from "../notifications";
import { Button, Grid } from "@material-ui/core";
import useFormUtils from "../common/useFormUtils";
import strings from "../common/strings";

function useActivatePushNotifications() {
  const [uid, loadingUid] = useUid(false);
  const [promise, setPromise] = useState<Promise<void>>();

  useEffect(() => {
    let cancelled = false;

    if (promise !== undefined)
      promise.finally(() => {
        if (!cancelled) setPromise(undefined);
      });

    return () => {
      cancelled = true;
    };
  });

  const run = useCallback(() => {
    if (uid !== undefined) setPromise(registerForNotifications(uid));
  }, [uid]);

  if (loadingUid || promise !== undefined) return undefined;
  else return run;
}

export default function PushNotificationSection() {
  const activatePushNotifications = useActivatePushNotifications();
  const [, , commonButtonProps] = useFormUtils();

  return (
    <Grid item container xs={12} spacing={1}>
      <Grid item xs={12} md={3}>
        <Button
          {...commonButtonProps}
          type="submit"
          disabled={activatePushNotifications === undefined}
          onClick={activatePushNotifications}
        >
          {strings.activatePushNotifications}
        </Button>
      </Grid>
    </Grid>
  );
}
