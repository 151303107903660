import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseApp";
import useAdminUidOverride from "./useAdminUidOverride";

/**
 * @param [ignoreAdminOverride=true] Whether to always return the logged-in user's UID - true by default
 */
export default function useUid(
  ignoreAdminOverride = true) {
  const [authState, loadingAuthState, authError] = useAuthState(auth);

  const [adminUidOverride] = useAdminUidOverride();

  if (!ignoreAdminOverride && adminUidOverride !== undefined)
    return [adminUidOverride, false, undefined] as const;

  return [authState?.uid, loadingAuthState, authError] as const;
}
