import { Button, Typography } from "@material-ui/core";
import { useLatestEmissionFromObservable } from "haakje";
import { useMemo, useReducer } from "react";
import {
  Observable,
  Subject,
  catchError,
  concatMap,
  from,
  map,
  of,
  startWith,
} from "rxjs";
import LoadingIndicator from "../common/LoadingIndicator";
import UserPicker from "../common/UserPicker";
import strings from "../common/strings";
import { standardButtonProps } from "../common/useFormUtils";
import { functions } from "../firebaseApp";
import FormCard from "./FormCard";

const getDataDump = functions.httpsCallable("getDataDump", {
  timeout: 600000,
});

type MaxLengthArray<
  T,
  n extends number,
  a extends Array<T> = []
> = a["length"] extends n ? a : MaxLengthArray<T, n, [...a, T]> | a;

type Item =
  | {
      loading: true;
      uids: Readonly<MaxLengthArray<string, 10>>;
      data: null;
    }
  | {
      loading: false;
      uids: Readonly<MaxLengthArray<string, 10>>;
      data: any;
    };

const $dataDumpCall = new Subject<Readonly<MaxLengthArray<string, 10>>>();
const $dataDump: Observable<Item> = $dataDumpCall.pipe(
  concatMap((uids) =>
    from(getDataDump(uids)).pipe(
      map(({ data }) => ({ loading: false, uids, data })),
      // TODO: Show the error status to the user
      catchError(() => of({ loading: false, uids, data: null })),
      startWith({ loading: true, uids, data: null })
    )
  )
);

function assertIsUidArray(
  uids: readonly string[]
): Readonly<MaxLengthArray<string, 10>> {
  if (uids.length <= 10) return uids as ReturnType<typeof assertIsUidArray>;

  throw new TypeError("The UID array was too long!");
}

export default function DataDump() {
  const result = useLatestEmissionFromObservable($dataDump, [undefined]);

  const [uidsJson, dataJson] = useMemo(
    (): [string | undefined, string | undefined] =>
      result === undefined
        ? [undefined, undefined]
        : [JSON.stringify(result.uids), JSON.stringify(result.data)],
    [result]
  );

  const [uids, toggleUser] = useReducer(
    (prevState: readonly string[], action: string): readonly string[] =>
      prevState.includes(action)
        ? prevState.filter((e) => e !== action)
        : [...prevState, action],
    []
  );

  return (
    <FormCard title={strings.export}>
      {result ? (
        result.loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Typography variant="h2">UIDs</Typography>
            <code>{uidsJson}</code>
            <Typography variant="h2">Data</Typography>
            <code>{dataJson}</code>
          </>
        )
      ) : (
        <>
          <UserPicker onSelect={toggleUser} selected={uids} />
          <Button
            {...standardButtonProps}
            onClick={() => $dataDumpCall.next(assertIsUidArray(uids))}
            disabled={uids.length < 1}
          >
            {strings.export}
          </Button>
        </>
      )}
    </FormCard>
  );
}
