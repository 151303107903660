import { Observable, Subject } from "rxjs";
import { concatMap } from "rxjs/operators";
import { useObservableValue } from "../common/rxHooks";
import { auth } from "../firebaseApp";

export const useSignOut = () => {
  const signOutClick$ = new Subject<[() => void, (error: any) => void]>();
  const signingOut$ = signOutClick$.pipe(
    concatMap(
      ([resolve, reject]) =>
        new Observable<boolean>((subscriber) => {
          subscriber.next(true);

          auth
            .signOut()
            .then(resolve)
            .catch(reject)
            .finally(() => subscriber.next(false));
        })
    )
  );

  return [
    () =>
      new Promise<void>((resolve, reject) =>
        signOutClick$.next([resolve, reject])
      ),
    useObservableValue(signingOut$, false),
  ] as const;
};
