import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemProps,
  ListItemText,
} from "@material-ui/core";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import MenuItemProps from "./MenuItemProps";

export default function NavigationItem(
  props: MenuItemProps & {
    icon: ReactNode;
    label: string;
    url?: string;
    onClick?(): void;
  }
) {
  const commonProps: Pick<ListItemProps<Link>, "component" | "to"> | {} =
    props.url !== undefined
      ? {
          component: Link,
          to: props.url,
        }
      : {};

  return props.inDrawer ? (
    <ListItem
      button
      {...commonProps}
      onClick={() => {
        props.closeDrawer();
        props.onClick?.();
      }}
    >
      <ListItemIcon>{props.icon}</ListItemIcon>
      <ListItemText>{props.label}</ListItemText>
    </ListItem>
  ) : (
    <Button
      color="inherit"
      startIcon={props.icon}
      {...commonProps}
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  );
}
