import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useCollectionData } from "react-firebase-hooks/firestore";
import AnnouncementTarget from "../../common/AnnouncementTarget";
import LoadingIndicator from "../../common/LoadingIndicator";
import strings from "../../common/strings";
import { firestore } from "../../firebaseApp";
import AddButton from "../AddButton";
import useTableStyles from "../../common/useTableStyles";
import Announcement from "./Announcement";
import CreateDialog from "./CreateDialog";

export default function Announcements() {
  const [announcements, loadingAnnouncements, errorLoadingAnnouncements] =
    useCollectionData<{ id: string; targets: readonly AnnouncementTarget[]; title: string; content: string }>(
      firestore.collection("announcements"),
      {
        idField: "id",
      }
    );
  const classes = useTableStyles();

  return (
    <Grid item xs={12} xl={6}>
      <TableContainer component={Paper}>
        <Toolbar className={classes.tableToolbar}>
          <Typography variant="h6">{strings.announcements}</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{strings.title}</TableCell>
              {/* Reducing the cell's width to 1 ensures that
              the column will never be wider than it needs to be */}
              <TableCell align="center" width={1}>
                <AddButton
                  renderDialog={(open, setOpen) => (
                    <CreateDialog {...{ open, setOpen }} />
                  )}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingAnnouncements ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <LoadingIndicator />
                </TableCell>
              </TableRow>
            ) : errorLoadingAnnouncements ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  {strings.genericError}
                </TableCell>
              </TableRow>
            ) : (
              announcements?.map((announcement) => (
                <Announcement
                  key={announcement.id}
                  announcement={announcement}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
