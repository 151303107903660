import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { useCollectionData } from "react-firebase-hooks/firestore";
import LoadingIndicator from "../../common/LoadingIndicator";
import strings from "../../common/strings";
import useTableStyles from "../../common/useTableStyles";
import { firestore } from "../../firebaseApp";
import AddButton from "../AddButton";
import FeatureFlag from "./FeatureFlag";
import CreateDialog from "./dialogs/CreateDialog";

export default function FeatureFlags() {
  const [featureFlags, loadingFeatureFlags, errorLoadingAnnouncements] =
    useCollectionData<{ name: string; enabled: true | string[] }, "id">(
      firestore.collection("featureFlags"),
      {
        idField: "id",
      }
    );
  const classes = useTableStyles();

  return (
    <Grid item xs={12} xl={6}>
      <TableContainer component={Paper}>
        <Toolbar className={classes.tableToolbar}>
          <Typography variant="h6">{strings.featureFlags}</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{strings.id}</TableCell>
              <TableCell>{strings.name}</TableCell>
              {/* Reducing the cell's width to 1 ensures that
              the column will never be wider than it needs to be */}
              <TableCell align="center" width={1}>
                <AddButton
                  renderDialog={(open, setOpen) => (
                    <CreateDialog {...{ open, setOpen }} />
                  )}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadingFeatureFlags ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <LoadingIndicator />
                </TableCell>
              </TableRow>
            ) : errorLoadingAnnouncements ? (
              <TableRow>
                <TableCell colSpan={2} align="center">
                  {strings.genericError}
                </TableCell>
              </TableRow>
            ) : (
              featureFlags?.map((featureFlag) => (
                <FeatureFlag {...featureFlag} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
