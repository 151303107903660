import { registerForNotifications as _registerForNotifications } from "@albird-software/web-notifications-client";
import { firestore } from "./firebaseApp";
import firebase from "firebase";

export async function registerForNotifications(uid: string) {
  const deviceToken = await _registerForNotifications("service-worker.js");

  await firestore
    .collection("users")
    .doc(uid)
    .update({
      deviceTokens: firebase.firestore.FieldValue.arrayUnion(deviceToken),
    });
}
