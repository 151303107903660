import constate from "constate";
import firebase from "firebase/app";
import { useEffect, useReducer, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebaseApp";

export const [IdTokenResultProvider, useIdTokenResult] = constate(() => {
  const [refreshToken, refresh] = useReducer(() => Symbol(), Symbol());
  const [authState, loadingAuthState, authStateError] = useAuthState(auth);
  const [idTokenResult, setIdTokenResult] =
    useState<firebase.auth.IdTokenResult>();
  const [loadingClaims, setLoadingClaims] = useState<boolean>(true);
  const [claimsError, setClaimsError] = useState<firebase.auth.Error>();

  useEffect(() => {
    if (authState) {
      let cancelled = false;

      setLoadingClaims(true);

      (async () => {
        try {
          const result = await authState.getIdTokenResult(true);

          if (!cancelled) {
            setIdTokenResult(result);
            setClaimsError(undefined);
          }
        } catch (error) {
          if (!cancelled) setClaimsError(error);
        } finally {
          if (!cancelled) setLoadingClaims(false);
        }
      })();

      return () => {
        cancelled = true;
      };
    } else {
      setIdTokenResult(undefined);
      setClaimsError(undefined);
      setLoadingClaims(false);
    }
  }, [authState, refreshToken]);

  return [
    idTokenResult,
    loadingAuthState || loadingClaims,
    authStateError ?? claimsError,
    refresh,
  ] as const;
});
