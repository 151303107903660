import firebase from "firebase/app";
import { useEffect, useState } from "react";
import { functions } from "../firebaseApp";
import useReferentiallyStableValue from "./useReferentiallyStableValue";

export default function useCloudFunction<Data = any>(
  name: string,
  callback?: (response: any) => void
): [
  (data: Data) => void,
  boolean,
  firebase.FirebaseError | undefined,
  () => void
] {
  const [promise, setPromise] =
    useState<Promise<firebase.functions.HttpsCallableResult>>();
  const running = !!promise;
  const [error, setError] = useState<firebase.FirebaseError>();
  const resetError = () => {
    setError(undefined);
  };

  useEffect(() => {
    if (promise) {
      let cancelled = false;

      (async () => {
        try {
          const response = await promise;

          if (!cancelled) {
            setError(undefined);
            callback?.(response.data);
          }
        } catch (error: any) {
          if (!cancelled) setError(error);
        } finally {
          if (!cancelled) setPromise(undefined);
        }
      })();

      return () => {
        cancelled = true;
      };
    }
  }, [promise, callback]);

  return [
    useReferentiallyStableValue(
      () => (data: Data) => {
        setPromise(functions.httpsCallable(name)(data));
      },
      name
    ),
    running,
    error,
    resetError,
  ];
}
