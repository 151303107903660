import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import { useReferentiallyStableMemo } from "haakje";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import UserPicker from "../common/UserPicker";
import strings from "../common/strings";
import { standardButtonProps } from "../common/useFormUtils";
import { functions } from "../firebaseApp";
import FormCard from "./FormCard";

function asseertIsDefined<T>(value: T | undefined): T {
  if (value === undefined)
    throw new TypeError("The given value should never be undefined!");
  else return value;
}

function dateToUnix(date: Date) {
  return Math.floor(date.getTime() / 1000);
}

function deleteReadings(
  uid: string,
  startDate: Date,
  endDate: Date
): Promise<number> {
  return functions
    .httpsCallable("eraseDataForRange")({
      uid,
      range: [dateToUnix(startDate), dateToUnix(endDate)],
    })
    .then((response) => response.data);
}

function useDeleteReadings() {
  const [{ promise, result }, dispatch] = useReducer(
    (
      prevState: Readonly<{
        promise: Promise<number> | undefined;
        result: number | undefined;
      }>,
      action: Promise<number> | number | undefined
    ) => {
      if (action === undefined || typeof action === "number")
        return { promise: undefined, result: action };
      else return { ...prevState, promise: action };
    },
    { promise: undefined, result: undefined }
  );

  useEffect(() => {
    if (promise !== undefined) {
      let cancelled = false;

      promise
        .then((result) => {
          if (!cancelled) dispatch(result);
        })
        .catch(() => {
          if (!cancelled) dispatch(undefined);
        });

      return () => {
        cancelled = true;
      };
    }
  }, [promise]);

  return {
    deleteReadings: useCallback(
      (uid: string, startDate: Date, endDate: Date) =>
        dispatch(deleteReadings(uid, startDate, endDate)),
      []
    ),
    deletingReadings: promise !== undefined,
    deletedReadings: result,
    reset: useCallback(() => dispatch(undefined), []),
  } as const;
}

export default function RemoveErroneuousData() {
  const [uid, setUid] = useState<string>();
  const selectedUids = useReferentiallyStableMemo(
    () => (uid !== undefined ? [uid] : undefined),
    [uid]
  );

  const [startDate, setStartDate] = useState<Date | undefined>(
    () => new Date()
  );
  const [endDate, setEndDate] = useState<Date | undefined>(() => new Date());

  const [confirmed, setConfirmed] = useState(false);

  const valid = useMemo(
    () =>
      confirmed &&
      uid !== undefined &&
      startDate !== undefined &&
      endDate !== undefined &&
      startDate.getTime() < endDate.getTime(),
    [confirmed, uid, startDate, endDate]
  );

  const { deleteReadings, deletingReadings, deletedReadings, reset } =
    useDeleteReadings();

  return (
    <FormCard title={strings.deleteReadings}>
      <Dialog open={deletedReadings !== undefined} onClose={reset}>
        <DialogContent>{deletedReadings} datapunten verwijderd</DialogContent>
        <DialogActions>
          <Button onClick={reset}>{strings.okay}</Button>
        </DialogActions>
      </Dialog>
      <UserPicker selected={selectedUids} onSelect={setUid} />
      <FormGroup>
        <DateTimePicker
          value={startDate}
          onChange={(date) => setStartDate(date?.toDate())}
          label={strings.startTime}
          ampm={false}
        />
      </FormGroup>
      <FormGroup style={{ marginTop: 2 }}>
        <DateTimePicker
          value={endDate}
          onChange={(date) => setEndDate(date?.toDate())}
          label={strings.endTime}
          ampm={false}
        />
      </FormGroup>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              value={confirmed}
              onChange={() => setConfirmed(!confirmed)}
            />
          }
          label={strings.confirmReadingDeletion}
        />
      </FormGroup>
      <Button
        {...standardButtonProps}
        disabled={!valid || deletingReadings}
        onClick={() => {
          if (valid)
            deleteReadings(
              asseertIsDefined(uid),
              asseertIsDefined(startDate),
              asseertIsDefined(endDate)
            );
        }}
      >
        {strings.deleteReadings}
      </Button>
      {uid !== undefined &&
        startDate !== undefined &&
        endDate !== undefined &&
        JSON.stringify({
          uid,
          range: [dateToUnix(startDate), dateToUnix(endDate)],
        })}
    </FormCard>
  );
}
