import { TextFieldProps } from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import strings from "./strings";

export default function useFormField<T>(
  defaultValue: T,
  parser: (rawValue: string) => T,
  validator: (value: T) => string | null
): [T, boolean, Partial<TextFieldProps>, () => void] {
  const [value, setValue] = useState(defaultValue);
  const [touched, setTouched] = useState(false);
  const errorMessage = useMemo(() => validator(value), [validator, value]);

  const errorProps: Partial<TextFieldProps> = useMemo(
    () =>
      errorMessage != null && touched
        ? { error: true, helperText: errorMessage }
        : {},
    [errorMessage, touched]
  );

  return [
    value,
    errorMessage == null,
    {
      ...errorProps,
      onBlur: useCallback(() => setTouched(true), [setTouched]),
      onChange: useCallback(
        (e) => setValue(parser(e.target.value)),
        [setValue, parser]
      ),
    },
    useCallback(() => {
      setValue(defaultValue);
      setTouched(false);
    }, [setValue, defaultValue, setTouched]),
  ];
}

export function numberFieldParser(rawValue: string) {
  const value = parseInt(rawValue);

  if (isNaN(value)) return null;
  else return value;
}

export function useMaximumTemperatureValidator(
  minimumTemperature: number | null | undefined
): (maximumTemperature: number | null | undefined) => string | null {
  return useCallback(
    (maximumTemperature) =>
      minimumTemperature == null
        ? null
        : maximumTemperature == null
        ? strings.emptyFormField
        : maximumTemperature < minimumTemperature
        ? strings.maximumTemperatureLesserThanMinimumTemperature
        : maximumTemperature - minimumTemperature < 3
        ? strings.minimumMaximumTemperatureDifferenceAtLeastThreeDegrees
        : null,
    [minimumTemperature]
  );
}

export function emailAdressValidator(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) ? null : strings.invalidEmail;
}
