import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBY7klrjFJbIwDen69j7INNirR1gdT5cKM",
  authDomain: "duursma-temperature-monitor.firebaseapp.com",
  projectId: "duursma-temperature-monitor",
  storageBucket: "duursma-temperature-monitor.appspot.com",
  messagingSenderId: "326983555958",
  appId: "1:326983555958:web:275166656d0d7021673857",
};

const app = firebase.initializeApp(firebaseConfig);

export default app;

export const auth = firebase.auth(app);
export const firestore = firebase.firestore(app);
export const functions = app.functions("europe-west1");
