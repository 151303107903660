import {
  ButtonProps,
  createStyles,
  makeStyles,
  TextFieldProps,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      [theme.breakpoints.down("md")]: {
        alignSelf: "stretch",
      },
      [theme.breakpoints.up("lg")]: {
        minWidth: 1024,
      },
      display: "flex",
      flexDirection: "column",
    },
    button: {
      marginTop: 2,
      marginBottom: 2,
    },
  })
);

export const standardFieldProps: Pick<
  TextFieldProps,
  "variant" | "fullWidth" | "margin"
> = {
  variant: "filled",
  fullWidth: true,
  margin: "dense",
};

export const standardButtonProps: Pick<
  ButtonProps,
  "variant" | "fullWidth" | "color"
> = {
  variant: "contained",
  fullWidth: true,
  color: "primary",
};

export const standardMinorButtonProps: Pick<
  ButtonProps,
  "variant" | "fullWidth" | "size"
> = {
  variant: "text",
  fullWidth: true,
  size: "small",
};

export default function useFormUtils(): [
  ClassNameMap<"button" | "form">,
  typeof standardFieldProps,
  typeof standardButtonProps,
  typeof standardMinorButtonProps
] {
  return [
    useStyles(),
    standardFieldProps,
    standardButtonProps,
    standardMinorButtonProps,
  ];
}
