import {
  AppBar,
  Container,
  createStyles,
  makeStyles,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { PropsWithChildren } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import strings from "../common/strings";
import useIsAdmin from "../common/useIsAdmin";
import { auth } from "../firebaseApp";
import DrawerButton from "./DrawerButton";
import FloatingActionButton from "./FloatingActionButton";
import MenuItemProps from "./MenuItemProps";
import SensorsButton from "./SensorsButton";
import SettingsButton from "./SettingsButton";
import SignOutButton from "./SignOutButton";
import SwitchUserButton from "./SwitchUserButton";
import SwitchUserDialog from "./SwitchUserButton/SwitchUserDialog";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    title: {
      flexGrow: 1,
    },
    titleLink: {
      textDecoration: "inherit",
      color: "inherit",
    },
  })
);

function useIsSmOrDown(): boolean {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("sm"));
}

export default function Layout({
  children,
  onFloatingActionButtonClick,
}: PropsWithChildren<{ onFloatingActionButtonClick?(): void }>) {
  const classes = useStyles();
  const isSmOrDown = useIsSmOrDown();

  const [user] = useAuthState(auth);
  const [isAdmin] = useIsAdmin();

  const renderMenuItems = (commonMenuItemProps: MenuItemProps = {}) => (
    <>
      {isAdmin && <SwitchUserButton {...commonMenuItemProps} />}
      {user && <SensorsButton {...commonMenuItemProps} />}
      {user && <SettingsButton {...commonMenuItemProps} />}
      {user && <SignOutButton {...commonMenuItemProps} />}
    </>
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          {isSmOrDown && user && <DrawerButton>{renderMenuItems}</DrawerButton>}
          <Typography variant="h6" className={classes.title}>
            <Link to="/" className={classes.titleLink}>
              {strings.refrigerators}
            </Link>
          </Typography>
          {!isSmOrDown && renderMenuItems()}
          <FloatingActionButton onClick={onFloatingActionButtonClick} />
        </Toolbar>
      </AppBar>
      <Container>
        <>{children}</>
      </Container>
      <SwitchUserDialog />
    </>
  );
}
