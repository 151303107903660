import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Dispatch, KeyboardEventHandler, useCallback, useEffect } from "react";
import strings from "../../../common/strings";
import useFormUtils from "../../../common/useFormUtils";
import { useNameField } from "../../fields";
import { IResellerDialogProps } from "./common";

export default function RenameDialog({
  reseller,
  open,
  setOpen,
  onOk,
}: IResellerDialogProps & {
  onOk: Dispatch<string>;
}) {
  const [, commonFieldProps] = useFormUtils();
  const [newName, newNameValid, newNameFieldProps, resetNewName] = useNameField(
    reseller.name
  );

  const handleClose = useCallback(() => setOpen(false), [setOpen]);

  useEffect(() => {
    if (open) resetNewName();
  }, [open, resetNewName]);

  const submit = useCallback(() => {
    if (newNameValid) {
      onOk(newName);
      handleClose();
    }
  }, [newNameValid, onOk, newName, handleClose]);

  const handleKeydown: KeyboardEventHandler = useCallback(
    ({ key }) => {
      if (key === "Enter") {
        submit();
      }
    },
    [submit]
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby={`rename-${reseller.id}-dialog-title`}
    >
      <DialogTitle id={`rename-${reseller.id}-dialog-title`}>
        {strings.renameReseller}
      </DialogTitle>
      <DialogContent>
        <TextField
          {...commonFieldProps}
          autoFocus
          margin="dense"
          id={`name-${reseller.id}`}
          label={strings.name}
          onKeyDown={handleKeydown}
          fullWidth
          value={newName}
          {...newNameFieldProps}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{strings.cancel}</Button>
        <Button onClick={submit} color="primary" disabled={!newNameValid}>
          {strings.save}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
