import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import ErrorDisplay from "./common/ErrorDisplay";
import LogoWrapper from "./common/LogoWrapper";
import strings from "./common/strings";

export default function PageNotFound() {
  return (
    <LogoWrapper>
      <Grid container direction="column" alignItems="center">
        <ErrorDisplay>{strings.pageNotFound}</ErrorDisplay>
        <Button component={Link} to="/" color="primary" variant="contained">
          {strings.returnToLandingPage}
        </Button>
      </Grid>
    </LogoWrapper>
  );
}
