import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import firebase from "firebase/app";
import { without } from "lodash";
import {
  DispatchWithoutAction,
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  useCollectionData,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore";
import strings from "../common/strings";
import { auth, firestore, functions } from "../firebaseApp";

export default function LackingLicensesDialog({
  open,
  onComplete,
}: {
  open?: boolean;
  onComplete?: DispatchWithoutAction;
}) {
  const [authState] = useAuthState(auth);
  const [sensors] = useCollectionData(
    authState?.uid != null
      ? firestore.collection("sensors").where("ownerId", "==", authState.uid)
      : null,
    {
      idField: "id",
    }
  );

  const [customers] = useCollectionDataOnce<{
    ref: firebase.firestore.DocumentReference;
  }>(
    authState?.uid != null
      ? firestore.collectionGroup("customers").where("uid", "==", authState.uid)
      : null,
    { idField: "id", refField: "ref" }
  );
  const [licensePackageCount, setLicensePackageCount] = useState(0);

  useEffect(() => {
    if (customers != null) {
      let cancelled = false;

      (async () => {
        const now = new Date();

        const newLicensePackageCount = (
          await Promise.all(
            customers.map(({ ref }) =>
              (
                ref
                  .collection("licensePackages")
                  .where(
                    "expiryDate",
                    ">",
                    firebase.firestore.Timestamp.now()
                  ) as firebase.firestore.CollectionReference<{
                  licenseCount: number;
                  startDate: firebase.firestore.Timestamp;
                }>
              )
                .get()
                .then((snapshot) =>
                  snapshot.docs.reduce(
                    (total, document) =>
                      total +
                      (new Date(document.get("startDate").seconds * 1000) <= now
                        ? document.get("licenseCount")
                        : 0),
                    0
                  )
                )
            )
          )
        ).reduce((total, value) => total + value, 0);

        if (!cancelled) setLicensePackageCount(newLicensePackageCount);
      })();

      return () => {
        cancelled = true;
      };
    }
  }, [customers]);

  const [selectedSensorIds, toggleSensor] = useReducer(
    useCallback(
      (prevState: readonly string[], action: string) => {
        if (prevState.includes(action))
          return prevState.filter(
            (selectedSensorId) => selectedSensorId !== action
          );
        else if (licensePackageCount > prevState.length)
          return [...prevState, action];
        else return prevState;
      },
      [licensePackageCount]
    ),
    []
  );

  const selectedSensorCount = selectedSensorIds.length;

  const [deactivateSensorsPromise, setDeactivateSensorsPromise] = useReducer<
    Reducer<Promise<number> | undefined, Promise<number> | undefined>
  >(
    (_, newPromise) =>
      newPromise?.then(
        (n) =>
          new Promise((resolve) =>
            setTimeout(() => {
              resolve(n);
            }, 5000)
          )
      ),
    undefined
  );

  useEffect(() => {
    if (deactivateSensorsPromise !== undefined) {
      let cancelled = false;

      deactivateSensorsPromise
        .then((newLackingLicenseCount) => {
          if (newLackingLicenseCount <= 0 && !cancelled) onComplete?.();
        })
        .finally(() => {
          if (!cancelled) setDeactivateSensorsPromise(undefined);
        });

      return () => {
        cancelled = true;
      };
    }
  }, [deactivateSensorsPromise, setDeactivateSensorsPromise, onComplete]);

  // const lackingLicenseCount = (sensors?.length ?? 0) - licensePackageCount;

  const deactivateSensors = useCallback(() => {
    const sensorIds = sensors?.map((sensor) => sensor.id) ?? [];

    const unselectedSensorIds = without(sensorIds, ...selectedSensorIds);

    setDeactivateSensorsPromise(
      functions
        .httpsCallable("deactivateSensors")(unselectedSensorIds)
        .then((response) => response.data)
    );
  }, [setDeactivateSensorsPromise, sensors, selectedSensorIds]);

  return (
    <Dialog open={open ?? false}>
      {licensePackageCount > 0 ? (
        <>
          <DialogTitle>{strings.deactivateSensors}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography paragraph>
                {strings.formatString(strings.lackingLicensesExplaination, {
                  count: licensePackageCount,
                  sensorNoun:
                    licensePackageCount === 1
                      ? strings.sensorNoun
                      : strings.sensorsNoun,
                })}
              </Typography>
              <Typography>
                {strings.formatString(strings.selectionCountDisplay, {
                  selectedSensorCount,
                  licensePackageCount,
                })}
              </Typography>
            </DialogContentText>
            <List>
              {sensors?.map((sensor) => {
                const selected = selectedSensorIds.includes(sensor.id);

                return (
                  <ListItem key={sensor.id}>
                    <ListItemIcon>
                      <Checkbox
                        onChange={() => toggleSensor(sensor.id)}
                        checked={selected}
                        disabled={
                          (!selected &&
                            licensePackageCount <= selectedSensorCount) ||
                          deactivateSensorsPromise !== undefined
                        }
                      />
                    </ListItemIcon>
                    <ListItemText>{sensor.name}</ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={
                selectedSensorCount !==
                  Math.min(licensePackageCount, sensors?.length ?? 0) ||
                deactivateSensorsPromise !== undefined
              }
              onClick={deactivateSensors}
            >
              {strings.save}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>{strings.licensesExpired}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {strings.noLicensesExplaination}
            </DialogContentText>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
