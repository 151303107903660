import { Grid, makeStyles, Paper } from "@material-ui/core";
import { createStyles } from "@material-ui/styles";
import Logo from "../common/Logo";
import EmailPasswordSection from "./EmailPasswordSection";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("lg")]: {
        alignItems: "center",
      },
      marginTop: 4,
    },
    column: {
      padding: 4,
    },
  })
);

export default function SignInPortal() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        component={Paper}
        alignItems="center"
        className={classes.column}
      >
        <Grid item component={Logo} />
        <Grid item component={EmailPasswordSection} />
      </Grid>
    </div>
  );
}
