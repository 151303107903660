import {
  CircularProgress,
  createStyles,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { green, orange } from "@material-ui/core/colors";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloudOffIcon from "@material-ui/icons/CloudOff";
import ErrorIcon from "@material-ui/icons/ErrorOutline";
import WarningIcon from "@material-ui/icons/WarningOutlined";
import "moment/locale/nl";
import strings from "../common/strings";

const useStyles = makeStyles((theme) =>
  createStyles({
    okayIcon: {
      color: green[500],
    },
    warningIcon: {
      color: orange[500],
    },
  })
);

type Status =
  | "loading"
  | "connectivityError"
  | "noRecentData"
  | "error"
  | "okay";

export default function StatusIcon({ status }: { status: Status }) {
  const classes = useStyles();

  const tooltipTexts: { [K in Status]: string } = {
    loading: strings.loading,
    connectivityError: strings.connectivityError,
    error: strings.outage,
    okay: strings.okay,
    noRecentData: strings.noRecentData,
  };

  const tootltipText = tooltipTexts[status];

  return (
    <Tooltip title={tootltipText}>
      {status === "loading" ? (
        <CircularProgress />
      ) : status === "connectivityError" ? (
        <CloudOffIcon />
      ) : status === "okay" ? (
        <CheckCircleIcon fontSize="large" className={classes.okayIcon} />
      ) : status === "noRecentData" ? (
        <WarningIcon fontSize="large" className={classes.warningIcon} />
      ) : (
        <ErrorIcon fontSize="large" color="error" />
      )}
    </Tooltip>
  );
}
