import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { Dispatch, DispatchWithoutAction, ReactNode } from "react";
import AnnouncementTarget from "../../common/AnnouncementTarget";
import strings from "../../common/strings";
import { standardFieldProps } from "../../common/useFormUtils";

function TargetCheckbox({
  label,
  targets,
  target,
  toggleTarget,
}: {
  label: ReactNode;
  targets: readonly AnnouncementTarget[];
  target: AnnouncementTarget;
  toggleTarget: Dispatch<AnnouncementTarget>;
}) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={targets.includes(target)}
          onChange={() => toggleTarget(target)}
          color="primary"
        />
      }
      label={label}
    />
  );
}

export default function AnnouncementEditor({
  dialogTitle,
  targets,
  toggleTarget,
  title,
  setTitle,
  content,
  setContent,
  onOk,
  onCancel,
  open,
}: {
  dialogTitle: ReactNode;
  targets: readonly AnnouncementTarget[];
  toggleTarget: Dispatch<AnnouncementTarget>;
  title: string;
  setTitle: Dispatch<string>;
  content: string;
  setContent: Dispatch<string>;
  onOk?: DispatchWithoutAction;
  onCancel?: DispatchWithoutAction;
  open: boolean;
}) {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{dialogTitle ?? strings.editAnnouncement}</DialogTitle>
      <DialogContent>
        <TargetCheckbox
          target="admin"
          targets={targets}
          toggleTarget={toggleTarget}
          label={strings.admins}
        />
        <TargetCheckbox
          target="reseller"
          targets={targets}
          toggleTarget={toggleTarget}
          label={strings.resellers}
        />
        <TargetCheckbox
          target="customer"
          targets={targets}
          toggleTarget={toggleTarget}
          label={strings.customers}
        />
        <TextField
          {...standardFieldProps}
          autoFocus
          margin="dense"
          label={strings.title}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onOk?.();
              e.preventDefault();
            }
          }}
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          {...standardFieldProps}
          multiline
          rows={10}
          margin="dense"
          label={strings.content}
          fullWidth
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={onCancel === undefined} onClick={onCancel}>
          {strings.cancel}
        </Button>
        <Button disabled={onOk === undefined} onClick={onOk} color="primary">
          {strings.okay}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
