import PersonIcon from "@material-ui/icons/Person";
import strings from "../../common/strings";
import MenuItemProps from "../MenuItemProps";
import NavigationItem from "../NavigationItem";
import useSwitchUserDialogOpen from "./useSwitchUserDialogOpen";

export default function SwitchUserButton(props: MenuItemProps) {
  const [, setOpen] = useSwitchUserDialogOpen();

  return (
    <>
      <NavigationItem
        {...props}
        icon={<PersonIcon />}
        label={strings.switchUser}
        onClick={() => setOpen(true)}
      />
    </>
  );
}
