import { MenuItem, TableCell, TableRow, Typography } from "@material-ui/core";
import { useReducer, useRef, useState } from "react";
import ContextMenuButton from "../../common/ContextMenuButton";
import strings from "../../common/strings";
import DeleteDialog from "./dialogs/DeleteDialog";
import EditDialog from "./dialogs/EditDialog";

export default function FeatureFlag({
  id,
  name,
  enabled,
}: {
  id: string;
  name: string;
  enabled: true | readonly string[];
}) {
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [openedDialog, dispatchDialogAction] = useReducer(
    (
      prevState: undefined | "edit" | "delete",
      action: ["edit" | "delete", boolean]
    ) => {
      if (prevState === action[0]) return action[1] ? prevState : undefined;
      else if (prevState === undefined)
        return action[1] ? action[0] : undefined;
    },
    undefined
  );

  const [
    [editDialogOpen, setEditDialogOpen],
    [deleteDialogOpen, setDeleteDialogOpen],
  ] = (["edit", "delete"] as const).map((dialog) => [
    openedDialog === dialog,
    useRef((open: boolean) => dispatchDialogAction([dialog, open])).current, // eslint-disable-line react-hooks/rules-of-hooks
  ]);

  return (
    <TableRow>
      <TableCell>{id}</TableCell>
      <TableCell>{name}</TableCell>
      <TableCell>
        <ContextMenuButton open={contextMenuOpen} setOpen={setContextMenuOpen}>
          <MenuItem
            onClick={() => {
              setEditDialogOpen(true);
              setContextMenuOpen(false);
            }}
          >
            {strings.edit}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setDeleteDialogOpen(true);
              setContextMenuOpen(false);
            }}
          >
            <Typography color="error">{strings.delete}</Typography>
          </MenuItem>
        </ContextMenuButton>
        <EditDialog
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          id={id}
          name={name}
          enabled={enabled}
        />
        <DeleteDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          id={id}
          name={name}
        />
      </TableCell>
    </TableRow>
  );
}
