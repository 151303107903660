import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";
import { firestore } from "../../../firebaseApp";

export type EmployeeStatus = "emailSent" | "roleGranted";

export interface IEmployee {
  id: string;
  email: string;
  status: EmployeeStatus;
}

export default function useEmployees(): [
  IEmployee[] | undefined,
  boolean,
  firebase.FirebaseError?
] {
  const { resellerId } = useParams<{ resellerId: string }>();

  const [employees, loadingEmployees, employeeLoadingError] = useCollectionData<
    Pick<IEmployee, "id" | "email">
  >(
    firestore
      .collection("users")
      .where("resellerIds", "array-contains", resellerId),
    {
      idField: "id",
    }
  );

  const [unusedTokens, loadingUnusedTokens, unusedTokenLoadingError] =
    useCollectionData<{
      tokenHash: string;
      email: string;
      usedBy: null;
      id: string;
    }>(
      firestore
        .collection("resellerTokens")
        .where("resellerId", "==", resellerId)
        .where("usedBy", "==", null),
      {
        idField: "id",
      }
    );

  return [
    employees != null && unusedTokens != null
      ? [
          ...employees.map((admin) => ({
            ...admin,
            status: "roleGranted" as EmployeeStatus,
          })),
          ...unusedTokens.map((token) => ({
            ...token,
            status: "emailSent" as EmployeeStatus,
          })),
        ]
      : undefined,
    loadingEmployees || loadingUnusedTokens,
    employeeLoadingError ?? unusedTokenLoadingError,
  ];
}
