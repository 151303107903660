import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import AdminPanel from "./AdminPanel";
import AnnouncementWrapper from "./AnnouncementWrapper";
import AuthWrapper from "./AuthWrapper";
import { AdminUidOverrideProvider } from "./common/useAdminUidOverride";
import useIsCustomer from "./common/useIsCustomer";
import CustomerWrapper from "./CustomerWrapper";
import Dashboard from "./Dashboard";
import DeviceRegistration from "./DeviceRegistration";
import LandingPage from "./LandingPage";
import Layout from "./Layout";
import PageNotFound from "./PageNotFound";
import ResellerPanel from "./ResellerPanel";
import Settings from "./Settings";
import theme from "./theme";
import TokenProcessor from "./TokenProcessor";

function App() {
  const deviceListMatch = useRouteMatch("/sensors");
  const history = useHistory();
  const [isCustomer] = useIsCustomer();

  return (
    <AdminUidOverrideProvider>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Layout
            onFloatingActionButtonClick={
              deviceListMatch?.isExact && isCustomer
                ? () => history.push("/device-registration")
                : undefined
            }
          >
            <AuthWrapper>
              <AnnouncementWrapper>
                <Switch>
                  <Route path="/token" component={TokenProcessor} />
                  <Route path="/admin" component={AdminPanel} />
                  <Route path="/reseller" component={ResellerPanel} />
                  <Route path="/settings" component={Settings} />
                  <Route exact path="/" component={LandingPage} />
                  <Route path={["/device-registration", "/sensors"]}>
                    <CustomerWrapper>
                      <Switch>
                        <Route
                          path="/device-registration"
                          component={DeviceRegistration}
                        />
                        <Route path="/sensors" component={Dashboard} />
                      </Switch>
                    </CustomerWrapper>
                  </Route>
                  <Route component={PageNotFound} />
                </Switch>
              </AnnouncementWrapper>
            </AuthWrapper>
          </Layout>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </AdminUidOverrideProvider>
  );
}

export default App;
