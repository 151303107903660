import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { Dispatch, useCallback, useEffect } from "react";
import strings from "../../../common/strings";
import { standardFieldProps } from "../../../common/useFormUtils";
import { useNameField } from "../../fields";
import useCloudFunction from "../../../common/useCloudFunction";

export default function CreateDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<boolean>;
}) {
  const [id, idValid, idFieldProps, resetId] = useNameField();
  const [name, nameValid, nameFieldProps, resetName] = useNameField();

  useEffect(() => {
    if (!open) {
      resetId();
      resetName();
    }
  }, [open, resetId, resetName]);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [createFeatureFlag] = useCloudFunction("createFeatureFlag");

  const submit = useCallback(() => {
    if (idValid && nameValid) {
      createFeatureFlag({
        id,
        name,
        enabled: [],
      });
      close();
    }
  }, [createFeatureFlag, idValid, nameValid, id, name, close]);

  return (
    <Dialog open={open} onClose={close}>
      <DialogTitle>{strings.createFeatureFlag}</DialogTitle>
      <DialogContent>
        <TextField
          {...standardFieldProps}
          label={strings.id}
          {...idFieldProps}
        />
        <TextField
          {...standardFieldProps}
          label={strings.name}
          {...nameFieldProps}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>{strings.cancel}</Button>
        <Button
          onClick={submit}
          color="primary"
          disabled={!idValid || !nameValid}
        >
          {strings.createFeatureFlag}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
