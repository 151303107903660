import { createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import LoadingIndicator from "../common/LoadingIndicator";
import strings from "../common/strings";
import useIsAdmin from "../common/useIsAdmin";
import Admins from "./Admins";
import Announcements from "./Announcements";
import BulkSensors from "./BulkSensors";
import DataDump from "./DataDump";
import FeatureFlags from "./FeatureFlags";
import PasswordResetLinks from "./PasswordResetLinks";
import RemoveErroneuousData from "./RemoveErroneousData";
import Resellers from "./Resellers";
import Sensors from "./Sensors";
import WeeklyReport from "./WeeklyReport";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(),
    },
    errorMessage: {
      textAlign: "center",
    },
  })
);

export default function AdminPanel() {
  const classes = useStyles();

  const [isAdmin, loading, error] = useIsAdmin();

  return (
    <Grid container spacing={1} className={classes.root}>
      {isAdmin ? (
        <>
          <Resellers />
          <Admins />
          <Announcements />
          <Sensors />
          <PasswordResetLinks />
          <WeeklyReport />
          <DataDump />
          <FeatureFlags />
          <RemoveErroneuousData />
          <BulkSensors />
        </>
      ) : loading ? (
        <Grid item xs={12}>
          <LoadingIndicator />
        </Grid>
      ) : (
        <Grid item xs={12} className={classes.errorMessage}>
          <Typography variant="h3" component="strong">
            {error ? strings.genericError : strings.notAnAdmin}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
