import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { firestore } from "../../firebaseApp";

export type AdminStatus = "emailSent" | "roleGranted";

export interface IAdmin {
  id: string;
  email: string;
  status: AdminStatus;
}

export default function useAdmins(): [
  IAdmin[] | undefined,
  boolean,
  firebase.FirebaseError?
] {
  const [admins, loadingAdmins, adminLoadingError] = useCollectionData<
    Pick<IAdmin, "id" | "email">
  >(firestore.collection("users").where("admin", "==", true), {
    idField: "id",
  });

  const [unusedTokens, loadingUnusedTokens, unusedTokenLoadingError] =
    useCollectionData<{
      tokenHash: string;
      email: string;
      usedBy: null;
      id: string;
    }>(firestore.collection("adminTokens").where("usedBy", "==", null), {
      idField: "id",
    });

  return [
    admins != null && unusedTokens != null
      ? [
          ...admins.map((admin) => ({
            ...admin,
            status: "roleGranted" as AdminStatus,
          })),
          ...unusedTokens.map((token) => ({
            ...token,
            status: "emailSent" as AdminStatus,
          })),
        ]
      : undefined,
    loadingAdmins || loadingUnusedTokens,
    adminLoadingError ?? unusedTokenLoadingError,
  ];
}
