import { Button, TextField } from "@material-ui/core";
import strings from "../../common/strings";
import useFormUtils, {
  standardButtonProps,
  standardFieldProps,
} from "../../common/useFormUtils";
import FormCard from "../FormCard";
import { useEmailField } from "../fields";
import usePasswordResetLink from "./usePasswordResetLink";

export default function PasswordResetLinks() {
  const [email, emailValid, emailFieldProps] = useEmailField();
  const [
    passwordResetLink,
    fetchPasswordResetLink,
    loadingPasswordResetLink,
    error,
  ] = usePasswordResetLink();

  const [classes] = useFormUtils();

  return (
    <FormCard title={strings.resetPassword}>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          fetchPasswordResetLink(email);
        }}
        className={classes.form}
        style={{ minWidth: "unset" }}
      >
        <TextField
          id="email"
          label={strings.email}
          type="email"
          value={email}
          {...standardFieldProps}
          {...emailFieldProps}
        />
        <Button
          type="submit"
          disabled={!emailValid || loadingPasswordResetLink}
          {...standardButtonProps}
        >
          {strings.okay}
        </Button>
      </form>
      {error ? error.code : passwordResetLink}
    </FormCard>
  );
}
