import {
  Grid,
  Paper,
  Toolbar,
  Typography,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { PropsWithChildren, ReactNode } from "react";
import useTableStyles from "../common/useTableStyles";

const useStyles = makeStyles((theme) =>
  createStyles({
    entryFormContainer: {
      padding: theme.spacing(2),
      paddingTop: 0,
    },
  })
);

export default function FormCard({
  title,
  children,
}: PropsWithChildren<{ title: ReactNode }>) {
  const tableClasses = useTableStyles();
  const classes = useStyles();

  return (
    <Grid item xs={12} xl={6}>
      <Grid container component={Paper}>
        <Grid
          item
          xs={12}
          component={Toolbar}
          className={tableClasses.tableToolbar}
        >
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item xs={12} className={classes.entryFormContainer}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
}
