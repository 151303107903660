import { Button, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { merge, Observable } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { useObservableValue, useSubject } from "../common/rxHooks";
import strings from "../common/strings";
import useFormUtils from "../common/useFormUtils";
import { auth } from "../firebaseApp";
import errorMessages from "./errorMessages";

export default function PasswordResetView({
  showSignInForm,
}: {
  showSignInForm(): void;
}) {
  const [email, setEmail] = useState("");

  const resetEmailSubject = useSubject<string>();
  const goBackSubject = useSubject<null>();

  const [state$] = useState(() =>
    merge(
      resetEmailSubject.pipe(
        concatMap(
          (email) =>
            new Observable<
              | { stage: 0; sendingPasswordResetEmail: boolean; error?: string }
              | { stage: 1 }
            >((subscriber) => {
              subscriber.next({ stage: 0, sendingPasswordResetEmail: true });

              auth
                .sendPasswordResetEmail(email)
                .then(() => subscriber.next({ stage: 1 }))
                .catch((reason) => {
                  if (reason != null) {
                    if (typeof reason.code === "string")
                      subscriber.next({
                        stage: 0,
                        sendingPasswordResetEmail: false,
                        error: reason.code,
                      });
                  }
                })
                .finally(() => subscriber.complete());
            })
        )
      ),
      goBackSubject.pipe(
        map((): { stage: 0; sendingPasswordResetEmail: false } => ({
          stage: 0,
          sendingPasswordResetEmail: false,
        }))
      )
    )
  );

  const state = useObservableValue<
    | { stage: 0; sendingPasswordResetEmail: boolean; error?: string }
    | { stage: 1 }
  >(state$, { stage: 0, sendingPasswordResetEmail: false });

  const [
    classes,
    standardFieldProps,
    standardButtonProps,
    standardMinorButtonProps,
  ] = useFormUtils();

  switch (state.stage) {
    case 0:
      const { sendingPasswordResetEmail, error } = state;

      const errorMessage =
        error != null
          ? errorMessages.get(error)?.[1] ?? strings.genericError
          : null;

      return (
        <form
          className={classes.form}
          onSubmit={(e) => {
            e.preventDefault();
            resetEmailSubject.next(email);
          }}
        >
          <TextField
            id="email"
            label={strings.email}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={errorMessage != null}
            helperText={errorMessage}
            {...standardFieldProps}
          />
          <Button
            type="submit"
            className={classes.button}
            disabled={sendingPasswordResetEmail}
            {...standardButtonProps}
          >
            {strings.sendResetLink}
          </Button>
          <Button
            type="button"
            className={classes.button}
            onClick={showSignInForm}
            {...standardMinorButtonProps}
          >
            {strings.back}
          </Button>
        </form>
      );
    case 1:
      return (
        <>
          <Typography variant="body2">
            {strings.formatString(
              strings.resetLinkSent,
              <strong>{email}</strong>
            )}
          </Typography>
          <Button
            variant="outlined"
            type="button"
            className={classes.button}
            fullWidth
            onClick={() => goBackSubject.next(null)}
          >
            {strings.back}
          </Button>
        </>
      );
  }
}
