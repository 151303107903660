import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography
} from "@material-ui/core";
import strings from "../../common/strings";
import AddButton from "../AddButton";
import useTableStyles from "../../common/useTableStyles";
import Reseller from "./Reseller";
import CreateDialog from "./dialogs/CreateDialog";
import useResellers from "./useResellers";

export default function Resellers() {
  const [resellers] = useResellers();
  const classes = useTableStyles();

  return (
    <Grid item xs={12} xl={6}>
      <TableContainer component={Paper}>
        <Toolbar className={classes.tableToolbar}>
          <Typography variant="h6">{strings.resellers}</Typography>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{strings.name}</TableCell>
              {/* Reducing the cell's width to 1 ensures that
              the column will never be wider than it needs to be */}
              <TableCell align="center" width={1}>
                <AddButton
                  renderDialog={(open, setOpen) => (
                    <CreateDialog {...{ open, setOpen }} />
                  )}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {resellers.map((reseller) => (
              <Reseller key={reseller.id} reseller={reseller} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}
