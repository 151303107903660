import { formatNumber, parse } from "libphonenumber-js";
import { useCallback, useEffect, useState } from "react";
import { firestore } from "../firebaseApp";
import useUid from "./useUid";

export default function useUpdatePhoneNumber(
  onSuccess?: () => void
): [(phoneNumber: string) => void, boolean] {
  const [uid, loadingUid] = useUid(false);
  const [promise, setPromise] = useState<Promise<unknown>>();

  useEffect(() => {
    let cancelled = false;

    promise
      ?.then(() => {
        if (!cancelled) onSuccess?.();
      })
      .finally(() => {
        if (!cancelled) setPromise(undefined);
      });

    return () => {
      cancelled = true;
    };
  }, [promise, onSuccess]);

  const updatePhoneNumber = useCallback(
    (phoneNumber: string) => {
      const parsedNumber = parse(phoneNumber, "NL");
      setPromise(
        firestore
          .collection("users")
          .doc(uid)
          .set(
            {
              phoneNumber: formatNumber(parsedNumber, "INTERNATIONAL"),
            },
            { merge: true }
          )
      );
    },
    [uid]
  );

  return [updatePhoneNumber, promise !== undefined || loadingUid];
}
