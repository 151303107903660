import { LinearProgress } from "@material-ui/core";
import firebase from "firebase/app";
import { useCallback, useState } from "react";
import useAdminUidOverride from "../common/useAdminUidOverride";
import { ISensor } from "../Dashboard/common";
import { firestore, functions } from "../firebaseApp";
import ConfigurationForm from "./ConfigurationForm";
import EndScreen from "./EndScreen";
import Scanner from "./Scanner";

export default function DeviceRegistration() {
  const [sensorSnapshot, setSensorSnapshot] =
    useState<firebase.firestore.DocumentSnapshot<ISensor> | null>(null);

  const [loadingSensor, setLoadingSensor] = useState(false);

  const [registering, setRegistering] = useState(false);

  const [finished, setFinished] = useState(false);

  const [error, setError] = useState<firebase.FirebaseError | null>(null);

  const reset = () => {
    setSensorSnapshot(null);
    setLoadingSensor(false);
    setRegistering(false);
    setFinished(false);
    setError(null);
  };

  const [uidOverride] = useAdminUidOverride();
  console.log(uidOverride);

  // TODO: Improve this code!!!
  const onScan = useCallback(
    async (devEui: string) => {
      setLoadingSensor(true);

      try {
        const sensorSnapshot = await firestore
          .collection("sensors")
          .doc(devEui)
          .get();

        setSensorSnapshot(
          sensorSnapshot.exists
            ? (sensorSnapshot as firebase.firestore.DocumentSnapshot<ISensor>)
            : null
        );
      } catch (error: any) {
        setError(error);
        setSensorSnapshot(null);
      }

      setLoadingSensor(false);
    },
    [setLoadingSensor, setSensorSnapshot]
  );

  if (loadingSensor || registering) return <LinearProgress />;
  else if (sensorSnapshot == null || !sensorSnapshot.exists)
    return <Scanner onScan={onScan} />;
  else if (!finished) {
    return (
      <ConfigurationForm
        onSubmit={async (name, minimumTemperature, maximumTemperature) => {
          setRegistering(true);

          try {
            await functions.httpsCallable("registerSensor")({
              devEui: sensorSnapshot.id,
              name,
              minimumTemperature,
              maximumTemperature,
              ...(uidOverride !== undefined ? { uid: uidOverride } : {}),
            });
          } catch (error: any) {
            setError(error);
          } finally {
            setFinished(true);
            setRegistering(false);
          }
        }}
        onCancel={reset}
      />
    );
  } else return <EndScreen error={error} onRestart={reset} />;
  // TODO: Improve the screen that is shown when the user succesfully claims a device.
}
