import { Box, Button, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import strings from "../common/strings";
import useFormUtils from "../common/useFormUtils";
import firebase from "firebase/app";

export default function EndScreen({
  error,
  onRestart,
}: {
  error?: firebase.FirebaseError | null;
  onRestart(): void;
}) {
  const [classes] = useFormUtils();

  return (
    <Box margin={1} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h3" align="center">
        {error
          ? error.code === "resource-exhausted"
            ? strings.notEnoughLicenses
            : strings.genericError
          : strings.sensorConnected}
      </Typography>
      <Button
        color="primary"
        variant="outlined"
        className={classes.button}
        onClick={onRestart}
      >
        {error ? strings.retry : strings.connectAnotherSensor}
      </Button>
      <Button
        variant="outlined"
        className={classes.button}
        component={Link}
        to="/sensors"
      >
        {strings.backToSensorList}
      </Button>
    </Box>
  );
}
