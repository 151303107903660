import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
  TextField,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase/app";
import { Dispatch, useState } from "react";
import strings from "../../../common/strings";
import useCloudFunction from "../../../common/useCloudFunction";
import useFormUtils from "../../../common/useFormUtils";
import usePromise from "../../../common/usePromise";
import { firestore } from "../../../firebaseApp";
import { useEmailField, useNameField } from "../../fields";

export default function CreateDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<boolean>;
}) {
  const [, commonFieldProps] = useFormUtils();

  const [email, emailValid, emailFieldProps, resetEmail] = useEmailField();
  const [name, nameValid, nameFieldProps, resetName] = useNameField();

  const handleClose = () => {
    setOpen(false);
    resetEmail();
    resetName();
  };

  const [
    sendResellerToken,
    sendingResellerToken,
    sendResellerTokenError,
    resetSendResellerTokenError,
  ] = useCloudFunction("sendResellerToken");

  const [creatingReseller, setCreatingReseller] = useState(false);
  const [creatingResellerError, setCreatingResellerError] =
    useState<firebase.FirebaseError>();
  const resetCreatingResellerError = () => setCreatingResellerError(undefined);
  const setCreateResellerPromise = usePromise<{ id: string; email: string }>(
    ({ id, email }) => {
      setCreatingResellerError(undefined);
      sendResellerToken({ resellerId: id, email });
    },
    setCreatingResellerError,
    () => {
      setCreatingReseller(false);
    }
  );

  const submit = () => {
    if (emailValid && nameValid) {
      setCreatingReseller(true);
      setCreateResellerPromise(
        firestore
          .collection("resellers")
          .add({
            name,
          })
          .then((result) => ({ id: result.id, email }))
      );

      handleClose();
    }
  };

  const working = sendingResellerToken || creatingReseller;
  const error = sendResellerTokenError || creatingResellerError;

  const resetErrors = () => {
    resetSendResellerTokenError();
    resetCreatingResellerError();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby={`create-reseller-dialog-title`}
      >
        <DialogTitle id={`create-reseller-dialog-title`}>
          {strings.createReseller}
        </DialogTitle>
        <DialogContent>
          <TextField
            {...commonFieldProps}
            autoFocus
            margin="dense"
            label={strings.email}
            onKeyDown={({ key }) => {
              if (key === "Enter") {
                submit();
              }
            }}
            fullWidth
            value={email}
            {...emailFieldProps}
          />
          <TextField
            {...commonFieldProps}
            margin="dense"
            label={strings.name}
            onKeyDown={({ key }) => {
              if (key === "Enter") {
                submit();
              }
            }}
            fullWidth
            value={name}
            {...nameFieldProps}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{strings.cancel}</Button>
          <Button onClick={submit} color="primary" disabled={!emailValid}>
            {strings.createReseller}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar message={strings.sendingEmail} open={working} />
      <Snackbar
        message={strings.genericError}
        open={!!error}
        onClose={resetErrors}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={resetErrors}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
}
