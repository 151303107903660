import {
  createStyles,
  IconButton,
  List,
  makeStyles,
  SwipeableDrawer,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { ReactNode, useState } from "react";
import MenuItemProps from "./MenuItemProps";

const useStyles = makeStyles((theme) =>
  createStyles({
    menuButton: {
      marginRight: theme.spacing(2),
    },
  })
);

export default function DrawerButton({
  children,
}: {
  children?(commonProps: MenuItemProps): ReactNode;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setIsOpen(open);
  };

  const classes = useStyles();

  const commonMenuItemProps: MenuItemProps = {
    inDrawer: true,
    closeDrawer() {
      setIsOpen(false);
    },
  };

  const menuItems = children?.(commonMenuItemProps);

  return (
    <>
      <IconButton
        onClick={toggleDrawer(true)}
        color="inherit"
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="left"
        open={isOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <List>{menuItems}</List>
      </SwipeableDrawer>
    </>
  );
}
