import { Grid } from "@material-ui/core";
import PhoneNumberSection from "./PhoneNumberSection";
import WeeklyEmailSection from "./WeeklyEmailSection";
import PushNotificationSection from "./PushNotificationSection";

export default function Settings() {
  return (
    <Grid container>
      <PhoneNumberSection />
      <WeeklyEmailSection />
      <PushNotificationSection />
    </Grid>
  );
}
