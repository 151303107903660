import {
  Button,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import strings from "../common/strings";
import useSavedCallback from "../common/useSavedCallback";
import { useSignOut } from "../common/useSignOut";
import MenuItemProps from "./MenuItemProps";

export default function SignOutButton(props: MenuItemProps) {
  const [signOut, signingOut] = useSignOut();
  const signOutCallback = useSavedCallback(
    props.inDrawer ? props.closeDrawer : () => {}
  );

  const icon = <ExitToAppIcon />;
  const commonProps = {
    disabled: signingOut,
    onClick: () => {
      signOut().then(() => signOutCallback.current?.());
    },
  };
  const text = strings.signOut;

  return props.inDrawer ? (
    <ListItem button {...commonProps}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>{text}</ListItemText>
    </ListItem>
  ) : (
    <Button color="inherit" startIcon={icon} {...commonProps}>
      {text}
    </Button>
  );
}
