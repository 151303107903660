import { Button, ButtonProps } from "@material-ui/core";
import strings from "../common/strings";
import useFormUtils from "../common/useFormUtils";

export default function BackButton({ children, ...props }: ButtonProps) {
  const [classes, , , commonMinorButtonProps] = useFormUtils();

  return (
    <Button className={classes.button} {...commonMinorButtonProps} {...props}>
      {children || strings.back}
    </Button>
  );
}
