import { Route, Switch } from "react-router-dom";
import AdminTokenProcessor from "./AdminTokenProcessor";
import CustomerTokenProcessor from "./CustomerTokenProcessor";
import ResellerTokenProcessor from "./ResellerTokenProcessor";

export default function RoleGranter() {
  return (
    <Switch>
      <Route path="/token/admin/:token" component={AdminTokenProcessor} />
      <Route path="/token/reseller/:token" component={ResellerTokenProcessor} />
      <Route path="/token/customer/:token" component={CustomerTokenProcessor} />
    </Switch>
  );
}
