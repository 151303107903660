import { IconButton, List, ListItem, Typography } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { DispatchWithoutAction, MutableRefObject, useCallback } from "react";
import LoadingIndicator from "../LoadingIndicator";
import useUsers, { UsersActionKind } from "./useUsers";

export default function UserPicker({
  onSelect,
  refreshRef,
  disabled = false,
  selected,
}: {
  onSelect?(uid: string): void;
  refreshRef?: MutableRefObject<DispatchWithoutAction | undefined>;
  disabled?: boolean;
  selected?: readonly string[];
}) {
  const [users, pageIndex, dispatch] = useUsers();

  const refresh = useCallback(
    () => dispatch(UsersActionKind.REFRESH),
    [dispatch]
  );

  if (refreshRef !== undefined) refreshRef.current = refresh;

  return (
    <>
      {users === undefined ? (
        <LoadingIndicator />
      ) : users.length === 0 ? (
        <Typography>U heeft het einde van de lijst bereikt.</Typography>
      ) : (
        <List>
          {users?.map((user) => (
            <ListItem
              key={user.uid}
              button
              onClick={disabled ? undefined : () => onSelect?.(user.uid)}
              disabled={disabled}
              selected={selected?.includes(user.uid)}
            >
              {user.email}
            </ListItem>
          ))}
        </List>
      )}
      <div>
        <IconButton
          disabled={pageIndex === 0}
          onClick={() => dispatch(UsersActionKind.PREVIOUS_PAGE)}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          disabled={users === undefined || users.length < 4}
          onClick={() => dispatch(UsersActionKind.NEXT_PAGE)}
        >
          <ChevronRightIcon />
        </IconButton>
      </div>
    </>
  );
}
